import Error from "./components/Pages/Error/Error";

// Home Page
import Home from "./components/LandingPage/Home/Home";

// Default Pages
import BrandAssets from "./components/Pages/BrandAssets/BrandAssets";
import NewProject from "./components/Pages/NewProject/NewProject";
import Projects from "./components/Pages/Projects/Projects";
import Work from "./components/Pages/Work/Work";
import PortfolioWork from "./components/Pages/Portfolio/PortfolioWork";
import Sidekick from "./components/Pages/Sidekick/Sidekick";
import Settings from "./components/Pages/Settings/Settings";

// Admin Pages
import AdminPanel from "./components/Pages/Admin/AdminPanel";
import AdminProjects from "./components/Pages/Admin/Apps/Projects";
import AdminUserManager from "./components/Pages/Admin/Apps/UserManager";
import AdminProviderManager from "./components/Pages/Admin/Apps/ProviderManager";
import AdminUserFeedback from "./components/Pages/Admin/Apps/UserFeedback";
import AdminServiceManager from "./components/Pages/Admin/Apps/ServiceManager";
import AdminInvoiceManager from "./components/Pages/Admin/Apps/InvoiceManager";
import AdminAddUser from "./components/Pages/Admin/Apps/AddUser";
import AdminAddProvider from "./components/Pages/Admin/Apps/AddProvider";

// Onboarding Pages
import DesignerOnboarding from "./components/Pages/Onboarding/DesignerOnboarding";

// Authentication pages
import EmailLink from "./components/Authentication/EmailLink";
import Login from "./components/Authentication/Login";
import Registration from "./components/Authentication/Registration";
import ProviderRegistration from "./components/Authentication/ProviderRegistration";
import ForgotPassword from "./components/Authentication/ForgotPassword";

import React, { useContext, useEffect, useState } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Layout from "./components/Layout";
import ProjectDetail from "./components/Pages/ProjectDetail/ProjectDetail";

import { getSession, userPool } from "./cognito/cognitoAuth";
import WelcomeModal from "./components/WelcomeModal";
// import ThemeVisualiser from "./components/Pages/ThemeVisualiser/ThemeVisualiser";
import ErrorLoggedOut from "./components/Pages/Error/ErrorLoggedOut";
import SidekickOnboarding from "./components/Pages/Sidekick/Onboarding";

import Lottie from "lottie-react";

import {
  Box,
  Text,
  useToast,
  useColorMode,
  Spinner,
  Button,
} from "@chakra-ui/react";
import Welcome from "./components/Pages/Sidekick/Welcome";
import WorkAtOlenium from "./components/WorkAtOlenium/WorkAtOlenium";
import AuthLayout from "./components/AuthFlowLayout";
import LandingLayout from "./components/LandingLayout";
import { UserContext, setUserDetails, setIdToken } from "./UserContext";
import Services from "./components/LandingPage/Services/Services";
import OurPeople from "./components/LandingPage/Our-People/our-people";
import Pricing from "./components/LandingPage/Pricing/Pricing";
import Explore from "./components/LandingPage/Explore/Explore";
import WorkAtOleniumLoggedOut from "./components/LandingPage/WorkAtOlenium/WorkAtOlenium";
import Portfolio from "./components/Pages/Onboarding/DesignerForms/Portfolio";
import WebLanding from "./components/LandingPage/Web-Landing/Home";

const App = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [onboardingCompleted, setOnboardingCompleted] = useState(true);
  const [userRole, setUserRole] = useState("null");
  const { colorMode } = useColorMode();

  // set sickick onboarding to the local storage value if it exists, otherwise set it to false
  const [sidekickOnboardingCompleted, setSidekickOnboardingCompleted] =
    useState(
      localStorage.getItem("sidekickOnboardingComplete") === "true"
        ? true
        : false,
    );

  const [initialRender, setInitialRender] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [loggingOut, setLoggingOut] = useState(false);
  const [pageRefresh, setPageRefresh] = useState(false);

  const context = useContext(UserContext);
  const { setUserDetails, setIdToken } = context;

  const API_URL = process.env.REACT_APP_API_URL;

  const navigate = useNavigate();

  const [isWelcomeModalOpen, setIsWelcomeModalOpen] = useState(
    localStorage.getItem("isWelcomeModalOpen") === "true" ? true : false,
  );

  useEffect(() => {
    localStorage.setItem("isWelcomeModalOpen", isWelcomeModalOpen);
  }, [isWelcomeModalOpen]);

  if (colorMode === "light") {
    // set the color mode to dark
    localStorage.setItem("chakra-ui-color-mode", "dark");
  }

  useEffect(() => {
    if (initialRender || pageRefresh) {
      const cognitoUser = userPool.getCurrentUser();
      getSession(cognitoUser)
        .then(async (session) => {
          setLoggedIn(true);

          // Set user details in context
          setIdToken(session.getIdToken().getJwtToken());

          // fetch user details from API instead of using the session data
          const userResponse = await fetch(
            `${API_URL}/users/info/${session.idToken.payload.sub}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
              },
            },
          );
          const userData = await userResponse.json();

          if (userData.statusCode === 200) {
            setUserDetails({
              id: session.idToken.payload.sub,
              email: session.idToken.payload.email,
              // check if the user has a first name in the user pool, if not use the one from the API
              firstName:
                session.idToken.payload.given_name === undefined
                  ? userData.body.firstName
                  : session.idToken.payload.given_name,
              lastName:
                session.idToken.payload.family_name === undefined
                  ? userData.body.lastName
                  : session.idToken.payload.family_name,
              phoneNumber:
                session.idToken.payload.phone_number === undefined
                  ? userData.body.phone_number
                  : session.idToken.payload.phone_number,
              profilePicture: userData.body.profile_picture_url,
              userRole:
                userData.body.user_role === undefined
                  ? "null"
                  : userData.body.user_role,
            });
            setUserRole(userData.body.user_role);
            setIdToken(session.getIdToken().getJwtToken());
          } else {
            // show a toast to the user
            toast({
              title: "Error",
              description: "There was an error fetching your user details",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          }
        })
        .catch((err) => {
          console.log("Error:", err);
          // Handle the case where the account no longer exists
          if (err.message === "User not found") {
            setLoggedIn(false);
            setUserRole(null);
          }
        })
        .finally(() => {
          setInitialRender(false);
          setIsLoading(false);
        });
    }
  }, [initialRender, pageRefresh, navigate, setUserDetails, setIdToken]);

  const handleOnboardingComplete = () => {
    setOnboardingCompleted(true);
    setIsWelcomeModalOpen(true);
  };

  const handleSidekickOnboardingComplete = () => {
    setSidekickOnboardingCompleted(true);
  };

  const toast = useToast();

  useEffect(() => {
    if (localStorage.getItem("providerRegistered") === "true" && loggedIn) {
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.toString() !== "") {
        navigate(`/get-started-with-olenium?${urlParams.toString()}`);
      } else {
        navigate("/get-started-with-olenium");
      }

      // Check if the toast is already shown
      if (!toast.isActive('providerToast')) {
        toast({
          id: 'providerToast', // Assign an ID to the toast
          title: "Welcome to Olenium!",
          description: "You have successfully registered as a provider.",
          status: "success",
          duration: 10000,
          isClosable: true,
          render: () => (
            <Box color="white" p={5} bg="oleniumBlack.400" borderRadius={10} boxShadow="lg" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              <Text fontSize="xl" fontWeight="bold" mb={2}>
                You have registered as a provider.
              </Text>
              <Text fontSize="sm" textAlign="center" mb={2} maxW={350}>
                Complete the onboarding process to start working on projects. You can cancel your application at any time.
              </Text>
              <Button
                mt={3}
                size={"xs"}
                variant="outline"
                _hover={{ bg: "oleniumBlack.500" }}
                onClick={() => {
                  localStorage.setItem("providerRegistered", false);
                  toast.closeAll();
                  navigate("/");
                }}
              >
                Cancel Application
              </Button>
            </Box>
          ),
        });
      }
    }
  }, [loggedIn, navigate, toast]);



  useEffect(() => {
    const checkInternetInterval = setInterval(() => {
      showNoInternetToast();
    }, 10000); // Check for internet connection every 10 seconds (10,000 ms)

    return () => {
      clearInterval(checkInternetInterval); // Clear the interval when the component is unmounted
    };
  }, []);

  const showNoInternetToast = () => {
    if (!navigator.onLine) {
      toast({
        title: "No Internet Connection",
        description: "Please check your internet connection and try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const LoadingScreen = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spinner size="xl" />
      </div>
    );
  };


  // I need a useEffect here to check if the user is a client and if the URL has is /portfolio then redirect to /get-started-with-olenium and transfer the URL params
  useEffect(() => {
    if (userRole === "Clients" && window.location.pathname === "/portfolio") {
      const urlParams = new URLSearchParams(window.location.search);
      // if there are url params add them to the url when redirecting to /get-started-with-olenium

      if (urlParams.toString() !== "") {
        navigate(`/get-started-with-olenium?${urlParams.toString()}`);
      }
      else {
        navigate("/get-started-with-olenium");
      }
    }
  }, [userRole, navigate]);



  const UserRoleError = () => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: require("./assets/animations/Profile.json"),
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice",
            },
          }}
          height={200}
          width={200}
        />
        <Text fontSize="2xl" fontWeight="bold">
          User role not found
        </Text>
        <Text fontSize="lg" fontWeight="normal">
          Please contact support
        </Text>
      </Box>
    );
  };

  const renderOnboardingComponent = () => {
    switch (userRole) {
      case "providers":
        return <DesignerOnboarding onComplete={handleOnboardingComplete} />;
      default:
        return <UserRoleError />;
    }
  };

  const handleLogout = () => {
    setLoggingOut(true);
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.signOut();
      setLoggedIn(false);
      setUserRole(null);
      navigate("/login");
    }
    setLoggingOut(false);
  };

  // redirect from /login to / if user is logged in
  if (loggedIn && window.location.pathname === "/login") {
    navigate("/");
  }



  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : initialRender ? null : loggedIn ? (
        onboardingCompleted ? (
          <Layout onLogout={handleLogout}>
            <Routes>
              <Route path="/" element={<Projects />} />
              {/* For providers and admins */}
              {(userRole === "Providers" || userRole === "Admins") && (
                <Route path="/work" element={<Work />} />
              )}

              <Route path="/brand-assets" element={<BrandAssets />} />
              {/* <Route
                path="/sidekick"
                element={
                  sidekickOnboardingCompleted ? <Sidekick /> : <Welcome />
                }
              /> */}
              {/* <Route
                path="/getting-started-with-sidekick"
                element={
                  <SidekickOnboarding
                    onComplete={handleSidekickOnboardingComplete}
                  />
                }
              /> */}
              <Route path="/work-on-olenium" element={<WorkAtOlenium />} />
              <Route path="/my-account" element={<Settings />} />
              <Route path="/my-account/:action" element={<Settings />} />
              <Route path="/new-project" element={<NewProject />} />
              <Route path="/project/:projectId" element={<ProjectDetail />} />
              <Route path="/project/:projectId/:action" element={<ProjectDetail />} />
              {(userRole === "Providers" || userRole === "Admins") && (
                <Route path="/portfolio" element={<PortfolioWork />} />
              )}
              <Route
                path="/get-started-with-olenium"
                element={<DesignerOnboarding />}
              />

              {/* Admin Routes */}
              {userRole === "Admins" && (
                <>
                  <Route path="/admin-panel" element={<AdminPanel />} />
                  <Route
                    path="/admin-panel/projects"
                    element={<AdminProjects />}
                  />
                  <Route
                    path="/admin-panel/user-manager"
                    element={<AdminUserManager />}
                  />
                  <Route
                    path="/admin-panel/provider-manager"
                    element={<AdminProviderManager />}
                  />
                  <Route
                    path="/admin-panel/user-feedback"
                    element={<AdminUserFeedback />}
                  />
                  <Route
                    path="/admin-panel/services-manager"
                    element={<AdminServiceManager />}
                  />
                  <Route
                    path="/admin-panel/invoice-manager"
                    element={<AdminInvoiceManager />}
                  />
                  <Route path="/admin-panel/add-user" element={<AdminAddUser />} />
                  <Route
                    path="/admin-panel/add-provider"
                    element={<AdminAddProvider />}
                  />

                </>
              )}

              <Route path="*" element={<Error />} />
            </Routes>
            {context.userDetails && context.userDetails.firstName && (
              <WelcomeModal
                isOpen={isWelcomeModalOpen}
                onClose={() => setIsWelcomeModalOpen(false)}
              />
            )}
          </Layout>
        ) : (
          renderOnboardingComponent()
        )
      ) : (

        //<LandingLayout>
          <Routes>
            <Route path="/" element={<AuthLayout><Registration /></AuthLayout>} />
            {/* <Route path="/services" element={<Services />} />
            <Route path="/our-people" element={<OurPeople />} /> */}
            <Route path="/work-on-olenium" element={<WorkAtOleniumLoggedOut />} />
            <Route exact path="/explore/:serviceTitle" element={<Explore />} />
            {/* <Route path="/explore" element={<Explore />} /> */}
            <Route path="/login" element={<AuthLayout><Login /></AuthLayout>} />
            <Route path="/provider-registration" element={<AuthLayout><ProviderRegistration /></AuthLayout>} />
            <Route path="/email-verification" element={<AuthLayout><EmailLink /></AuthLayout>} />
            <Route path="/forgot-password" element={<AuthLayout><ForgotPassword /></AuthLayout>} />
            <Route path="*" element={<ErrorLoggedOut />} />
          </Routes>
        //</LandingLayout>

      )}
    </>
  );
};

export default App;
