import { PhoneIcon } from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Spacer,
  Text,
  Tooltip,
  useColorModeValue,
  useToast,
  InputRightElement,
} from "@chakra-ui/react";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { BsShieldFillExclamation } from "react-icons/bs";
import { UserContext } from "../../../../UserContext";
import { getIdToken } from "../../../../cognito/cognitoAuth";
import EditableAvatar from "./EditableAvatar";

const Profile = () => {
  const textColor = useColorModeValue("oleniumGray.700", "white");
  const boxColor = useColorModeValue("white", "oleniumBlack.600");
  const toast = useToast(); // Create an instance of the useToast hook
  const apiUrl = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(false);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const phoneRegex = /^\+[1-9]\d{1,14}$/; // Regex pattern to match phone number format allowed by AWS
  const [isIdentityVerified, setIsIdentityVerified] = useState(false);
  const [verificationLoading, setVerificationLoading] = useState(false);
  const { userDetails, setUserDetails } = useContext(UserContext);
  const [isCopied, setIsCopied] = useState(false);

  const defaultUserDetails = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    id: "",
    profilePicture: "",
  };

  const [inputValues, setInputValues] = useState({
    given_name: userDetails?.firstName || defaultUserDetails.firstName,
    family_name: userDetails?.lastName || defaultUserDetails.lastName,
    phone_number: userDetails?.phoneNumber || defaultUserDetails.phoneNumber,
    profilePicture: userDetails?.profilePicture || "",
    is_sk_onboarded:
      userDetails?.isSkOnboarded || defaultUserDetails.isSkOnboarded,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputValues({
      ...inputValues,
      [name]: value,
    });

    setIsFormChanged(true);
  };

  const updateUserDetails = async (values) => {
    try {
      const idToken = await getIdToken();
      setLoading(true);
      const response = await axios.put(
        apiUrl + "/users",
        {
          given_name: values.given_name,
          family_name: values.family_name,
          phone_number: values.phone_number,
          profile_picture_url: values.profilePicture,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
        },
      );

      if (response.status === 200) {
        // Update user details in context
        setUserDetails((prevUserDetails) => ({
          ...prevUserDetails,
          firstName: values.given_name,
          lastName: values.family_name,
          phoneNumber: values.phone_number,
        }));

        setLoading(false);
        toast({
          title: "Success",
          description: "User details updated successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      setLoading(false);
      console.error("Error updating user details", error);
      // Show error toast
      toast({
        title: "Error",
        description: "Error updating user details.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const displayedUserRole = (role) => {
    switch (role) {
      case "Providers":
        return "Provider";
      case "Clients":
        return "Client";
      case "Admins":
        return "Admin";
      case "ProviderApplicant":
        return "Awaiting Approval";
      default:
        return "Unknown Role";
    }
  };

  const validatePhoneNumber = () => {
    if (inputValues.phone_number.trim() === "") return ""; // Return no error if the phone number field is empty
    if (!inputValues.phone_number.match(phoneRegex)) {
      // Phone number format is not valid
      return "Phone number format is not valid.";
    }
    return "";
  };

  const isPhoneNumberValid = validatePhoneNumber() === "";




  const checkStripeIdentityVerification = async () => {
    try {
      setVerificationLoading(true);
      const IdToken = await getIdToken();
      const response = await axios.get(apiUrl + "/stripe/identity/check", {
        headers: {
          Authorization: `Bearer ${IdToken}`,
        },
      });

      if (response.status === 200) {
        setIsIdentityVerified(true);
        setVerificationLoading(false);
      }

    } catch (error) {
      console.error("Error checking Stripe identity verification", error);

      toast({
        title: "Your identity is not verified.",
        description: "We need you to do a quick identity verification check.",
        status: "info",
        duration: 9000,
        isClosable: true,
      });

      setIsIdentityVerified(false);
      setVerificationLoading(false);
    }
  };

  const handleIdentityVerification = async () => {
    try {

      setVerificationLoading(true);
      // Get the ID token from Cognito
      const idToken = await getIdToken();

      // Make an API call to your backend Lambda function
      const response = await axios.post(
        `${apiUrl}/stripe/identity`,
        {},
        { headers: { Authorization: idToken } }
      );

      // Load Stripe
      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

      const { error } = await stripe.verifyIdentity(response.data.client_secret);

      if (error) {
        console.error("Failed to verify identity", error);
        // Handle error
        setIsIdentityVerified(false);
        setVerificationLoading(false);
      } else {
        setIsIdentityVerified(true);
        setVerificationLoading(false);
      }
    } catch (error) {
      console.error("Failed to initiate identity verification", error);
      setIsIdentityVerified(false);
      setVerificationLoading(false);
    }
  };

  useEffect(() => {

    // if user role is Provider
    if (userDetails?.userRole === "Providers") {
      checkStripeIdentityVerification();
    }
  }, []);

  const handleCopyClick = async () => {
    navigator.clipboard.writeText(userDetails.id)
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000); // Reset after 2 seconds
  };


  return (
    <>
      <Flex direction="column" align="center" justify="center" w="100%">
        <Box
          bg={boxColor}
          p={5}
          rounded="2xl"
          maxW={"100%"}
          w={"100%"}
          shadow="lg"
        >
          <Flex direction="row" align="center" justify="space-between">
            <EditableAvatar
              userDetails={userDetails}
              defaultUserDetails={defaultUserDetails}
              isVerified={isIdentityVerified}
            />
            <Box ml="4">
              <HStack spacing={1} alignItems="flex-start">
                <Text fontSize="lg" fontWeight="bold" align={"left"}>
                  {userDetails?.firstName || defaultUserDetails.firstName}{" "}
                  {userDetails?.lastName || defaultUserDetails.lastName}
                </Text>
                <Badge
                  variant="outline"
                  rounded={"full"}
                  px={2}
                  py={1}
                  mt={1}
                  ml={2}
                  size="xs"
                  fontSize="0.5em"
                  colorScheme={
                    userDetails?.userRole === "Providers" ||
                      userDetails?.userRole === "Clients" ||
                      userDetails?.userRole === "Admins"
                      ? "green"
                      : "yellow"
                  }
                >
                  {displayedUserRole(userDetails?.userRole)}
                </Badge>
              </HStack>
              <Text fontSize={"xs"} align={"left"}>
                {userDetails?.email || defaultUserDetails.email}
              </Text>
            </Box>
            <Spacer />
            {!isIdentityVerified && !verificationLoading && userDetails?.userRole === "Providers" && (
              // show on desktop
              <Box display={{ base: "none", md: "block" }}>
                <Button
                  variant="oleniumBlue"
                  size="sm"
                  ml="auto"
                  leftIcon={<BsShieldFillExclamation />}
                  onClick={handleIdentityVerification}
                  isLoading={verificationLoading}
                >
                  Verify your identity
                </Button>
              </Box>

            )}
          </Flex>
          {!isIdentityVerified && !verificationLoading && userDetails?.userRole === "Providers" && (
            // show on mobile
            <Box mt={8} display={{ base: "block", md: "none" }}>
              <Button
                variant="oleniumBlue"
                size="sm"
                ml="auto"
                leftIcon={<BsShieldFillExclamation />}
                onClick={handleIdentityVerification}
                isLoading={verificationLoading}
                w={"100%"}
              >
                Verify your identity
              </Button>
            </Box>
          )}
        </Box>
      </Flex>
      <Flex
        w={"100%"}
        my={5}
        alignSelf={{ lg: "flex-end" }}
        justifySelf={{ lg: "flex-end" }}
      >
        <Box bg={boxColor} w="100%" p={8} rounded="2xl" shadow="xl">
          <Heading as="h1" size="md" fontWeight="bold" mb={2} color={textColor}>
            Profile
          </Heading>
          <Divider my={4} />

          <Grid
            templateColumns={{ sm: "repeat(1, 1fr)", lg: "repeat(2, 1fr)" }}
            gap={6}
          >
            <GridItem colSpan={1}>
              <FormLabel fontWeight="semibold" fontSize="sm" mb="10px">
                Email Address
              </FormLabel>
              <Tooltip
                label="Your email can't be changed"
                aria-label="A tooltip"
              >
                <Input
                  borderRadius="lg"
                  fontSize="sm"
                  isReadOnly={true}
                  placeholder={
                    userDetails ? userDetails.email : "Email Address"
                  }
                />
              </Tooltip>
            </GridItem>
            <GridItem colSpan={1}>
              <FormLabel fontWeight="semibold" fontSize="sm" mb="10px">
                User ID
              </FormLabel>
              <InputGroup size="md" mb={4}>
                <Input
                  pr="4.5rem"
                  value={userDetails ? userDetails.id : "User ID"}
                  isReadOnly
                />
                <InputRightElement width="4.5rem">
                  <Button h="1.8rem" size="sm" onClick={handleCopyClick} w="4rem" mr={1}>
                    {isCopied ? "Copied" : "Copy"}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </GridItem>
            <GridItem colSpan={1}>
              <FormLabel fontWeight="semibold" fontSize="sm" mb="10px">
                First Name
              </FormLabel>
              <Input
                name="given_name"
                borderRadius="lg"
                fontSize="sm"
                placeholder={userDetails ? userDetails.firstName : "First Name"}
                value={inputValues.given_name}
                onChange={handleInputChange}
              />
            </GridItem>
            <GridItem colSpan={1}>
              <FormLabel fontWeight="semibold" fontSize="sm" mb="10px">
                Last Name
              </FormLabel>
              <Input
                name="family_name"
                borderRadius="lg"
                fontSize="sm"
                placeholder={userDetails ? userDetails.lastName : "Last Name"}
                value={inputValues.family_name}
                onChange={handleInputChange}
              />
            </GridItem>
            {/* <GridItem colSpan={1}>
              <FormLabel fontWeight="semibold" fontSize="sm" mb="10px">
                Phone Number
              </FormLabel>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <PhoneIcon color="oleniumGray.500" />
                </InputLeftElement>
                <Input
                  name="phone_number"
                  autoComplete="tel"
                  borderRadius="lg"
                  fontSize="sm"
                  type="text" // Changed from "number" to "text"
                  placeholder={
                    userDetails ? userDetails.phoneNumber : "Phone Number"
                  }
                  value={inputValues.phone_number}
                  onChange={handleInputChange}
                />
              </InputGroup>
              {!isPhoneNumberValid && ( // Notice the negation here
                <Text color="red.500" fontSize="sm" mt={1}>
                  {validatePhoneNumber()}
                </Text>
              )}
            </GridItem> */}
          </Grid>
          <Flex direction="row" align="center" justify="space-between" mt={8}>
            <Spacer />
            <Button
              variant="oleniumBlue"
              size="sm"
              onClick={() => updateUserDetails(inputValues)}
              isLoading={loading}
              isDisabled={!isFormChanged}
            >
              Save Changes
            </Button>
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

export default Profile;
