import React, { useEffect } from "react";

import { Flex, VStack } from "@chakra-ui/react";

import BasicInfo from "./Sections/Profile";
// import TeamSettings from "./Sections/TeamSettings";
import { UserContext } from "../../../UserContext";
import AwaitingApproval from "./Sections/AwaitingApproval";
import BusinessProfile from "./Sections/BusinessProfile";
import ChangePassword from "./Sections/ChangePassword";
import DeleteAccount from "./Sections/DeleteAccount";
import DigitalBusinessCard from "./Sections/DigitalBusinessCard";
import PayOutInfo from "./Sections/PayOutInfo";
import WorkAtOleniumCTA from "./Sections/WorkAtOlenium";
import SocialMediaPortfolio from "./Sections/SocialMediaPortfolio";

const Settings = () => {
  const { userDetails } = React.useContext(UserContext);

  // Add null check for userDetails and default to an empty string
  const [userRole, setUserRole] = React.useState(
    userDetails ? userDetails.userRole : "",
  );

  useEffect(() => {
    setUserRole(userDetails ? userDetails.userRole : "");
  }, [userDetails]);

  useEffect(() => {
    // set window scroll to top
    window.scrollTo(0, 0);
  }, []);

  return (
    <Flex justify={"flex-start"} direction={"column"} align={"left"} w={"100%"}>
      <VStack spacing={4} align="center" w={"100%"} maxW={"800px"} mx={"auto"}>
        {userRole === "Providers" && (
          <>
            <BasicInfo />
            <BusinessProfile />
            <PayOutInfo />
            <ChangePassword />
            <DeleteAccount />
          </>
        )}

        {userRole === "Clients" && (
          <>
            <BasicInfo />
            <WorkAtOleniumCTA />
            <BusinessProfile />
            <ChangePassword />
            <DeleteAccount />
          </>
        )}\

        {userRole === "ProviderApplicant" && (
          <>
            <BasicInfo />
            <AwaitingApproval />
            <BusinessProfile />
            <ChangePassword />
            <DeleteAccount />
          </>
        )}

        {userRole === "Admins" && (
          <>
            <BasicInfo />
            <BusinessProfile />
            <WorkAtOleniumCTA />
            <ChangePassword />
            <DeleteAccount />
          </>
        )}
      </VStack>
    </Flex>
  );
};

export default Settings;
