const assetTypes = [
  { value: "LOGO", label: "Logo" },
  { value: "IMAGE", label: "Image" },
  { value: "BRANDING_GUIDELINE", label: "Branding Guideline" },
  { value: "COLOUR_PALETTE", label: "Colour Palette" },
  { value: "TYPOGRAPHY", label: "Typography" },
  { value: "ICON_SET", label: "Icon Set" },
  { value: "MARKETING_MATERIAL", label: "Marketing Material" },
  { value: "PRESENTATION_TEMPLATE", label: "Presentation Template" },
  { value: "WEBSITE_DESIGN", label: "Website Design" },
  { value: "LETTERHEAD", label: "Letterhead" },
  { value: "BUSINESS_CARD", label: "Business Card" },
  { value: "ENVELOPE", label: "Envelope" },
  { value: "SIGNAGE", label: "Signage" },
  { value: "STICKER", label: "Sticker" },
  { value: "FLYER", label: "Flyer" },
  { value: "POSTER", label: "Poster" },
  { value: "SOCIAL_MEDIA_ASSET", label: "Social Media Asset" },
  { value: "EMAIL_SIGNATURE", label: "Email Signature" },
  { value: "BROCHURE", label: "Brochure" },
  { value: "CATALOG", label: "Catalog" },
  { value: "INFOGRAPHIC", label: "Infographic" },
  { value: "ANIMATION", label: "Animation" },
  { value: "NEWSLETTER", label: "Newsletter" },
  { value: "PRESS_KIT", label: "Press Kit" },
  { value: "PHOTOGRAPHY", label: "Photography" },
  { value: "VEHICLE_WRAP", label: "Vehicle Wrap" },
  { value: "APPAREL_DESIGN", label: "Apparel Design" },
  { value: "PACKAGING", label: "Packaging" },
  { value: "EXHIBITION_STANDS", label: "Exhibition Stands" },
  { value: "OTHER", label: "Other" },
];

export default assetTypes;
