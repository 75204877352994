import { AddIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";

import axios from "axios";
import { getIdToken } from "../../../../cognito/cognitoAuth";

const API_URL = process.env.REACT_APP_API_URL;

const assetTypes = [
  { value: "LOGO", label: "Logo" },
  { value: "IMAGE", label: "Image" },
  { value: "BRANDING_GUIDELINE", label: "Branding Guideline" },
  { value: "COLOUR_PALETTE", label: "Colour Palette" },
  { value: "TYPOGRAPHY", label: "Typography" },
  { value: "ICON_SET", label: "Icon Set" },
  { value: "MARKETING_MATERIAL", label: "Marketing Material" },
  { value: "PRESENTATION_TEMPLATE", label: "Presentation Template" },
  { value: "WEBSITE_DESIGN", label: "Website Design" },
  { value: "LETTERHEAD", label: "Letterhead" },
  { value: "BUSINESS_CARD", label: "Business Card" },
  { value: "ENVELOPE", label: "Envelope" },
  { value: "SIGNAGE", label: "Signage" },
  { value: "STICKER", label: "Sticker" },
  { value: "FLYER", label: "Flyer" },
  { value: "POSTER", label: "Poster" },
  { value: "SOCIAL_MEDIA_ASSET", label: "Social Media Asset" },
  { value: "EMAIL_SIGNATURE", label: "Email Signature" },
  { value: "BROCHURE", label: "Brochure" },
  { value: "CATALOG", label: "Catalog" },
  { value: "INFOGRAPHIC", label: "Infographic" },
  { value: "ANIMATION", label: "Animation" },
  { value: "NEWSLETTER", label: "Newsletter" },
  { value: "PRESS_KIT", label: "Press Kit" },
  { value: "PHOTOGRAPHY", label: "Photography" },
  { value: "VEHICLE_WRAP", label: "Vehicle Wrap" },
  { value: "APPAREL_DESIGN", label: "Apparel Design" },
  { value: "PACKAGING", label: "Packaging" },
  { value: "EXHIBITION_STANDS", label: "Exhibition Stands" },
  { value: "OTHER", label: "Other" },
];

const AddDelivery = ({ project_id, client_id }) => {
  const textColor = useColorModeValue("oleniumBlack.600", "white");
  const boxColor = useColorModeValue("white", "oleniumBlack.600");

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [fileName, setFileName] = useState("");
  const [uploadProgress, setUploadProgress] = useState({});
  const [formValues, setFormValues] = useState({
    deliveryType: "",
    assetType: "",
  });
  const toast = useToast();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      for (let file of files) {
        await uploadFile(file);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      toast({
        title: "Error.",
        description: "Something went wrong. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
      onClose();
    }
  };

  const fileInputRef = useRef(null); // New line to hold a reference to the hidden file input

  const handleFileButtonClick = () => {
    // New function to trigger file input click
    fileInputRef.current.click();
  };

  const updateFileProgress = (file, progress) => {
    setUploadProgress({ ...uploadProgress, [file.name]: progress });
  };

  const handleFileChange = (event) => {
    setFiles(event.target.files);

    if (event.target.files.length > 0) {
      setFileName(event.target.files[0].name); // Update the fileName state
    }
  };

  const handleChange = (event) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
  };

  const getSignedUrl = async (file) => {
    const IdToken = await getIdToken();

    const response = await axios.post(
      `${API_URL}/assets/delivery`,
      {
        file_name: file.name, // Use file.name here
        content_type: file.type,
        delivery_type: formValues.deliveryType,
        asset_type: formValues.assetType,
        project_id: project_id,
        client_id: client_id,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${IdToken}`,
        },
      },
    );

    return response.data.body;
  };

  const uploadFile = async (file) => {
    try {
      const signedUrl = await getSignedUrl(file);

      if (!signedUrl.startsWith("http")) {
        throw new Error(`Invalid signed URL: ${signedUrl}`);
      }

      const response = await axios.put(signedUrl, file, {
        headers: {
          "Content-Type": file.type,
        },
        onUploadProgress: (progressEvent) => {
          const progressPercentage = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          );
          updateFileProgress(file, progressPercentage);
        },
        withCredentials: false,
        responseType: "text",
      });

      toast({
        // Show success toast when done
        title: "Success.",
        description: "Delivery added successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("File upload failed:", error);
      toast({
        // Show error toast when something goes wrong
        title: "Error.",
        description: "Something went wrong. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setFileName(""); // reset file name
    }
  };

  return (
    <Box w="100%" bg={boxColor} rounded="xl" p={8} mb={6}>
      <Text color={textColor} fontSize="md" fontWeight="bold" mb={2}>
        Deliveries
      </Text>

      <Text color={textColor} fontSize="sm" mb={2}>
        Deliveries are files that you will be sending to your client and they
        will be notified once you send them.
      </Text>

      <Divider my={4} />

      <Button
        variant="oleniumBlue"
        onClick={onOpen}
        size={"sm"}
        leftIcon={<AddIcon />}
        w={"100%"}
      >
        Add Delivery
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent bg={boxColor} rounded="xl" p={4}>
          <ModalHeader>Add New Delivery</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleSubmit}>
            <ModalBody>
              <FormControl mb={4}>
                <FormLabel>Delivery File</FormLabel>
                <Input
                  name="file"
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                {/* Chakra button for file upload */}
                <Button onClick={handleFileButtonClick}>Upload File</Button>
                {/* Display selected file name */}
                {fileName && <Text my={2}>{fileName}</Text>}
              </FormControl>
              <FormControl mb={4}>
                <FormLabel>Asset Type</FormLabel>
                <Select
                  name="assetType"
                  value={formValues.assetType}
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    Select Asset Type
                  </option>
                  {assetTypes.map((assetType) => (
                    <option key={assetType.value} value={assetType.value}>
                      {assetType.label}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl mb={4}>
                <FormLabel>Delivery Type</FormLabel>
                <Select
                  name="deliveryType"
                  value={formValues.deliveryType}
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    Select Delivery Type
                  </option>
                  <option value="DRAFT_ASSET">Draft Asset</option>
                  <option value="ITERATION_ASSET">Iteration Asset</option>
                  <option value="FINAL_ASSET">Final Asset</option>
                </Select>
              </FormControl>
            </ModalBody>
            <ModalFooter justifyContent="space-between">
              <Button variant="ghost" onClick={onClose}>
                Close
              </Button>
              <Button variant="oleniumBlue" type="submit" isLoading={loading}>
                Add Delivery
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default AddDelivery;
