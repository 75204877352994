import {
  Alert,
  Box,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  RadioGroup,
  Spinner,
  Text,
  HStack,
  Heading,
  Icon,
  VStack,
} from "@chakra-ui/react";
import axios from "axios";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import loadingAnimationData from "../../../../assets/animations/NewProjectLoading.json";
import { getIdToken } from "../../../../cognito/cognitoAuth";
import CapabilityCard from "./CapabilityCard";
import backgroundImage from "../../../../assets/images/payment-background.jpg";

import OleniumLogoAnimated from "../../../../../src/components/OleniumLogoAnimated";
import { BiSolidCheckCircle } from "react-icons/bi";
import { useLocation, useNavigate, useParams } from "react-router-dom";


const API_URL = process.env.REACT_APP_API_URL;

const ProjectService = ({ selectedService, setSelectedService }) => {
  const [alert, setAlert] = useState({ show: false, status: "", message: "" });
  const [services, setServices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [servicesUnavailable, setServicesUnavailable] = useState(false);
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);



  const loadingOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };



  useEffect(() => {
    const fetchServices = async () => {
      setIsLoading(true);
      try {
        const IdToken = await getIdToken();
        const response = await axios.get(API_URL + "/services", {
          headers: {
            Authorization: `Bearer ${IdToken}`,
            "Content-Type": "application/json",
          },
        });
  
        if (response.status === 200 && response.data.statusCode === 200) {
          let servicesData = JSON.parse(response.data.body);
          if (Array.isArray(servicesData) && servicesData.length > 0) {
            // Sort services so that internal services appear at the top
            servicesData = servicesData.sort((a, b) => {
              if (a.is_internal === b.is_internal) return 0; // No change if both have the same is_internal status
              if (a.is_internal) return -1; // a comes first if it is internal
              return 1; // otherwise, b comes first
            });
  
            setServices(servicesData);
            setIsLoading(false);
            setServicesUnavailable(false); // Ensure modal doesn't show when services are available
          } else {
            // No services were fetched
            setIsLoading(false);
            setServicesUnavailable(true);
            setIsModalOpen(true); // Open the "Coming Soon" modal
          }
        } else {
          throw new Error("Failed to fetch services.");
        }
      } catch (error) {
        setIsLoading(false);
        setServicesUnavailable(true);
        setIsModalOpen(true); // Open the "Coming Soon" modal on error
        console.error("Error fetching services", error);
        setAlert({
          show: true,
          status: "error",
          message: error.message,
        });
      }
    };
  
    fetchServices();
  }, []);
  


  const handleChange = (value) => {
    setSelectedService(value);
  };


  return (
    <Box>
      {isLoading ? (
        <Center mt={4} mb={4} height="100%" width="100%">
          <Spinner size="xl" />
        </Center>
      ) : (
        <RadioGroup onChange={handleChange} value={selectedService} mx={4}>
          {services.map((element, key) => {
            return (
              <motion.div
                key={key}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.1 * key }}
              >
                <CapabilityCard
                  value={element.id}
                  title={element.name}
                  description={element.description}
                  icon={element.icon}
                  onChange={handleChange}
                  selectedOption={selectedService === element.id}
                  isDisabled={!element.is_active}
                />
              </motion.div>
            );
          })}
        </RadioGroup>
      )}

      {/* {alert.show && (
        <Alert status={alert.status} mt={4} rounded="xl">
          {alert.message}
        </Alert>
      )} */}

      {servicesUnavailable && (
        <Alert status="info" mt={4} rounded="xl" colorScheme="blue">
          We're getting ready to launch Olenium. Please check back later for new capabilities.
        </Alert>
      )}
      {/* Coming Soon Modal */}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} isCentered>
        <ModalOverlay />
        <ModalContent
          bgImage={`url(${backgroundImage})`}
          bgSize="cover"
          bgPos="center"
          borderRadius="10px"
          p={10}
          margin={5}
        >
          <ModalHeader>
            <ModalCloseButton onClick={() => navigate("/")} />
          </ModalHeader>
          <ModalBody>
            <OleniumLogoAnimated />
            <Text fontSize="md" mt={3} fontWeight="bold">
              Coming Soon
            </Text>
            <HStack alignItems="baseline" spacing={0}>
            </HStack>
            <HStack>
              <Heading as="h3" size="md" color="white" mt={8}>
                We're working hard behind the scenes to bring you new capabilities
              </Heading>

          
            </HStack>
            <Text fontSize="md" my={6}>
                We hope to have them ready for you soon. Please check back later
            </Text>
            
          </ModalBody>
          <ModalFooter>
            <VStack spacing={4} w={"100%"}>
              <Button
                variant="oleniumBlue"
                size="md"
                fontSize="md"
                fontWeight="bold"
                loadingText="Processing..."
                width={"100%"}
                onClick={() => navigate("/")}
              >
                Go Back
              </Button>
            </VStack>
          </ModalFooter>
        </ModalContent>
      </Modal>

    </Box>
  );
};

export default ProjectService;
