import React, { useEffect, useState } from "react";
import {
  Avatar, Box, Button, Flex, FormControl, FormLabel, HStack, Heading, IconButton,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter,
  Table, Tag, Tbody, Td, Text, Th, Thead, Tr, useDisclosure, useToast, Input, Spacer, Grid, GridItem, Divider, Image
} from "@chakra-ui/react";
import axios from "axios";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { getIdToken } from "../../../../cognito/cognitoAuth";
import PortfolioItemModal from '../../NewProject/Forms/PortfolioModal';
import { CloseIcon } from "@chakra-ui/icons";
import ImportUsersModal from "./ImportUsers";
import AddProfile from "./AddProfile";

const UserManager = () => {
  const [users, setUsers] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isProfileOpen, onOpen: onProfileOpen, onClose: onProfileClose } = useDisclosure();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [userType, setUserType] = useState('ProviderApplicant');
  const toast = useToast();
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const [applications, setApplications] = useState([]);
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [profileIsOpen, setProfileOpen] = useState(false);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const idToken = await getIdToken();
      const response = await axios.get(`${API_URL}/users/providers`, {
        headers: { Authorization: `Bearer ${idToken}` }
      });
      setUsers(response.data.body);
    } catch (error) {
      toast({
        title: "Failed to fetch users",
        status: "error",
        duration: 3000,
        isClosable: true
      });
    }
  };

  const openProfile = (user) => {
    setSelectedUser(user);
    const hasApplication = applications.some(app => app.provider_id === user.username);
    setProfileOpen(hasApplication);

    if (hasApplication) {
      const application = applications.find(app => app.provider_id === user.username);
      setSelectedApplication(application);
    }
  };

  const [isPortfolioUploadOpen, setIsPortfolioUploadOpen] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(null);

  const openPortfolioUploadModal = (userId) => {
    setCurrentUserId(userId);
    setIsPortfolioUploadOpen(true);
  };

  useEffect(() => {
    fetchUsers();
  }, []);
  

  const closeProfile = () => {
    setProfileOpen(false);
  };
  

  const fetchApplications = async () => {
    try {
      const authentication = await getIdToken();
      const response = await axios.get(`${API_URL}/provider/all`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authentication}`,
        },
      });
      if (response.data.statusCode === 200) {
        const parsedBody = JSON.parse(response.data.body);
        const parsedApplications = parsedBody.applications;

        if (Array.isArray(parsedApplications)) {
          const applicationsWithDetails = await Promise.all(
            parsedApplications.map(async (app) => {
              const userResponse = await fetch(
                `${API_URL}/users/info/${app.provider_id}`,
                {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authentication}`,
                  },
                },
              );

              const userData = await userResponse.json();
              if (userData.statusCode === 200) {
                app.given_name = userData.body.given_name;
                app.family_name = userData.body.family_name;
                app.profile_picture_url = userData.body.profile_picture_url;
              }
              return app;
            }),
          );
          setApplications(applicationsWithDetails);
        }
      } else {
        console.error("Error status code:", response.data.statusCode);
        setApplications([]);
      }
    } catch (error) {
      console.error("Error fetching applications:", error);
    }
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    const randomPassword = Math.random().toString(36).slice(-8);
    const idToken = await getIdToken();

    try {
      await axios.post(`${API_URL}/users/admin/create`, {
        firstName, lastName, email, password: randomPassword, group: userType
      }, {
        headers: { Authorization: `Bearer ${idToken}` }
      });
      showSuccessToast("User created successfully");
      fetchUsers();
      onClose();
    } catch (error) {
      showErrorToast("Error creating user: " + error.message);
    }
  };

  const showSuccessToast = (message) => {
    toast({
      title: message,
      status: "success",
      duration: 3000,
      isClosable: true
    });
  };

  const showErrorToast = (message) => {
    toast({
      title: message,
      status: "error",
      duration: 3000,
      isClosable: true
    });
  };

  const getBadgeColor = (role) => {
    switch (role) {
      case "Clients": return "green";
      case "Providers": return "blue";
      case "Admins": return "purple";
      default: return "gray";
    }
  };

  const getProviderDetails = async (providerId, provider) => {
    const idToken = await getIdToken();

    try {
      const applicationResponse = await axios.get(
        `${API_URL}/provider/${providerId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
        },
      );

      if (applicationResponse.status === 200) {
        // Parse the JSON response
        const parsedData = JSON.parse(applicationResponse.data.body);

        console.log("Parsed application data:", parsedData);
        console.log("Bio from parsedData:", parsedData.bio);


        // add provider details to the parsed data
        parsedData.given_name = provider.given_name;
        parsedData.family_name = provider.family_name;
        parsedData.profile_picture_url = provider.profile_picture_url;

        // Use the extracted data (for example, setting state in a React component)
        setSelectedApplication(parsedData);
        console.log("Application details:", parsedData);

        // open the modal
        onProfileOpen();

        
      } if (applicationResponse.status === 404) {
        console.error("Application not found :((");
      } else {
        console.error(
          "Unexpected response status:",
          applicationResponse.status,
        );
      }
    } catch (error) {
      console.error("Error fetching application details:", error);
    }
  };

  const [isPortfolioItemModalOpen, setIsPortfolioItemModalOpen] = useState(false);
  const [selectedPortfolioItem, setSelectedPortfolioItem] = useState(null);
  const [designer, setDesigner] = useState(null);
  const [serviceIdToNameMapping, setServiceIdToNameMapping] = useState({});

  const openPortfolioItemModal = (item) => {
    setSelectedPortfolioItem(item);
    setIsPortfolioItemModalOpen(true);
  }

  const onClosePortfolioItemModal = () => {
    setIsPortfolioItemModalOpen(false);
  }

  return (
    <Box>
      <Flex justifyContent="space-between" mb={10}>
        <Flex>
          <IconButton aria-label="Back" icon={<FaArrowLeft />} onClick={() => navigate("/admin-panel")} />
          <Heading as="h1" size="lg" ml={5} mt={1}>Providers</Heading>
        </Flex>
        <Flex>
          <Button colorScheme="blue" size="xs" mr={2}>Import Users</Button>
          <Button colorScheme="blue" size="xs" onClick={onOpen}>Add Provider</Button>
        </Flex>
      </Flex>

      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Username</Th>
            <Th>Email</Th>
            <Th>Group</Th>
            <Th>Profile</Th>
          </Tr>
        </Thead>
        {users.length === 0 && (
          <Tbody>
            <Tr>
              <Td colSpan={4} textAlign="center">No users found</Td>
            </Tr>
          </Tbody>
        )}
        <Tbody>
          {users.map((user) => (
            <Tr key={user.Username}>
              <Td>
                <HStack justify="flex-start">
                  <Avatar size="xs" name={`${user.given_name} ${user.family_name}`} src={user.profile_picture_url} />
                  <Text color="white" fontSize="xs" fontWeight="bold">
                    {`${user.given_name} ${user.family_name}`}
                  </Text>
                </HStack>
              </Td>
              <Td>{user.email}</Td>
              <Td>
                <Tag colorScheme={getBadgeColor(user.user_role)} size="sm" variant="outline" rounded="full">
                  {user.user_role}
                </Tag>
              </Td>
              <Td>

              <Flex justifyContent="space-between">
              <Button
                size="sm"
                onClick={() => {
                  if (user) getProviderDetails(user.username, user);
                }}
                w={"100%"}
                mr={2}
              >
                View Profile
              </Button>

              <Button
                size="sm"
                onClick={() => openPortfolioUploadModal(user.username)}
                w={"100%"}
              >
                Add Profile
              </Button>
              </Flex>

              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      {/* Modal for adding a new provider user */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add New Provider User</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>First Name</FormLabel>
              <Input value={firstName} onChange={(e) => setFirstName(e.target.value)} />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Last Name</FormLabel>
              <Input value={lastName} onChange={(e) => setLastName(e.target.value)} />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Email</FormLabel>
              <Input value={email} onChange={(e) => setEmail(e.target.value)} />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
              Save
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isProfileOpen} onClose={onProfileClose} size="4xl">
        <ModalOverlay />
        <ModalContent
          background="oleniumBlack.700"
          color="white"
          borderRadius="3xl"
          p={2}
        >
          <ModalHeader>
            <ModalBody overflowY="auto">

              <HStack spacing={4} mb={4} w="100%" alignItems="center" justifyContent="space-between">
                <Heading as="h2" size="lg">
                  {selectedApplication?.given_name}{" "}
                  {selectedApplication?.family_name}
                </Heading>
                <Spacer />
                <CloseIcon onClick={onProfileClose} size="md" cursor="pointer" />
                {/* <Hide below="md">
                  <Tag
                    size="sm"
                    borderRadius="full"
                    variant="outline"
                    px={2}
                    py={1}
                    height="fit-content"
                  >
                    {convertToMemberSince(selectedApplication?.created_at)}
                  </Tag>
                </Hide> */}
              </HStack>

              <Divider mb={8} />

              <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }} gap={4} mb={8}>
                <GridItem colSpan={1} rowSpan={1} position="relative">
                  <Heading as="h3" size="md" mb={4}>
                    About
                  </Heading>
                  <Text
                    fontSize={{ base: "xs", md: "sm" }}
                    textAlign={"left"}
                    fontWeight="normal"
                    mb={4}
                    color={"oleniumGray.700"}
                  >
                    {selectedApplication?.bio}
                  </Text>
                </GridItem>

                <GridItem colSpan={1} rowSpan={1} position="relative">
                  <Heading as="h3" size="md" mb={4}>
                    Services
                  </Heading>
                  <HStack alignItems="flex-start" spacing={2} mb={4} wrap={"wrap"}>
                    {selectedApplication?.work?.selectedServices
                      .filter(serviceId => serviceIdToNameMapping.hasOwnProperty(serviceId))
                      .map((serviceId, index) => (
                        <Tag
                          size="md"
                          borderRadius="md"
                          px={2}
                          py={1}
                          background={"oleniumBlack.500"}
                          key={index}
                        >
                          {serviceIdToNameMapping[serviceId]}
                        </Tag>
                      ))}
                  </HStack>
                </GridItem>
              </Grid>
              <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)", lg: "repeat(3, 1fr)" }} gap={4}>
                {selectedApplication?.portfolio_items?.map((item, index) => (
                    <GridItem
                      colSpan={1}
                      rowSpan={1}
                      position="relative"
                      key={index}
                      h={"100%"}
                      onClick={() => openPortfolioItemModal(item)}

                    >
                      <Image
                        src={item.images[0]}
                        alt="portfolio item"
                        objectFit="cover"
                        w={"100%"}
                        h={"100%"}
                        borderRadius="xl"
                        zIndex={1}
                        overflow={"hidden"}
                      />
                      <Box
                        position="absolute"
                        bottom="0"
                        left="0"
                        w={"100%"}
                        h={"100%"}
                        bgGradient="linear(to-b, transparent, rgba(0, 0, 0, 0.5))"
                        opacity="0"
                        _hover={{ opacity: "1", cursor: "pointer", borderColor: "#0085FF" }}
                        zIndex={2}
                        display="flex"
                        flexDirection="column"
                        justifyContent="flex-end"
                        borderRadius="xl"
                        borderWidth={"2px"}
                        borderColor={"oleniumBlack.100"}
                      >
                        <Text
                          fontSize="lg"
                          color="white"
                          textAlign={"left"}
                          mb={4}
                          ml={4}
                          fontWeight="bold"
                        >
                          {item.title}
                        </Text>
                      </Box>
                    </GridItem>
                ))}
              </Grid>
            </ModalBody>
          </ModalHeader>
        </ModalContent>
      </Modal >

      <PortfolioItemModal
        isOpen={isPortfolioItemModalOpen}
        onClose={onClosePortfolioItemModal}
        selectedPortfolioItem={selectedPortfolioItem}
      />

      <ProviderApplicationModal
        isOpen={profileIsOpen}
        onClose={closeProfile}
        providerId={selectedUser?.username}
        initialData={selectedApplication}
      />

      <AddProfile
        isOpen={isPortfolioUploadOpen}
        onClose={() => setIsPortfolioUploadOpen(false)}
        userId={currentUserId}
      />

    </Box>
  );
};



const ProviderApplicationModal = ({ isOpen, onClose, providerId, initialData }) => {
  const [bio, setBio] = useState(initialData?.bio || '');
  const [providerType, setProviderType] = useState(initialData?.providerType || '');
  const API_URL = process.env.REACT_APP_API_URL;
  const toast = useToast();

  const handleSubmit = async () => {
    const idToken = await getIdToken(); // Make sure you handle authentication appropriately
    const url = `${API_URL}/provider/${providerId ? 'update' : 'create'}`;
    const method = providerId ? 'patch' : 'post';
    const data = { bio, providerType };

    try {
      await axios({
        method,
        url,
        data,
        headers: { Authorization: `Bearer ${idToken}` }
      });
      toast({
        title: `Provider application ${providerId ? 'updated' : 'created'} successfully.`,
        status: 'success',
        duration: 3000,
        isClosable: true
      });
      onClose(); // Close the modal after successful operation
    } catch (error) {
      toast({
        title: 'Failed to process the provider application',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{providerId ? 'Update Provider Application' : 'Create Provider Application'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <FormControl isRequired>
            <FormLabel>Bio</FormLabel>
            <Input 
              placeholder='Short bio'
              value={bio}
              onChange={(e) => setBio(e.target.value)}
            />
          </FormControl>
          <FormControl mt={4} isRequired>
            <FormLabel>Provider Type</FormLabel>
            <Input 
              placeholder='Type of provider'
              value={providerType}
              onChange={(e) => setProviderType(e.target.value)}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme='blue' mr={3} onClick={handleSubmit}>
            {providerId ? 'Update' : 'Save'}
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};


export default UserManager;
