import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
} from "amazon-cognito-identity-js";

const cognitoConfig = {
  UserPoolId: process.env.REACT_APP_USER_POOL_ID,
  ClientId: process.env.REACT_APP_CLIENT_ID,
  Region: process.env.REACT_APP_REGION,
};

const userPool = new CognitoUserPool({
  UserPoolId: cognitoConfig.UserPoolId,
  ClientId: cognitoConfig.ClientId,
});


export const getIdToken = () => {
  return new Promise((resolve, reject) => {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser != null) {
      cognitoUser.getSession((err, session) => {
        if (err) {
          reject(err);
        } else {
          resolve(session.getIdToken().getJwtToken());
        }
      });
    } else {
      reject("No user found");
    }
  });
};

export const signUp = async (
  given_name,
  family_name,
  email,
  password,
  navigate,
) => {
  email = email.toLowerCase(); // Transform email to lowercase
  const attributeList = [
    new CognitoUserAttribute({ Name: "given_name", Value: given_name }),
    new CognitoUserAttribute({ Name: "family_name", Value: family_name }),
    new CognitoUserAttribute({ Name: "email", Value: email }),
  ];

  return new Promise((resolve, reject) => {
    userPool.signUp(email, password, attributeList, null, (err, result) => {
      if (err) {
        reject({ error: err, message: err.message });
        console.error("Error:", err);
      } else {
        resolve({ email, password });
        navigate("/email-verification" + "?email=" + email);
      }
    });
  });
};

export const resendEmailConfirmation = (email) => {
  const cognitoUser = new CognitoUser({
    Username: email.toLowerCase(),
    Pool: userPool,
  });

  return new Promise((resolve, reject) => {
    cognitoUser.resendConfirmationCode((err, result) => {
      if (err) {
        reject({ error: err, message: err.message });
      } else {
        resolve(result);
      }
    });
  });
};

const getDetailsFromAWS = async () => {
  const cognitoUser = userPool.getCurrentUser();

  if (!cognitoUser) {
    throw new Error("User not logged in.");
  }

  try {
    await getSession(cognitoUser);
    const attributes = await getUserAttributes(cognitoUser);
    let details = {
      email: attributes["email"],
      given_name: attributes["given_name"],
      family_name: attributes["family_name"],
      userID: attributes["sub"],
      phoneNumber: attributes["phone_number"],
      country: attributes["custom:country"],
      timezone: attributes["custom:timezone"],
    };

    localStorage.setItem("userDetails", JSON.stringify(details));
    localStorage.setItem("userDetailsLastUpdated", Date.now());
    return details;
  } catch (err) {
    console.error("Error:", err);
    return null;
  }
};

export const getDetails = async () => {
  return await getDetailsFromAWS();
};

export const signIn = (email, password, navigate) => {
  const authenticationDetails = new AuthenticationDetails({
    Username: email.toLowerCase(),
    Password: password,
  });

  const cognitoUser = new CognitoUser({
    Username: email.toLowerCase(),
    Pool: userPool,
  });

  return new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        resolve(cognitoUser);
        window.location.reload();
      },
      onFailure: (err) => {
        reject({ error: err, message: err.message });
      },
    });
  });
};

export const confirmEmail = (email, code, navigate) => {
  const cognitoUser = new CognitoUser({
    Username: email.toLowerCase(),
    Pool: userPool,
  });

  return new Promise((resolve, reject) => {
    cognitoUser.confirmRegistration(code, true, (err, result) => {
      if (err) {
        reject({ error: err, message: err.message });
      } else {
        resolve(result);
        navigate("/login");
      }
    });
  });
};

export const getSession = (cognitoUser) => {
  return new Promise((resolve, reject) => {
    if (cognitoUser) {
      cognitoUser.getSession((err, session) => {
        if (err) {
          reject(err);
        } else {
          // Check if the user is authenticated
          if (session.isValid()) {
            resolve(session);
          } else {
            reject(new Error("User is not authenticated"));
          }
        }
      });
    } else {
      reject(new Error("User not found"));
    }
  });
};

export const getUserAttributes = (cognitoUser) => {
  return new Promise((resolve, reject) => {
    if (cognitoUser) {
      cognitoUser.getUserAttributes((err, attributes) => {
        if (err) {
          reject({ error: err, message: err.message });
        } else {
          const attributesObject = attributes.reduce(
            (accumulator, attribute) => {
              accumulator[attribute.getName()] = attribute.getValue();
              return accumulator;
            },
            {},
          );
          resolve(attributesObject);
        }
      });
    } else {
      reject(new Error("User not logged in."));
    }
  });
};

// export const getUserAttributesIfAuthenticated = async (cognitoUser) => {
//   try {
//     await getSession();
//     const attributes = await getUserAttributes(cognitoUser);
//     return attributes;
//   } catch (err) {
//     console.error("Error:", err);
//     return null;
//   }
// };

// Function to delete the account
export const deleteAccount = (navigate) => {
  const cognitoUser = userPool.getCurrentUser();
  if (cognitoUser) {
    getSession(cognitoUser)
      .then(() => {
        cognitoUser.deleteUser((err, result) => {
          if (err) {
            console.error("Error:", err);
          } else {
            navigate("/login");
            window.location.reload();
          }
        });
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  } else {
    console.error("Error: User not logged in.");
  }
};

// Function to change the password
export const changePassword = (oldPassword, newPassword) => {
  const cognitoUser = userPool.getCurrentUser();

  return new Promise((resolve, reject) => {
    if (cognitoUser) {
      cognitoUser.getSession((err, session) => {
        if (err) {
          reject({ error: err, message: err.message });
        } else {
          cognitoUser.changePassword(
            oldPassword,
            newPassword,
            (err, result) => {
              if (err) {
                reject({ error: err, message: err.message });
              } else {
                resolve(result);
              }
            },
          );
        }
      });
    } else {
      reject(new Error("User not logged in."));
    }
  });
};

// Function to send the password reset code
export const forgotPassword = (email) => {
  const cognitoUser = new CognitoUser({
    Username: email.toLowerCase(),
    Pool: userPool,
  });

  return new Promise((resolve, reject) => {
    cognitoUser.forgotPassword({
      onSuccess: (result) => {
        resolve(result);
      },
      onFailure: (err) => {
        console.error("Error:", err);
        reject({ error: err, message: err.message });
      },
      inputVerificationCode: (data) => {
        console.log("Code sent to:", data);
        resolve({ inputVerificationCode: true });
      },
    });
  });
};

// Function to confirm the new password
export const confirmForgotPassword = (email, verificationCode, newPassword) => {
  const cognitoUser = new CognitoUser({
    Username: email.toLowerCase(),
    Pool: userPool,
  });

  return new Promise((resolve, reject) => {
    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onSuccess: () => {
        resolve(true);
      },
      onFailure: (err) => {
        console.error("Error:", err);
        reject({ error: err, message: err.message });
      },
    });
  });
};

// Function to sign out the user
export const signOut = (navigate) => {
  const cognitoUser = userPool.getCurrentUser();
  if (cognitoUser) {
    cognitoUser.signOut();
    navigate("/login");
  }
};

export { userPool };
