import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  HStack,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  BsThreeDotsVertical,
  BsTrash
} from "react-icons/bs";
import { FaArrowLeft } from "react-icons/fa";
import { HiReceiptRefund } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { getIdToken } from "../../../../cognito/cognitoAuth";

const API_URL = process.env.REACT_APP_API_URL;

const InvoiceManager = () => {
  const [invoices, setInvoices] = useState([]);
  const [providerInfoMap, setProviderInfoMap] = useState({});
  const [confirmationAction, setConfirmationAction] = useState(null);
  const toast = useToast();
  const confirmDisclosure = useDisclosure();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  // Fetching invoices
  const fetchInvoices = async () => {
    try {
      const authentication = await getIdToken();
      const response = await fetch(`${API_URL}/invoices`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authentication}`,
        },
      });
      const data = await response.json();
      if (data.statusCode === 200) {
        setInvoices(JSON.parse(data.body));
      } else {
        toast({
          title: `Error fetching invoices: ${data.statusCode}`,
          status: "error",
        });
      }
    } catch (error) {
      toast({
        title: "Error fetching invoices",
        description: error.message,
        status: "error",
      });
    }
  };

  const refundInvoice = async (invoice) => {
    try {
      const authentication = await getIdToken();
      const response = await axios.post(
        `${API_URL}/invoices/${invoice.invoice_id}/client-refund`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authentication}`,
          },
        },
      );

      if (response.status === 200) {
        fetchInvoices();
      } else {
        console.error("Error status code:", response.status);
      }
    } catch (error) {
      console.error("Error refunding invoice:", error);
    }
  };

  const cancelInvoice = async (invoice) => {
    try {
      const authentication = await getIdToken();
      const response = await axios.post(
        `${API_URL}/invoice/admin/${invoice.invoice_id}/cancel`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authentication}`,
          },
        },
      );

      if (response.status === 200) {
        fetchInvoices();
      } else {
        console.error("Error status code:", response.status);
      }
    } catch (error) {
      console.error("Error deleting invoice:", error);
    }
  };

  const refundInvoiceFee = async (invoice) => {
    try {
      const authentication = await getIdToken();
      const response = await axios.post(
        `${API_URL}/invoices/${invoice.invoice_id}/application-fee-refund`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authentication}`,
          },
        },
      );

      if (response.status === 200) {
        fetchInvoices();
      } else {
        console.error("Error status code:", response.status);
      }
    } catch (error) {
      console.error("Error refunding invoice fee:", error);
    }
  };

  const handleActionConfirmation = (action, invoice) => {
    setConfirmationAction({ action, invoice });
    confirmDisclosure.onOpen();
  };

  const handleConfirmedAction = async () => {
    setIsLoading(true); // Start loading
    const { action, invoice } = confirmationAction;
    try {
      switch (action) {
        case "refund":
          await refundInvoice(invoice);
          toast({ title: `Invoice refunded successfully`, status: "success" }); // Success toast
          break;
        case "cancel":
          await cancelInvoice(invoice);
          toast({ title: `Invoice canceled successfully`, status: "success" }); // Success toast
          break;
        case "refund_fee":
          await refundInvoiceFee(invoice);
          toast({
            title: `Application fee refunded successfully`,
            status: "success",
          }); // Success toast
          break;
        default:
          throw new Error("Unknown action");
      }
    } catch (error) {
      console.error(`Error ${action} invoice:`, error);
      toast({ title: `Error ${action} invoice`, status: "error" }); // Error toast
    }
    setIsLoading(false); // Stop loading
    confirmDisclosure.onClose();
  };

  const fetchProviderInfo = async (provider_ids) => {
    let providerMap = {};
    for (const id of provider_ids) {
      if (!providerMap[id]) {
        const profile = await getProviderNameAndProfilePicture(id);
        providerMap[id] = profile;
      }
    }
    setProviderInfoMap(providerMap);
  };

  const getProviderNameAndProfilePicture = async (id) => {
    const authentication = await getIdToken();
    const userResponse = await axios.get(`${API_URL}/users/info/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authentication}`,
      },
    });

    const profile = userResponse.data.body;

    return profile;
  };

  useEffect(() => {
    fetchInvoices(); // Fetch invoices when the component mounts
  }, []);

  useEffect(() => {
    fetchInvoices().then(() => {
      const uniqueProviderIds = [
        ...new Set(invoices.map((invoice) => invoice.provider_id)),
      ];
      fetchProviderInfo(uniqueProviderIds);
    });
  }, []);

  const onBack = () => {
    navigate("/admin-panel");
  };

  const getTotalInvoices = () => {
    return invoices.length;
  };

  const getTotalPaid = () => {
    return invoices.filter((invoice) => invoice.payment_status === "paid")
      .length;
  };

  const getTotalInRefunded = () => {
    return invoices.filter((invoice) => invoice.payment_status === "refunded")
      .length;
  };

  const formatCurrency = (amount, currency) => {
    // needs to work with GBP, USD and EUR and convert from cents to dollars

    if (currency === "GBP") {
      return `£${(amount / 100).toFixed(2)}`;
    } else if (currency === "USD") {
      return `$${(amount / 100).toFixed(2)}`;
    } else if (currency === "EUR") {
      return `€${(amount / 100).toFixed(2)}`;
    } else {
      return `${(amount / 100).toFixed(2)}`;
    }
  };

  return (
    <Box>
      <Flex justifyContent="flex-start" mb={10}>
        <IconButton aria-label="Back" icon={<FaArrowLeft />} onClick={onBack} />
        <Heading as="h1" size="lg" ml={5} mt={1}>
          Invoice Manager
        </Heading>
      </Flex>
      <Grid templateColumns="repeat(4, 1fr)" gap={6} mb={6}>
        <GridItem colSpan={{ base: 2, md: 2, lg: 1 }}>
          <Box
            p={5}
            shadow="md"
            borderWidth="1px"
            flex="1"
            borderRadius="md"
            bg={"oleniumBlack.600"}
          >
            <Text color={"white"} fontSize="sm" fontWeight="bold">
              Total invoices
            </Text>
            <Text color={"white"} fontSize="2xl" fontWeight="bold">
              {getTotalInvoices()}
            </Text>
          </Box>
        </GridItem>
        <GridItem colSpan={{ base: 2, md: 2, lg: 1 }}>
          <Box
            p={5}
            shadow="md"
            borderWidth="1px"
            flex="1"
            borderRadius="md"
            bg={"oleniumBlack.600"}
          >
            <Text color={"white"} fontSize="sm" fontWeight="bold">
              Invoices paid
            </Text>
            <Text color={"white"} fontSize="2xl" fontWeight="bold">
              {getTotalPaid()}
            </Text>
          </Box>
        </GridItem>
        <GridItem colSpan={{ base: 2, md: 2, lg: 1 }}>
          <Box
            p={5}
            shadow="md"
            borderWidth="1px"
            flex="1"
            borderRadius="md"
            bg={"oleniumBlack.600"}
          >
            <Text color={"white"} fontSize="sm" fontWeight="bold">
              Refunded invoices
            </Text>
            <Text color={"white"} fontSize="2xl" fontWeight="bold">
              {getTotalInRefunded()}
            </Text>
          </Box>
        </GridItem>
      </Grid>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Invoice ID</Th>
            <Th>Amount</Th>
            <Th>Provider</Th>
            <Th>Description</Th>
            <Th>Status</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {invoices.map((invoice) => {
            const provider = providerInfoMap[invoice.provider_id];
            return (
              <Tr key={invoice.invoice_id}>
                <Td>{invoice.invoice_id}</Td>
                <Td>{formatCurrency(invoice.amount, invoice.currency)}</Td>
                <Td>
                  {provider ? (
                    <HStack justify="flex-start">
                      <Avatar
                        size="xs"
                        name={`${provider.given_name} ${provider.family_name}`}
                        src={provider.profile_picture_url}
                      />
                      <Text color={"white"} fontSize="xs" fontWeight="bold">
                        {`${provider.given_name} ${provider.family_name}`}
                      </Text>
                    </HStack>
                  ) : (
                    "Loading..."
                  )}
                </Td>
                <Td>{invoice.description}</Td>
                <Td>
                  <Tag
                    size={"sm"}
                    variant="outline"
                    textTransform={"capitalize"}
                    rounded={"full"}
                  >
                    {invoice.payment_status}
                  </Tag>
                </Td>
                <Td>
                  <Menu>
                    <MenuButton>
                      <IconButton
                        aria-label="Options"
                        icon={<BsThreeDotsVertical />}
                        size="xs"
                        variant="outline"
                        rounded="full"
                        mx={2}
                      />
                    </MenuButton>
                    <MenuList bg={"oleniumBlack.600"}>
                      {/* <MenuItem
                        icon={<BsCheckCircleFill color={"green.600"} />}
                        onClick={() => handleActionConfirmation("approve", invoice)}
                        bg={"oleniumBlack.600"}
                        color={"green.600"}
                      >
                        Approve Payout
                      </MenuItem>

                      <MenuItem
                        icon={<BsXCircleFill color={"red.600"} />}
                        onClick={() => handleActionConfirmation("deny", invoice)}
                        bg={"oleniumBlack.600"}
                        color={"red.600"}
                      >
                        Reject Payout
                      </MenuItem> */}

                      <MenuItem
                        icon={<HiReceiptRefund />}
                        onClick={() =>
                          handleActionConfirmation("refund", invoice)
                        }
                        bg={"oleniumBlack.600"}
                      >
                        Refund Invoice
                      </MenuItem>

                      <MenuItem
                        icon={<HiReceiptRefund />} // Choose an appropriate icon
                        onClick={() =>
                          handleActionConfirmation("refund_fee", invoice)
                        }
                        bg={"oleniumBlack.600"}
                      >
                        Refund Application Fee
                      </MenuItem>

                      <Divider my={2} />

                      <MenuItem
                        icon={<BsTrash color={"red.600"} />}
                        onClick={() =>
                          handleActionConfirmation("cancel", invoice)
                        }
                        bg={"oleniumBlack.600"}
                        color={"red.600"}
                      >
                        Cancel Invoice
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>

      {/* Add a modal for confirmation */}
      <Modal
        isOpen={confirmDisclosure.isOpen}
        onClose={confirmDisclosure.onClose}
      >
        <ModalOverlay />
        <ModalContent bg={"oleniumBlack.600"}>
          <ModalHeader>Confirm Action</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to {confirmationAction?.action} this invoice?
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={handleConfirmedAction}
              variant="oleniumBlue"
              isLoading={isLoading}
            >
              Confirm
            </Button>
            <Button
              variant="ghost"
              onClick={confirmDisclosure.onClose}
              isDisabled={isLoading}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default InvoiceManager;
