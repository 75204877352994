import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  Button,
  Text,
  useBreakpointValue,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Avatar,
  HStack,
  SimpleGrid,
  Image,
  ModalCloseButton,
} from "@chakra-ui/react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import css for carousel
import { Carousel } from "react-responsive-carousel";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

import OleniumLogo from "../../OleniumLogoAnimated";

const MotionBox = motion(Box);
const MotionHeading = motion(Heading);
const MotionText = motion(Text);

const images = [
  "https://cdn.dribbble.com/users/1998175/screenshots/17555010/media/9cb86785ee7d004879337f475d033a87.jpg?resize=1600x1200&vertical=center",
  "https://cdn.dribbble.com/userupload/13066533/file/original-02681b5aaeb2d5968da253d242f6ff48.png?resize=2048x1536",
  "https://cdn.dribbble.com/userupload/14555801/file/original-8edb36bfa38fe640fb37da7dc8ed5e69.jpg?resize=2048x1536",
  "https://cdn.dribbble.com/userupload/14409430/file/original-6a2a56f5314789187f86e097553f623e.jpg?resize=1504x1128",
  "https://cdn.dribbble.com/userupload/14584805/file/original-05f78a12229eed4ae498db896165435a.png?resize=2048x1536",
  "https://cdn.dribbble.com/userupload/14551512/file/original-b932a203c1640c246d91d367d8035c96.png?resize=1504x1128",
  "https://cdn.dribbble.com/userupload/14636287/file/original-c50d2b6b7145ce734988a63f0241603f.png?resize=2048x1536",
  "https://cdn.dribbble.com/userupload/14511962/file/original-cdeed3b2101f0d7650a29e53c037b378.png?resize=2048x1536",
  "https://cdn.dribbble.com/userupload/14556919/file/original-28f4b6be5f4266de2090f269678ec8b8.png?resize=1504x1128",
  "https://cdn.dribbble.com/userupload/14636866/file/original-9f279b4acb1755a86092f6dccfe0972e.jpeg?resize=1504x1128",
  "https://cdn.dribbble.com/userupload/14305373/file/original-f14c0c1ee895462cb389bb38818a111a.jpg?resize=2048x1536",
  "https://cdn.dribbble.com/userupload/14314707/file/original-f7c14272f289f2aea989422712f3d9a3.png?resize=2048x1536",
  // Add more image URLs as needed
];

const getRandomImage = (selectedImages) => {
    let randomIndex, selectedImage;
    do {
      randomIndex = Math.floor(Math.random() * images.length);
      selectedImage = images[randomIndex];
    } while (selectedImages.includes(selectedImage));
    return selectedImage;
  };


const MotionImage = motion(Image);


const ImageGrid = () => {
  const [imageGrid, setImageGrid] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      const newImages = [];
      for (let i = 0; i < 9; i++) {
        newImages.push(getRandomImage(newImages));
      }
      setImageGrid(newImages);
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval);
  }, []);

  // Animation variants for the fade transition
  const fadeVariants = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 }
  };

  return (
    <SimpleGrid columns={{ base: 1, md: 3 }} spacing={0}>
      {imageGrid.map((image, index) => (
        <Flex key={index} p={5}>
          <Box height={{ base: "200px", md: "300px" }} width="100%">
            <MotionImage
              src={image}
              alt={`Image ${index}`}
              objectFit="cover"
              height="100%"
              width="100%"
              rounded={"xl"}
              variants={fadeVariants}
              initial="initial"
              animate="animate"
              exit="exit"
              layoutId={`image-${index}`} // This helps in maintaining layout consistency during animations
            />
          </Box>
        </Flex>
      ))}
    </SimpleGrid>
  );
};


const Hero = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const headingSize = useBreakpointValue({ base: "5xl", md: "8xl" });
  const textSize = useBreakpointValue({ base: "lg", md: "3xl" });

  const handleNavigate = () => {
    navigate("/");
  };

  const toggleModal = () => setModalOpen(!isModalOpen);

  const ourWork = [
    {
      image: "https://images.unsplash.com/photo-1461749280684-dccba630e2f6?q=80&w=2969&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      author: "Author 1",
      authorImage: "https://images.unsplash.com/photo-1461749280684-dccba630e2f6?q=80&w=2969&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "Title 1",
      description: "Description 1",
      tags: ["tag1", "tag2"],
    },
    {
      image: "https://images.unsplash.com/photo-1461749280684-dccba630e2f6?q=80&w=2969&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      author: "Author 2",
      authorImage: "https://images.unsplash.com/photo-1461749280684-dccba630e2f6?q=80&w=2969&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "Title 2",
      description: "Description 2",
      tags: ["tag3", "tag4"],
    },
  ];

  return (
    <Box height="100vh" width="100vw" position="relative" overflow="hidden">
      <ImageGrid />
      <Box
        position="absolute"
        top="0"
        left="0"
        w="100%"
        h="100%"
        bg="rgba(0, 0, 0, 0.8)" // Darker overlay
        zIndex={1}
      ></Box>
      <Flex
        direction="column"
        align="center"
        justify="center"
        position="absolute"
        top="0"
        left="0"
        w="100%"
        h="100%"
        zIndex={2} // Ensure text and buttons are on top
      >
        <OleniumLogo animate={{ scale: [0, 1] }} transition={{ duration: 0.5 }} />
        <MotionHeading
          fontSize={headingSize}
          textAlign="center"
          color="white"
          animate={{ y: [-20, 0], opacity: [0, 1] }}
          transition={{ delay: 0.2, duration: 0.5 }}
          mt={4}
          lineHeight={1}
        >
          Where the best creative
          <br /> experiences are crafted.
        </MotionHeading>
        <MotionText
          fontSize={textSize}
          color="white"
          mt="4"
          textAlign="center"
          animate={{ y: [-20, 0], opacity: [0, 1] }}
          transition={{ delay: 0.4, duration: 0.5 }}
        >
          Connect with top-tier creative professionals from around the world to turn
          <br /> your ideas into a reality.
        </MotionText>
          <MotionBox
            as={Button}
            variant="oleniumBlue"
            onClick={handleNavigate}
            animate={{ scale: [0.9, 1.1, 1] }}
            transition={{ delay: 0.6, duration: 0.3 }}
            mt={5}
          >
            Get started
          </MotionBox>
      </Flex>

      <Modal isOpen={isModalOpen} onClose={toggleModal} isCentered size="6xl">
        <ModalOverlay />
        <ModalContent bg="transparent" shadow={0}>
          <ModalBody>
            <ModalCloseButton zIndex={1} mt={4} mr={6} />
            <Carousel
              showArrows={false}
              showThumbs={true}
              showStatus={false}
              infiniteLoop={true}
              autoPlay={true}
              interval={5000}
              stopOnHover={true}
              swipeable={true}
              emulateTouch={true}
            >
              {ourWork.map((work, index) => (
                <Box
                  key={index}
                  bgImage={`url(${work.image})`}
                  bgSize="cover"
                  bgPos="center"
                  h="70vh"
                  w="100%"
                  rounded="xl"
                  overflow={"hidden"}
                >
                  <Flex
                    direction="column"
                    justifyContent="flex-end"
                    alignItems={{ base: "center", md: "flex-start" }}
                    h="100%"
                    p={6}
                    bg="rgba(0, 0, 0, 0.5)"
                    rounded="xl"
                  >
                    <HStack justify="flex-start">
                      <Avatar size="xs" name={work.author} src={work.authorImage} />
                      <Text fontSize="xs" fontWeight="bold" color="white">
                        {work.author}
                      </Text>
                    </HStack>

                    <Text color="white" fontSize="xl" fontWeight="bold" my={2}>
                      {work.title}
                    </Text>

                    <Text color="white" fontSize="lg">
                      {work.description}
                    </Text>
                  </Flex>
                </Box>
              ))}
            </Carousel>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};


export default Hero;