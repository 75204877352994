import {
  Alert,
  Avatar,
  Badge,
  Box,
  Button,
  Center,
  CloseButton,
  Flex,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Spinner,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import axios from "axios";
import { getIdToken } from "../../../../cognito/cognitoAuth";
import "./Payments.css";

import { Elements, LinkAuthenticationElement, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";

import { loadStripe } from "@stripe/stripe-js";

import { BsCheckCircle } from "react-icons/bs";

let stripePromise = null;

if (process.env.REACT_APP_STRIPE_PUBLIC_KEY) {
  stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
}

const API_URL = process.env.REACT_APP_API_URL;

const CheckoutForm = ({ project_id }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}/project/${project_id}/payment_success`,
      },
    });

    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: {
      type: 'accordion',
      defaultCollapsed: false,
      radios: true,
      spacedAccordionItems: true,
    }
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <Box justifyContent={"center"} alignContent={"center"} spacing={4}>
        <LinkAuthenticationElement
          id="link-authentication-element"
          onChange={(e) => setEmail(e.value)}
          options={{ style: { marginBottom: "10px" } }}
        />
        <PaymentElement id="payment-element" options={paymentElementOptions} />
        <HStack spacing={4} mt={4}>
          <Spacer />
          <Button type="submit" disabled={!stripe || isLoading} px={16} variant="oleniumBlue" isLoading={isLoading}>
            Pay
          </Button>
        </HStack>

        {/* Show any error or success messages */}
        {message && <div id="payment-message">{message}</div>}
      </Box>
    </form>
  );
}










const PaymentForm = ({ invoice, project_id }) => {
  const [clientSecret, setClientSecret] = useState("");

  const [loadingCheckout, setLoadingCheckout] = useState(false);

  useEffect(() => {

    const fetchClientSecret = async () => {
      const IdToken = await getIdToken(); // Get the id token
      try {
        setLoadingCheckout(true);
        // Adjusting the API call according to the backend change
        const response = await axios.post(
          `${API_URL}/invoices/create-client-secret`,
          {
            project_id,
            invoice_id: invoice.invoice_id,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${IdToken}`,
            },
          }
        );

        // Ensure you handle response accordingly if your backend does not use the statusCode within the response.data
        if (response.data.statusCode === 200) {
          // Ensure you handle response accordingly if your backend does not use the body within the response.data
          setClientSecret(response.data.body.clientSecret);
          setLoadingCheckout(false);
        } else {
          throw new Error(response.data.body);
        }
      } catch (err) {
        console.error(err);
        setLoadingCheckout(false);
      }
    };

    fetchClientSecret();
  }, [invoice.id]);

  const appearance = {
    theme: 'stripe',
    variables: {
      colorText: '#ffffff',
      colorPrimary: '#0570de',
      colorTextSecondary: '#ffffff',
      colorBackground: '#1F1F1F',
      colorDanger: '#df1b41',
      fontFamily: 'helvetica, arial, sans-serif',
      spacingUnit: '4px',
      borderRadius: '15px',
      colorIcon: '#ffffff',
      spacingAccordionItem: '10px',
      colorLogoTab: '#ffffff',
      AccordionItemMargin: '10px',
      colorTabIcon: '#ffffff',
      // See all possible variables below
    },
    rules: {
      '.TabIcon': {
        color: '#ffffff',
      },
      '.Tab': {
        color: '#ffffff',
      },
      '.Tab.is-selected': {
        color: '#ffffff',
      },
      'AccordionItem.is-open .AccordionItem__body': {
        backgroundColor: '#1F1F1F',
        marginTop: '10px',
      },
    },
  };

  const options = {
    clientSecret,
    appearance,
  };

  const renderPrice = (currency, amount) => {
    amount = amount / 100; // Convert cents to dollars
    amount = amount.toFixed(2); // Round to 2 decimal places
    switch (currency) {
      case "USD":
        return `$${amount}`;
      case "EUR":
        return `€${amount}`;
      case "GBP":
        return `£${amount}`;
      default:
        return `${amount}`;
    }
  }


  return (
    <div className="App">
      {clientSecret ? (
        <>
          <Text fontSize={"lg"} mb={4}>
            You are paying <strong>{renderPrice(invoice.currency, invoice.amount)}</strong> to <strong>{invoice.provider}</strong> for <strong>{invoice.description}</strong>.
          </Text>
          <Elements options={options} stripe={stripePromise} >
            <Box justifyContent={"center"} alignContent={"center"} >
              <CheckoutForm project_id={project_id} />
            </Box>
          </Elements>
        </>
      ) : (
        <Center>
          <Spinner size="xl" my={8} />
        </Center>
      )}

    </div>
  );
};

const Payments = ({ project_id, user_id, is_provider }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [error, setError] = useState(null);
  const [showAlert, setShowAlert] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  const [currentInvoice, setCurrentInvoice] = useState(null);

  const stripe = useStripe();
  const elements = useElements();

  const handleOpenModal = (invoice) => {
    setCurrentInvoice(invoice);
    setIsOpen(true);
  };

  // Function to handle closing the modal
  const handleCloseModal = () => {
    setIsOpen(false);
  };

  // Check local storage to see if the alert has been dismissed before
  useEffect(() => {
    const alertDismissed = localStorage.getItem("currencyAlertDismissed");
    if (alertDismissed === "true") {
      setShowAlert(false);
    }
  }, []);


  // Function to handle dismissing the alert
  const handleDismissAlert = () => {
    setShowAlert(false);
    localStorage.setItem("currencyAlertDismissed", "true");
  };


  const renderPrice = (currency, amount) => {
    amount = amount / 100; // Convert cents to dollars
    amount = amount.toFixed(2); // Round to 2 decimal places
    switch (currency) {
      case "USD":
        return `$${amount}`;
      case "EUR":
        return `€${amount}`;
      case "GBP":
        return `£${amount}`;
      default:
        return `${amount}`;
    }
  };

  const convertPaymentStatus = (status) => {
    switch (status) {
      case "draft":
        return "Draft";
      case "requires_payment_method":
        return "Unpaid";
      case "paid":
        return "Paid";
      case "void":
        return "Void";
      case "uncollectible":
        return "Uncollectible";
      case "deleted":
        return "Deleted";
      default:
        return "Unknown";
    }
  };

  const getPaymentStatusTooltip = (status) => {
    switch (status) {
      case "draft":
        return "The invoice isn’t ready to use. All invoices start in draft status.";
      case "requires_payment_method":
        return "The invoice is ready to be paid.";
      case "paid":
        return "This invoice is paid.";
      case "void":
        return "This invoice is cancelled.";
      case "uncollectible":
        return "The customer is overdue on the invoice.";
      default:
        return "Unknown status.";
    }
  };

  useEffect(() => {
    const fetchInvoices = async () => {
      if (user_id === undefined) {
        // Do not send the API request if user_id is undefined
        return;
      }

      setIsLoading(true);
      try {
        const IdToken = await getIdToken(); // Get the id token
        const response = await axios.get(
          `${API_URL}/invoices/project/${project_id}`,
          {
            headers: {
              Authorization: `Bearer ${IdToken}`,
              "Content-Type": "application/json",
            },
          },
        );

        if (response.data.statusCode === 200) {
          const parsedInvoices = JSON.parse(response.data.body);
          if (Array.isArray(parsedInvoices)) {
            // Fetch user details for each asset
            for (let i = 0; i < parsedInvoices.length; i++) {
              const invoice = parsedInvoices[i];
              const userResponse = await fetch(
                `${API_URL}/users/info/${invoice.provider_id}`,
                {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${IdToken}`,
                  },
                },
              );
              const userData = await userResponse.json();
              if (userData.statusCode === 200) {
                // Add user details to asset
                invoice.provider =
                  userData.body.given_name + " " + userData.body.family_name;
                invoice.provider_avatar = userData.body.profile_picture_url;
              }
            }
            setInvoices(parsedInvoices);
            setIsLoading(false);
          } else {
            throw new Error("Error fetching invoices");
          }
        } else {
          throw new Error(response.data.body);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchInvoices();
  }, [user_id, project_id]); // Added dependencies to re-fetch invoices when user_id or project_id changes


  const paymentStatusColor = (status) => {
    switch (status) {
      case "Paid":
        return "green";
      case "Unpaid":
        return "orange";
      case "Void":
        return "red";
      case "Uncollectible":
        return "red";
      default:
        return "gray";
    }
  };


  const convertDueDate = (date) => {
    const dueDate = new Date(date);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return dueDate.toLocaleDateString(undefined, options);
  }

  return (
    <>
      <Box>
        {showAlert && (
          <Alert status="info" mb={4} rounded="md">
            <Text flex="1">
              We currently only charge in GBP, USD, and EUR. We are
              working on expanding to other currencies.
            </Text>
            <CloseButton onClick={handleDismissAlert} />
          </Alert>
        )}

        {isLoading ? (
          <Center>
            <Spinner size="xl" my={8} />
          </Center>
        ) : error ? (
          <Alert status="error">{error}</Alert>
        ) : (
          invoices && (
            <TableContainer>
              <Table variant="simple" style={{ overflow: 'visible' }} >
                <Thead>
                  <Tr>
                    <Th>Provider</Th>
                    <Th>Amount</Th>
                    <Th>Description</Th>
                    <Th>Due Date</Th>
                    <Th>Status</Th>

                    {is_provider && <Th>Transferable</Th>}
                    {!is_provider && <Th>Actions</Th>}
                  </Tr>
                </Thead>
                <Tbody>
                  {Array.isArray(invoices) &&
                    invoices.map((invoice, index) => (
                      <Tr key={index}>
                        <Td>
                          <Flex alignItems="center" gap="2">
                            <Avatar
                              size="xs"
                              name={invoice.provider}
                              src={invoice.provider_avatar}
                            />
                            <Text
                              ml={1}
                              fontSize="sm"
                              fontWeight="bold"
                              align={"left"}
                            >
                              {invoice.provider}
                            </Text>
                          </Flex>
                        </Td>

                        <Td>{renderPrice(invoice.currency, invoice.amount)}</Td>

                        <Td>
                          <Text isTruncated maxWidth="250px">
                            {invoice.description}
                          </Text>
                        </Td>

                        <Td>
                          <Badge
                            size="sm"
                            variant="outline"
                            rounded={"full"}
                            colorScheme={"gray"}
                            textTransform={"Capitalize"}
                            px={2}
                          >
                            {convertDueDate(invoice.due_date)}
                          </Badge>
                        </Td>

                        <Td>
                          <Tooltip
                            label={getPaymentStatusTooltip(invoice.payment_status)}
                            placement="top"
                            hasArrow
                          >
                            <Tag
                              size="sm"
                              variant="outline"
                              rounded={"full"}
                              colorScheme={paymentStatusColor(
                                convertPaymentStatus(invoice.payment_status),
                              )}
                            >
                              {convertPaymentStatus(invoice.payment_status)}
                            </Tag>
                          </Tooltip>
                        </Td>

                        {is_provider && (
                          <Td>
                            <Text>
                              {renderPrice(invoice.currency, invoice.transfer_amount)}
                            </Text>
                          </Td>
                        )}

                        {!is_provider && (
                          <Td textAlign={"center"} >
                            {invoice.payment_status === "requires_payment_method" ? (
                              <Button
                                size="xs"
                                onClick={() => handleOpenModal(invoice)}
                              >
                                Pay Invoice
                              </Button>
                            ) : (
                              <Icon as={BsCheckCircle} w={4} h={4} color={"green.500"} mx={"auto"} />
                            )}
                          </Td>
                        )}
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            </TableContainer>
          )
        )}
        {invoices.length === 0 && (
          <Center mt={8}>
            <Text >You have no invoices for this project yet.</Text>
          </Center>
        )}
      </Box>

      <Modal isOpen={isOpen} onClose={handleCloseModal} size={"2xl"} isCentered>
        <ModalOverlay />
        <ModalContent bg="oleniumBlack.600" color="white" padding={5} rounded={"2xl"}>
          <ModalHeader>Pay Invoice</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Pass currentInvoice to PaymentForm if needed */}
            <PaymentForm invoice={currentInvoice} project_id={project_id} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Payments;
