import {
  Box,
  Divider,
  Flex,
  HStack,
  Icon,
  Stack,
  Text,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { AiFillDollarCircle } from "react-icons/ai";
import {
  BsFillClockFill,
  BsFillPatchCheckFill
} from "react-icons/bs";


const ourValues = [
  {
    icon: BsFillPatchCheckFill,
    title: "We'll match you with your specialist",
    description:
      "We reach out to our network of designers and developers to find the perfect match for your project.",
  },
  {
    icon: BsFillClockFill,
    title: "Pricing and timeline",
    description:
      "You will receive a quote and timeline for your project. If you are not satisfied, we will find you a new specialist.",
  },
  {
    icon: AiFillDollarCircle,
    title: "Pay the project deposit",
    description:
      "A £9.99 deposit is required to start your project. This is fully refundable if you are not satisfied with the specialist we find for you.",
  },
];

const WhatNext = () => {
  const boxColor = useColorModeValue("white", "oleniumBlack.600");

  return (
    <Flex direction="column" align="center" justify="center" w="100%">
      <Box
        bg={boxColor}
        p={10}
        rounded="2xl"
        maxW={"800px"}
        w={"100%"}
        height={"100%"}
        display="flex"
        alignItems="center"
      >
        <Box w={"100%"} h={"100%"} display="flex" flexDirection="column">

          <Stack
            spacing={10}
            align="center"
            mx="auto"
            py={8}
            maxW={"450px"}
            justify={"center"}
          >
            {ourValues.map((value, index) => (
              <React.Fragment key={index}>
                {renderValue(value.icon, value.title, value.description)}
                {index < ourValues.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </Stack>
        </Box>
      </Box>
    </Flex>
  );
};

const renderValue = (icon, title, description) => (
  <HStack
    spacing={4}
    align="center"
    width="100%"
    flexDirection={{ base: "column", md: "row" }}
  >
    <Box
      w={12}
      h={12}
      minW={12}
      rounded="10px"
      bg="oleniumBlack.100"
      display="flex"
      alignItems="center"
      justifyContent="center"
      marginBottom={{ base: 4, md: 0 }}
      marginRight={{ base: 0, md: 4 }}
      aspectRatio={1}
    >
      <Icon as={icon} boxSize={{ base: 4, md: 6 }} />
    </Box>
    <VStack
      align={{ base: "center", md: "start" }}
      spacing={1}
      h={12}
      justifyContent={"center"}
      textAlign={{ base: "center", md: "left" }}
    >
      <Text
        fontSize={{ base: "md", md: "lg" }}
        fontWeight="bold"
        textAlign={{ base: "center", md: "left" }}
      >
        {title}
      </Text>
      <Text
        fontSize={{ base: "sm", md: "md" }}
        color={"oleniumGray.700"}
        textAlign={{ base: "center", md: "left" }}
      >
        {description}
      </Text>
    </VStack>
  </HStack>
);

export default WhatNext;
