import {
  AddIcon,
  AttachmentIcon,
  ChevronDownIcon,
  DeleteIcon,
  EditIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Table,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { BsCheckCircleFill, BsXCircleFill } from "react-icons/bs";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import { getIdToken } from "../../../../cognito/cognitoAuth";

const ServiceManager = () => {
  const navigate = useNavigate();

  const onBack = () => {
    navigate("/admin-panel");
  };

  const [services, setServices] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentService, setCurrentService] = useState(null);
  const [action, setAction] = useState("");
  const API_URL = process.env.REACT_APP_API_URL;

  // toast
  const toast = useToast(); // Use the useToast hook from Chakra UI

  useEffect(() => {
    fetchServices();
  }, []);

  const handleResponse = (response) => {
    if (!response.ok) {
      const error = new Error(response.statusText);
      error.response = response;
      throw error;
    }
    return response;
  };

  const fetchServices = async () => {
    try {
      const authentication = await getIdToken();
      const response = await fetch(`${API_URL}/services`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authentication}`,
        },
      });

      handleResponse(response); // Check the response status

      const data = await response.json(); // Parse the response body as JSON

      // Parse the body attribute in the data object
      const servicesData = JSON.parse(data.body);

      console.log("servicesData", servicesData);

      // Process the data and update the state accordingly
      if (Array.isArray(servicesData)) {
        setServices(servicesData);
      } else {
        setServices([]);
      }
    } catch (error) {
      console.error("Error fetching services:", error);
      toast({
        title: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setServices([]);
    }
  };

  const openCreateModal = () => {
    setAction("create");
    setCurrentService(null);
    onOpen();
  };

  const openDeleteModal = (service) => {
    setAction("delete");
    setCurrentService(service);
    onOpen();
  };

  const openUpdateModal = (service) => {
    setAction("update");
    setCurrentService(service);
    onOpen();
  };

  const onModalClose = () => {
    fetchServices();
    onClose();
  };

  const totalNumberOfServices = services.length;
  const totalNumberOfActiveServices = services.filter(
    (service) => service.is_active,
  ).length;
  const totalNumberOfInactiveServices = services.filter(
    (service) => !service.is_active,
  ).length;

  return (
    <>
      <Box>
        <Flex justifyContent="flex-start" mb={10}>
          <IconButton
            aria-label="Back"
            icon={<FaArrowLeft />}
            onClick={onBack}
          />
          <Heading as="h1" size="lg" ml={5} mt={1}>
            Service Manager
          </Heading>
          <Spacer />
          <Button
            leftIcon={<AddIcon />}
            variant="oleniumBlue"
            size={"sm"}
            onClick={openCreateModal}
            mt={1}
          >
            Create Service
          </Button>
        </Flex>

        <Grid templateColumns="repeat(4, 1fr)" gap={6} mb={6}>
          <GridItem colSpan={1}>
            <Box
              p={5}
              shadow="md"
              borderWidth="1px"
              flex="1"
              borderRadius="md"
              bg={"oleniumBlack.600"}
            >
              <Text color={"white"} fontSize="sm" fontWeight="bold">
                Total services
              </Text>
              <Text color={"white"} fontSize="2xl" fontWeight="bold">
                {totalNumberOfServices}
              </Text>
            </Box>
          </GridItem>
          <GridItem colSpan={1}>
            <Box
              p={5}
              shadow="md"
              borderWidth="1px"
              flex="1"
              borderRadius="md"
              bg={"oleniumBlack.600"}
            >
              <Text color={"white"} fontSize="sm" fontWeight="bold">
                Active services
              </Text>
              <Text color={"white"} fontSize="2xl" fontWeight="bold">
                {totalNumberOfActiveServices}
              </Text>
            </Box>
          </GridItem>
          <GridItem colSpan={1}>
            <Box
              p={5}
              shadow="md"
              borderWidth="1px"
              flex="1"
              borderRadius="md"
              bg={"oleniumBlack.600"}
            >
              <Text color={"white"} fontSize="sm" fontWeight="bold">
                Inactive services
              </Text>
              <Text color={"white"} fontSize="2xl" fontWeight="bold">
                {totalNumberOfInactiveServices}
              </Text>
            </Box>
          </GridItem>
          {/* <GridItem colSpan={1}>
            <Box
              p={5}
              shadow="md"
              borderWidth="1px"
              flex="1"
              borderRadius="md"
              bg={"oleniumBlack.600"}
            >
              <Text color={"white"} fontSize="sm" fontWeight="bold">
                Most popular service
              </Text>
              <Text color={"white"} fontSize="2xl" fontWeight="bold">
                4
              </Text>
            </Box>
          </GridItem> */}
        </Grid>
      </Box>

      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Description</Th>
            <Th>Icon</Th>
            <Th>Is Active</Th>
            <Th>Is Internal</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {services.map((service, index) => (
            <Tr key={index}>
              <Td>{service.name}</Td>
              <Td>{service.description}</Td>
              <Td>
                <Image
                  src={service.icon}
                  alt={service.name}
                  w={16}
                  objectFit="cover"
                  aspectRatio={1}
                />
              </Td>
              <Td>
                {service.is_active ? (
                  <BsCheckCircleFill color="green" />
                ) : (
                  <BsXCircleFill color="red" />
                )}
              </Td>

              <Td>
                {service.is_internal ? (
                  <BsCheckCircleFill color="green" />
                ) : (
                  <BsXCircleFill color="red" />
                )}
              </Td>

              <Td>
                <Menu>
                  <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                    Actions
                  </MenuButton>
                  <MenuList bg="oleniumBlack.600">
                    <MenuItem
                      icon={<EditIcon />}
                      onClick={() => openUpdateModal(service)}
                      bg={"oleniumBlack.600"}
                    >
                      Update
                    </MenuItem>
                    <MenuItem
                      icon={<DeleteIcon />}
                      onClick={() => openDeleteModal(service)}
                      bg={"oleniumBlack.600"}
                    >
                      Delete
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <ServiceModal
        isOpen={isOpen}
        onClose={onModalClose}
        action={action}
        service={currentService}
      />
    </>
  );
};

const ServiceModal = ({ isOpen, onClose, action, service }) => {
  const [serviceId, setServiceId] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [icon, setIcon] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [isInternal, setIsInternal] = useState(false);
  const [iconChanged, setIconChanged] = useState(false);
  const toast = useToast();

  const API_URL = process.env.REACT_APP_API_URL;

  const handleIconChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = function () {
      setIcon(reader.result);
      setIconChanged(true); // Set iconChanged to true when the icon is updated by the user
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (service) {
      setServiceId(service.id);
      setName(service.name);
      setDescription(service.description);
      setIcon(service.icon);
      setIsActive(service.is_active);
      setIsInternal(service.is_internal);
    } else {
      setName("");
      setDescription("");
      setIcon(null);
      setIsActive(false);
      setIsInternal(false);
    }
    setIconChanged(false);
  }, [service]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const url =
      action === "update" // Make sure "update" is also included here
        ? `${API_URL}/services/${service.id}`
        : `${API_URL}/services`;

    const method = action === "update" ? "PUT" : "POST"; // Make sure "update" is also included here

    function cleanURL(url) {
      // Create a URL object from the input URL
      const urlObj = new URL(url);

      // Access the search parameters of the URL
      const params = new URLSearchParams(urlObj.search);

      // Delete the parameters related to the signature and tokens
      params.delete("AWSAccessKeyId");
      params.delete("Signature");
      params.delete("x-amz-security-token");
      params.delete("Expires");

      // Update the search parameters of the URL object
      urlObj.search = params;

      // Return the cleaned URL as a string
      return urlObj.toString();
    }

    const data = {
      name,
      description,
      is_active: isActive,
      is_internal: isInternal,
    };

    if (iconChanged) {
      // Include icon only if it has been changed
      const iconUrl = cleanURL(icon);
      data.icon = iconUrl;
    }

    try {
      const authentication = await getIdToken();
      const response = await fetch(url, {
        method,
        headers: {
          Authorization: `Bearer ${authentication}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        toast({
          title: `${action === "update" ? "Updated" : "Created"} service`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        onClose();
      } else {
        const responseBody = await response.json(); // Get response body
        console.error("Server responded with error:", responseBody); // Log server error message
        throw new Error("Failed to save the service");
      }
    } catch (error) {
      console.error("Error saving service:", error);
      toast({
        title: "Error saving service",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDelete = async () => {
    try {
      const authentication = await getIdToken();
      const response = await fetch(`${API_URL}/services/${service.id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authentication}`,
        },
      });

      if (response.ok) {
        toast({
          title: "Service deleted",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        onClose();
      } else {
        throw new Error("Failed to delete the service");
      }
    } catch (error) {
      console.error("Error deleting service:", error);
      toast({
        title: "Error deleting service",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const renderModalBody = () => {
    if (action === "delete") {
      return (
        <ModalBody>
          Are you sure you want to delete the service "{name}"?
        </ModalBody>
      );
    }
    return (
      <ModalBody>
        <FormControl id="name" mb={4}>
          <FormLabel>Name</FormLabel>
          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </FormControl>
        <FormControl id="description" mb={4}>
          <FormLabel>Description</FormLabel>
          <Textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </FormControl>
        <FormControl id="icon" mb={4}>
          <FormLabel>Icon</FormLabel>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              children={<AttachmentIcon color="gray.300" />}
            />
            <Input type="file" accept="image/*" onChange={handleIconChange} />
          </InputGroup>
        </FormControl>
        <FormControl id="is_active" mb={4}>
          <Checkbox
            isChecked={isActive}
            onChange={(e) => setIsActive(e.target.checked)}
          >
            Is Active
          </Checkbox>
        </FormControl>
        <FormControl id="is_internal" mb={4}>
          <Checkbox
            isChecked={isInternal}
            onChange={(e) => setIsInternal(e.target.checked)}
          >
            Is Internal
          </Checkbox>
        </FormControl>
      </ModalBody>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg="oleniumBlack.600">
        <ModalHeader>
          {action === "create"
            ? "Create Service"
            : action === "update"
              ? "Update Service"
              : "Delete Service"}
        </ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit}>
          {renderModalBody()}
          <ModalFooter>
            {action !== "delete" ? (
              <Button colorScheme="blue" mr={3} type="submit">
                {action === "create" ? "Create" : "Save"}
              </Button>
            ) : null}
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </form>
        {action === "delete" ? (
          <ModalFooter>
            <Button colorScheme="red" onClick={handleDelete}>
              Delete
            </Button>
          </ModalFooter>
        ) : null}
      </ModalContent>
    </Modal>
  );
};

export default ServiceManager;
