import React, { useEffect, useState } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Box,
    Text,
    Flex,
    Heading,
    Divider,
    Grid,
    IconButton,
    HStack,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Alert,
    useToast,
    Image,
    useDisclosure,
    GridItem,
} from "@chakra-ui/react";

import axios from "axios";
import { getIdToken } from "../../../cognito/cognitoAuth";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useNavigate } from "react-router-dom";

import { BsThreeDotsVertical, BsPencil, BsPlus } from "react-icons/bs";

import { FaPlus } from "react-icons/fa";

import { BsArrowRepeat } from "react-icons/bs";

import PortfolioForm from './PortfolioForm';
import { set } from "date-fns";


const InstagramImportModal = ({ isOpen, onClose }) => {

    const apiUrl = process.env.REACT_APP_API_URL;
    const toast = useToast();
    const [loading, setLoading] = useState(false);
    const [posts, setPosts] = useState([]);
    const [services, setServices] = useState([]);
    const [hasToken, setHasToken] = useState(false);
    const [error, setError] = useState("");
    const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
    const navigator = useNavigate();

    const [portfolioFormValues, setPortfolioFormValues] = useState({});
    const { isOpen: isPortfolioFormOpen, onOpen: onPortfolioFormOpen, onClose: onPortfolioFormClose } = useDisclosure();

    const openEditModal = (post) => {
        setPortfolioFormValues({
            description: post.caption,
            images: post.image_urls,
        });
        onPortfolioFormOpen();
        onClose();
    };

    // This function is used to check if the user has an Instagram token
    const checkInstagramToken = async () => {
        setLoading(true);
        try {
            const token = await getIdToken();
            const response = await axios.get(
                `${apiUrl}/instagram/connection-check`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );
            setHasToken(response.data.hasToken);
            if (response.data.hasToken && response.data.isValidToken === false) {
                toast({
                    title: "Error",
                    description: "Instagram token is invalid. Please reconnect your Instagram account.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
            setLoading(false);
        } catch (error) {
            console.error("Error checking Instagram token", error);
        }
        setLoading(false);
    };

    // This useEffect hook is used to check if url contains 'code' and if so, exchange it for an access token.
    // If this is successful, the user's Instagram account is connected and the posts are fetched and retreived.
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');

        if (code) {
            // If 'code' is found in the URL, make an API request
            const exchangeCodeForToken = async () => {
                setLoading(true);
                try {
                    const token = await getIdToken();

                    // pass the code to the API in the path to exchange it for an access token
                    await axios.post(
                        `${apiUrl}/connect/instagram/handle-url-code`
                        ,
                        {
                            code: code,
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                                "Content-Type": "application/json",
                            },
                        }
                    );
                    toast({
                        title: "Success",
                        description: "Instagram account successfully connected.",
                        status: "success",
                        duration: 5000,
                        isClosable: true,
                    });

                    // remove the 'code' from the URL
                    window.history.replaceState({}, document.title, "/portfolio");

                    navigator("/portfolio");
                    fetchInstagramPosts();
                    getInstagramPosts();
                } catch (error) {
                    console.error("Error connecting Instagram account", error);
                }
                setLoading(false);
            };
            exchangeCodeForToken();
        }
    }, [apiUrl, toast]);


    // This function is used to connect the user's Instagram account
    const setupInstagram = async () => {
        setLoading(true);
        try {
            const token = await getIdToken();
            const response = await axios.post(
                `${apiUrl}/connect/instagram`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );
            const accountLinkUrl = response.data.login_url;
            window.location.href = accountLinkUrl;
        } catch (error) {
            toast({
                title: "Error",
                description: "Something went wrong. Please try again later.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            setLoading(false);
        }
    };

    const getInstagramPosts = async () => {
        setLoading(true);
        try {
            const token = await getIdToken();
            const params = { limit: 100 };
            const response = await axios.get(`${apiUrl}/instagram/posts/all`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            });
            console.log("response", response);
            const data = response.data;

            const newPosts = data.posts.filter(
                (newPost) => !posts.some((existingPost) => existingPost.post_id === newPost.post_id)
            );

            setPosts(prevPosts => [...prevPosts, ...newPosts]);
        } catch (error) {
            console.error("Error fetching Instagram posts from DynamoDB", error);
        }
        setLoading(false);
    };

    const fetchInstagramPosts = async () => {
        setLoading(true);
        try {
            const token = await getIdToken();
            await axios.post(
                `${apiUrl}/instagram/posts`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );
            toast({
                title: "Success",
                description: "Instagram posts fetched and stored successfully.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            getInstagramPosts();
        } catch (error) {
            console.error("Error fetching and storing Instagram posts", error);
        }
        setLoading(false);
    };

    const deleteAllInstagramPosts = async () => {
        setLoading(true);
        try {
            const token = await getIdToken();
            await axios.delete(`${apiUrl}/instagram/posts`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            });
            toast({
                title: "Success",
                description: "Instagram posts deleted successfully.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            setPosts([]);
        } catch (error) {
            console.error("Error deleting Instagram posts", error);
            toast({
                title: "Error",
                description: "Failed to delete Instagram posts.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
        setLoading(false);
    };

    const renderPost = (post) => {
        if (post.image_urls && post.image_urls.length > 1) {
            // Use Carousel for multiple images
            return (
                <Carousel showThumbs={false} showStatus={true} showIndicators={false} showArrows={true}>
                    {post.image_urls.map((imgUrl, index) => (
                        <Image
                            key={index}
                            src={imgUrl}
                            alt="Instagram post"
                            rounded={"md"}
                            objectFit={"cover"}
                            height={"182px"}
                        />
                    ))}
                </Carousel>
            );
        } else if (post.image_urls && post.image_urls.length === 1) {
            // Single image, just show it
            return (
                <Image src={post.image_urls[0]} alt="Instagram post" rounded={"md"} objectFit={"cover"} height={"182px"} />
            );

        } else {
            // No images, return null or a placeholder image/component
            return null;
        }
    };

    const disconnectInstagram = async () => {
        // set loading to true
        setLoading(true);
        try {
            const token = await getIdToken();
            await axios.post(`${apiUrl}/disconnect/instagram`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            });

            toast({
                title: "Success",
                description: "Instagram account successfully disconnected.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            // set loading to false
            setLoading(false);
            setHasToken(false);
        } catch (error) {
            console.error("Error disconnecting Instagram account", error);
            toast({
                title: "Error",
                description: "Failed to disconnect Instagram account.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            // set loading to false
            setLoading(false);
        }
    }

    useEffect(() => {
        // Only proceed if the modal is open
        if (isOpen) {
            checkInstagramToken().then(() => {
                // After checking the token, you can also decide to fetch Instagram posts
                // directly here if needed, based on the new state of hasToken
                if (hasToken) {
                    getInstagramPosts();
                }
            });
        }
    }, [isOpen]);

    useEffect(() => {
        checkInstagramToken();
    }, [apiUrl, toast]);

    useEffect(() => {
        checkInstagramToken();

        if (hasToken) {
            getInstagramPosts();
        }
    }, [hasToken]);


    useEffect(() => {
        const fetchServices = async () => {
            setLoading(true);
            try {
                const IdToken = await getIdToken();
                const response = await axios.get(apiUrl + "/services", {
                    headers: {
                        Authorization: `Bearer ${IdToken}`,
                        "Content-Type": "application/json",
                    },
                });

                if (response.status === 200 && response.data.statusCode === 200) {
                    const servicesData = JSON.parse(response.data.body);
                    if (Array.isArray(servicesData)) {
                        setServices(servicesData);
                        setLoading(false);
                    } else {
                        throw new Error("Failed to fetch services.");
                    }
                } else {
                    throw new Error("Failed to fetch services.");
                }
            } catch (error) {
                setLoading(false);
                console.error("Error fetching services", error);
            }
            setLoading(false);
        };
        fetchServices();
    }, []);

    const handlePortfolioFormSubmit = async (values) => {
        onPortfolioFormClose();
        window.location.reload();
    }

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} size="2xl">
                <ModalOverlay />
                <ModalContent bg="oleniumBlack.700" color="white" p={2} borderRadius="xl">
                    <ModalHeader>Import from Instagram</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>

                        <HStack mb={5}>

                            <Text >
                                Link your Instagram account to import your posts. You can then edit the descriptions or get SideKick to write them for you.
                            </Text>

                            <IconButton
                                aria-label="Refresh"
                                icon={<BsArrowRepeat />}
                                variant="outline"
                                size="sm"
                                color="white"
                                bg="oleniumBlack.600"
                                onClick={fetchInstagramPosts}
                                isLoading={loading}
                                isDisabled={loading}
                            />

                            {hasToken && (
                                <Menu>
                                    <MenuButton
                                        as={IconButton}
                                        aria-label="Options"
                                        icon={<BsThreeDotsVertical />}
                                        variant="outline"
                                        size="sm"
                                        color="white"
                                        bg="oleniumBlack.600"
                                        isLoading={loading}
                                        isDisabled={loading}
                                    />
                                    <MenuList bg="oleniumBlack.600">
                                        <MenuItem
                                            onClick={disconnectInstagram}
                                            bg={"transparent"}
                                            color="red.500"
                                        >
                                            Disconnect Instagram
                                        </MenuItem>

                                        <MenuItem
                                            onClick={deleteAllInstagramPosts}
                                            bg={"transparent"}
                                            color={"white"}
                                        >
                                            Delete Fetched Posts
                                        </MenuItem>


                                        {/* if posts is not empty, show this */}
                                        {posts.length > 0 && (
                                            <MenuItem onClick={fetchInstagramPosts} bg={"transparent"}>
                                                Refresh Posts
                                            </MenuItem>
                                        )}

                                    </MenuList>
                                </Menu>
                            )}
                        </HStack>


                        <Divider mb={4} />

                        {error && (
                            <Alert status="error" mb={5}>
                                <Alert.Icon />
                                <Alert.Title mr={2}>{error}</Alert.Title>
                            </Alert>
                        )}


                        {!hasToken ? (
                            <Flex direction="column" align="center" justify="center" w="100%" h="100%">
                                <Button onClick={setupInstagram} isLoading={loading} variant="oleniumBlue" my={5} mx="auto">
                                    Connect Instagram
                                </Button>
                            </Flex>
                        ) : (
                            <>
                                {posts.length === 0 ? (
                                    <Flex direction="column" align="center" justify="center" w="100%" h="100%">
                                        <Button onClick={fetchInstagramPosts} isLoading={loading} variant="oleniumBlue" my={5}>
                                            Import Posts
                                        </Button>
                                    </Flex>
                                ) : (
                                    <>
                                        <Grid templateColumns={{ base: "2fr", md: "repeat(2, 2fr)", lg: "repeat(3, 1fr)", xl: "repeat(3, 1fr)" }} gap={4} mb={5}>
                                            {posts.map((post, index) => (

                                                <Box position="relative" borderRadius="xl" overflow="hidden" borderWidth="2px" borderColor="transparent" _hover={{ borderColor: "#0085FF" }} key={index} height={"182px"} maxHeight={"182px"} maxWidth={"182px"} mx={"auto"}>
                                                    {renderPost(post)}

                                                    <Box
                                                        position="absolute"
                                                        top={0}
                                                        left={0}
                                                        h={"182px"}
                                                        w={"100%"}
                                                        bgGradient="linear(to-b, transparent, rgba(0, 0, 0, 0.5))"
                                                        opacity="0"
                                                        _hover={{ opacity: "1", cursor: "pointer" }}
                                                        display="flex"
                                                        flexDirection="column"
                                                        justifyContent="flex-end"
                                                    >
                                                        <Button
                                                            variant="oleniumBlue"
                                                            size="sm"
                                                            mx={2}
                                                            my={3}
                                                            onClick={() => openEditModal(post)}
                                                            leftIcon={<FaPlus size={12} />}
                                                        >
                                                            Add
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            ))}
                                        </Grid>
                                    </>
                                )}
                            </>
                        )}

                    </ModalBody>
                </ModalContent>
            </Modal>
            <PortfolioForm
                isOpen={isPortfolioFormOpen}
                onClose={onPortfolioFormClose}
                initialValues={portfolioFormValues}
                onSubmit={handlePortfolioFormSubmit} // Define this function based on your requirements
                services={services}
                isEditMode={false}
            />
        </>
    );
}

export default InstagramImportModal;