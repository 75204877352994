import { CheckIcon, DeleteIcon, ViewIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  HStack,
  Heading,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  BsCashCoin,
  BsChatHeartFill,
  BsPersonBadge,
  BsThreeDotsVertical,
} from "react-icons/bs";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { getIdToken } from "../../../../cognito/cognitoAuth";

const API_URL = process.env.REACT_APP_API_URL;

const ProjectsTable = () => {
  const [projects, setProjects] = useState([]);
  const [users, setUsers] = useState([]);
  const [currentProject, setCurrentProject] = useState(null);
  const [action, setAction] = useState("");
  const [timerIds, setTimerIds] = useState({});
  const [providerInfo, setProviderInfo] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isChatAnalysisModalOpen, setChatAnalysisModalOpen] = useState(false);

  useEffect(() => {
    projects.forEach(async (project) => {
      const profile = await getProviderNameAndProfilePicture(
        project.provider_id,
      );
      setProviderInfo((prevState) => ({
        ...prevState,
        [project.id]: profile, // Save the entire profile information
      }));
    });
  }, [projects]);

  const renderStatus = (status) => {
    // AWAITING_ASSIGNMENT to Awating assignment
    const statusWords = status.split("_").map((word) => {
      return word.charAt(0) + word.slice(1).toLowerCase();
    });
    return statusWords.join(" ");
  };

  const convertTime = (time) => {
    // 2023-09-21T23:59:59 to days, hours and minutes until deadline

    const deadline = new Date(time);
    const now = new Date();

    const days = Math.floor((deadline - now) / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      ((deadline - now) % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
    );

    if (days === 0 && hours === 0) {
      return "Less than an hour";
    }

    if (hours === 1) {
      return `${days} days and ${hours} hour`;
    }

    return `${days} days and ${hours} hours`;
  };

  useEffect(() => {
    // Clear existing timers
    Object.values(timerIds).forEach(clearInterval);

    // Setup new timers
    const newTimerIds = {};
    projects.forEach((project) => {
      newTimerIds[project.id] = setInterval(() => {
        // This will trigger re-render and update the time
        setProjects((prevProjects) => [...prevProjects]);
      }, 60000); // Update every 1 minute
    });
    setTimerIds(newTimerIds);

    return () => {
      // Clear timers when component unmounts
      Object.values(timerIds).forEach(clearInterval);
    };
  }, [projects]);

  const fetchData = async (entity, setState) => {
    try {
      const authentication = await getIdToken();
      const response = await fetch(`${API_URL}/${entity}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authentication}`,
        },
      });

      const data = await response.json();
      if (data.statusCode === 200) {
        const parsedData = JSON.parse(data.body);
        if (Array.isArray(parsedData)) {
          setState(parsedData);
        }
      } else {
        console.error(`Error status code: ${data.statusCode}`);
        setState([]);
      }
    } catch (error) {
      console.error(`Error fetching ${entity}:`, error);
      setState([]);
    }
  };

  const openModal = (project, modalAction) => {
    setAction(modalAction);
    setCurrentProject(project);
    onOpen();
  };

  const openChatAnalysisModal = (projectId) => () => {
    setChatAnalysisModalOpen(true);
  };

  const returnNumberOfProjects = (status) => {
    return projects.filter((project) => project.status === status).length;
  };

  const getProviderNameAndProfilePicture = async (id) => {
    const authentication = await getIdToken();
    const userResponse = await axios.get(`${API_URL}/users/info/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authentication}`,
      },
    });

    const profile = userResponse.data.body;

    return profile;
  };

  useEffect(() => {
    fetchData("projects", setProjects);
    fetchData("users", setUsers);
  }, []);

  const navigate = useNavigate();

  const onBack = () => {
    navigate("/admin-panel");
  };

  return (
    <Box>
      <Flex justifyContent="flex-start" mb={10}>
        <IconButton aria-label="Back" icon={<FaArrowLeft />} onClick={onBack} />
        <Heading as="h1" size="lg" ml={5} mt={1}>
          Project Manager
        </Heading>
      </Flex>
      <Grid templateColumns="repeat(4, 1fr)" gap={6} mb={6}>
        <GridItem colSpan={{ base: 2, md: 2, lg: 1 }}>
          <Box
            p={5}
            shadow="md"
            borderWidth="1px"
            flex="1"
            borderRadius="md"
            bg={"oleniumBlack.600"}
          >
            <Text color={"white"} fontSize="sm" fontWeight="bold">
              Total projects
            </Text>
            <Text color={"white"} fontSize="2xl" fontWeight="bold">
              {projects.length}
            </Text>
          </Box>
        </GridItem>
        <GridItem colSpan={{ base: 2, md: 2, lg: 1 }}>
          <Box
            p={5}
            shadow="md"
            borderWidth="1px"
            flex="1"
            borderRadius="md"
            bg={"oleniumBlack.600"}
          >
            <Text color={"white"} fontSize="sm" fontWeight="bold">
              Awaiting assignment
            </Text>
            <Text color={"white"} fontSize="2xl" fontWeight="bold">
              {returnNumberOfProjects("AWAITING_ASSIGNMENT")}
            </Text>
          </Box>
        </GridItem>
        <GridItem colSpan={{ base: 2, md: 2, lg: 1 }}>
          <Box
            p={5}
            shadow="md"
            borderWidth="1px"
            flex="1"
            borderRadius="md"
            bg={"oleniumBlack.600"}
          >
            <Text color={"white"} fontSize="sm" fontWeight="bold">
              In progress
            </Text>
            <Text color={"white"} fontSize="2xl" fontWeight="bold">
              {returnNumberOfProjects("IN_PROGRESS")}
            </Text>
          </Box>
        </GridItem>
        <GridItem colSpan={{ base: 2, md: 2, lg: 1 }}>
          <Box
            p={5}
            shadow="md"
            borderWidth="1px"
            flex="1"
            borderRadius="md"
            bg={"oleniumBlack.600"}
          >
            <Text color={"white"} fontSize="sm" fontWeight="bold">
              Completed
            </Text>
            <Text color={"white"} fontSize="2xl" fontWeight="bold">
              {returnNumberOfProjects("COMPLETED")}
            </Text>
          </Box>
        </GridItem>
      </Grid>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Assigned to</Th>
            <Th>Status</Th>
            <Th>Start Fee</Th>
            <Th>Deadline</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {projects.map((project) => (
            <Tr key={project.id}>
              <Td>{project.name}</Td>
              <Td>
                <HStack justify="flex-start">
                  <Avatar
                    size="xs"
                    name={`${providerInfo[project.id]?.given_name} ${providerInfo[project.id]?.family_name
                      }`}
                    src={providerInfo[project.id]?.profile_picture_url}
                  />
                  <Text color={"white"} fontSize="xs" fontWeight="bold">
                    {`${providerInfo[project.id]?.given_name} ${providerInfo[project.id]?.family_name
                      }`}
                  </Text>
                </HStack>
              </Td>
              <Td>
                <Tag
                  size="md"
                  variant="outline"
                  rounded="full"
                  colorScheme={
                    project.status === "AWAITING_ASSIGNMENT"
                      ? "yellow"
                      : project.status === "IN_PROGRESS"
                        ? "blue"
                        : project.status === "COMPLETED"
                          ? "green"
                          : "red"
                  }
                >
                  {renderStatus(project.status)}
                </Tag>
              </Td>
              <Td>
                {project.start_fee_paid ? (
                  <Tag
                    size="md"
                    variant="outline"
                    rounded="full"
                    colorScheme="green"
                    w={"fit-content"}
                  >
                    Paid
                  </Tag>
                ) : (
                  <Tag
                    size="md"
                    variant="outline"
                    rounded="full"
                    colorScheme="red"
                    w={"fit-content"}
                  >
                    Not paid
                  </Tag>
                )}
              </Td>
              <Td>
                <Tag
                  size="md"
                  variant="outline"
                  rounded="full"
                  w={"fit-content"}
                >
                  {convertTime(project.deadline)}
                </Tag>
              </Td>
              <Td>
                <Menu>
                  <MenuButton>
                    <IconButton
                      aria-label="Options"
                      icon={<BsThreeDotsVertical />}
                      size="xs"
                      variant="outline"
                      rounded="full"
                      mx={2}
                    />
                  </MenuButton>
                  <MenuList bg={"oleniumBlack.600"}>
                    <MenuItem
                      icon={<ViewIcon />}
                      onClick={() => openModal(project, "View")}
                      bg={"oleniumBlack.600"}
                    >
                      View project
                    </MenuItem>
                    <MenuItem
                      icon={<CheckIcon />}
                      onClick={() => openModal(project, "Complete")}
                      bg={"oleniumBlack.600"}
                    >
                      Mark as completed
                    </MenuItem>
                    <MenuItem
                      icon={<BsPersonBadge />}
                      onClick={() => openModal(project, "Assign")}
                      bg={"oleniumBlack.600"}
                    >
                      Change provider
                    </MenuItem>
                    <MenuItem
                      icon={<BsCashCoin />}
                      onClick={() => openModal(project, "StartFee")}
                      bg={"oleniumBlack.600"}
                    >
                      Remove start fee
                    </MenuItem>
                    <MenuItem
                      icon={<BsChatHeartFill />}
                      onClick={openChatAnalysisModal(project.id)}
                      bg={"oleniumBlack.600"}
                    >
                      View Chat Analysis
                    </MenuItem>
                    <MenuItem
                      icon={<DeleteIcon color="red.600" />}
                      onClick={() => openModal(project, "Delete")}
                      bg={"oleniumBlack.600"}
                      color={"red.600"}
                    >
                      Delete project
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <ActionModal
        isOpen={isOpen}
        onClose={onClose}
        currentProject={currentProject}
        action={action}
      />
      <ChatAnalysisModal
        isOpen={isChatAnalysisModalOpen}
        onClose={() => setChatAnalysisModalOpen(false)}
      />
    </Box>
  );
};

const ActionModal = ({ isOpen, onClose, currentProject, action }) => {
  const [deleteInput, setDeleteInput] = useState("");
  const [provider, setProvider] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [providers, setProviders] = useState([]);
  const [loading, setLoading] = useState(false);

  const toast = useToast();

  useEffect(() => {
    if (action === "Assign") {
      fetchProviders(); // Fetch providers when the Assign action is triggered
    }
  }, [action]);

  useEffect(() => {
    if (currentProject) {
      if (action === "Delete") {
        setIsValid(deleteInput === currentProject.name);
      } else if (action === "Assign") {
        setIsValid(provider !== "");
      } else if (action === "Complete") {
        setIsValid(true); // No validation required for Complete
      } else if (action === "StartFee") {
        setIsValid(true); // No validation required for StartFee
      }
    }
  }, [deleteInput, provider, action, currentProject?.name]); // Notice the use of optional chaining with currentProject?.name

  const fetchProviders = async () => {
    setLoading(true);
    const idToken = await getIdToken();
    const apiUrl = process.env.REACT_APP_API_URL;

    try {
      const response = await axios.get(`${apiUrl}/project/providers/${currentProject.service_id}`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
      });

      console.log("Full response object:", response); // Log the entire response object

      if (Array.isArray(response.data)) {
        setProviders(response.data);
      } else {
        console.error("Unexpected response format:", response.data);
        toast({
          title: "An error occurred.",
          description:
            "Received an unexpected response format from the server.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error(`Error fetching providers: ${error}`);
      toast({
        title: "An error occurred.",
        description: "An unexpected error occurred while fetching providers.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setLoading(false);
  };


  const handleConfirm = async () => {
    setLoading(true);
    const idToken = await getIdToken();

    if (action === "Delete") {
      try {
        await axios.delete(`${API_URL}/projects/${currentProject.id}`, {
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
          },
        });
        toast({
          title: "Project Deleted.",
          description: "The project has been successfully deleted.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        // TODO: Refresh the projects list here
      } catch (error) {
        console.error(`Error deleting project: ${error}`);
        toast({
          title: "An error occurred.",
          description: "Could not delete the project.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } else if (
      action === "Assign" ||
      action === "Complete" ||
      action === "StartFee"
    ) {
      let updatedFields = {};

      if (action === "Assign") {
        updatedFields = { provider_id: provider, status: "AWAITING_ACCEPTANCE", start_date: new Date().toISOString() };
      } else if (action === "Complete") {
        updatedFields = { status: "COMPLETED" };
      } else if (action === "StartFee") {
        updatedFields = { start_fee_paid: true };
      }

      try {
        await axios.put(
          `${API_URL}/projects/${currentProject.id}`,
          updatedFields,
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          },
        );
        toast({
          title: "Project Updated.",
          description: "The project has been successfully updated.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        // TODO: Refresh the projects list here
      } catch (error) {
        console.error(`Error updating project: ${error}`);
        toast({
          title: "An error occurred.",
          description: "Could not update the project.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
    //window.location.reload();
    setLoading(false);
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={action === "View" ? "2xl" : "md"}
    >
      <ModalOverlay />
      <ModalContent bg={"oleniumBlack.600"}>
        <ModalHeader>{action} Project</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {action === "View" && (
            <div>
              <Text size={"lg"} fontWeight={"bold"} mb={4}>
                {currentProject?.name}
              </Text>
              <Text size={"md"} mb={4}>
                {currentProject?.brief}
              </Text>
            </div>
          )}
          {action === "Delete" && (
            <div>
              <Text mb={4}>
                Are you sure you want to delete this project? This action cannot
                be undone.
              </Text>
              <Input
                value={deleteInput}
                onChange={(e) => setDeleteInput(e.target.value)}
                placeholder="Project name"
              />
            </div>
          )}
          {action === "Assign" && (
            <div>
              <Button mb={4} isLoading={loading} onClick={fetchProviders}>
                Refresh
              </Button>
              <Text mb={4}>Select a provider to assign this project to.</Text>
              {providers.length === 0 ? (
                <Text mb={4}>No providers available.</Text>
              ) : (
                <Select onChange={(e) => setProvider(e.target.value)}>
                  <option value="" disabled selected>
                    Select Provider
                  </option>
                  {providers.map((provider, index) => (
                    <option key={index} value={provider.username}>
                      {provider.given_name} {provider.family_name}
                    </option>
                  ))}
                </Select>
              )}
            </div>
          )}
          {action === "Complete" && (
            <div>
              <Text mb={4}>
                Are you sure you want to mark this project as completed?
              </Text>
            </div>
          )}
          {action === "StartFee" && (
            <div>
              <Text mb={4}>
                Are you sure you want to remove the start fee for this project?
              </Text>
            </div>
          )}
        </ModalBody>
        <ModalFooter mb={4}>
          <Button
            onClick={onClose}
            variant={action === "View" ? "oleniumBlue" : "ghost"}
          >
            Close
          </Button>
          <Spacer />

          {action !== "View" && (
            <Button
              variant="oleniumBlue"
              onClick={handleConfirm}
              isDisabled={!isValid}
            >
              Confirm
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const ChatAnalysisModal = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent bg={"oleniumBlack.600"}>
        <ModalHeader>Chat Analysis</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{/* Your chat analysis content here */}</ModalBody>
        <ModalFooter>
          <Button onClick={onClose} variant="ghost">
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ProjectsTable;
