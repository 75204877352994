import {
  Box,
  HStack,
  Icon,
  IconButton,
  Select,
  Text,
  VStack,
  useColorMode
} from "@chakra-ui/react";
import React from "react";
import { BsFillCloudCheckFill } from "react-icons/bs";
import { RxCross1 } from "react-icons/rx";
import FileIcon from "./FileIcons";

const assetTypes = [
  { value: "", label: "Select an asset type" },
  { value: "LOGO", label: "Logo" },
  { value: "IMAGE", label: "Image" },
  { value: "BRANDING_GUIDELINE", label: "Branding Guideline" },
  { value: "COLOUR_PALETTE", label: "Colour Palette" },
  { value: "TYPOGRAPHY", label: "Typography" },
  { value: "ICON_SET", label: "Icon Set" },
  { value: "MARKETING_MATERIAL", label: "Marketing Material" },
  { value: "PRESENTATION_TEMPLATE", label: "Presentation Template" },
  { value: "WEBSITE_DESIGN", label: "Website Design" },
  { value: "LETTERHEAD", label: "Letterhead" },
  { value: "BUSINESS_CARD", label: "Business Card" },
  { value: "ENVELOPE", label: "Envelope" },
  { value: "SIGNAGE", label: "Signage" },
  { value: "STICKER", label: "Sticker" },
  { value: "FLYER", label: "Flyer" },
  { value: "POSTER", label: "Poster" },
  { value: "SOCIAL_MEDIA_ASSET", label: "Social Media Asset" },
  { value: "EMAIL_SIGNATURE", label: "Email Signature" },
  { value: "BROCHURE", label: "Brochure" },
  { value: "CATALOG", label: "Catalog" },
  { value: "INFOGRAPHIC", label: "Infographic" },
  { value: "ANIMATION", label: "Animation" },
  { value: "NEWSLETTER", label: "Newsletter" },
  { value: "PRESS_KIT", label: "Press Kit" },
  { value: "PHOTOGRAPHY", label: "Photography" },
  { value: "VEHICLE_WRAP", label: "Vehicle Wrap" },
  { value: "APPAREL_DESIGN", label: "Apparel Design" },
  { value: "PACKAGING", label: "Packaging" },
  { value: "EXHIBITION_STANDS", label: "Exhibition Stands" },
  { value: "OTHER", label: "Other" },
];

const FileItem = ({
  file,
  onDelete,
  progressPercentage,
  status,
  onAssetTypeChange,
  handleAssetTypeChange,
}) => {
  return (
    <Box
      py={{ base: 2, md: 4 }}
      px={{ base: 2, md: 4 }}
      alignItems="center"
      borderRadius="xl"
      bg={useColorMode().colorMode === "light" ? "white" : "oleniumBlack.700"}
    >
      <HStack spacing={2} w="100%">
        {status !== "uploaded" ? (
          <FileIcon file={file.name} width={6} height={6} color={"white"} />
        ) : (
          <Icon as={BsFillCloudCheckFill} w={6} h={6} color={"white"} />
        )}

        <VStack alignItems="space-between" w={{ base: "85%", md: "100%" }}>
          <HStack spacing={2} w="100%" justifyContent="space-between">
            <Text fontWeight="bold" isTruncated w={{ base: "30%", md: "100%" }} fontSize={{ base: "xs", md: "sm" }}>
              {status === "uploaded" ? "File successfully uploaded" : file.name}
            </Text>

            <Select
              size="sm"
              w={{ base: "40%", md: "100%" }}
              rounded="md"
              defaultValue=""
              isDisabled={status !== "selected"}
              onChange={(e) => {
                file.assetType = e.target.value;
                handleAssetTypeChange();
              }}
            >
              {assetTypes.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>

            <IconButton
              onClick={() => onDelete(file)}
              size="xs"
              icon={<RxCross1 />}
              isLoading={status === "uploading"}
              isDisabled={status !== "selected"}
            />
          </HStack>
        </VStack>
      </HStack>
    </Box>
  );
};

export default FileItem;
