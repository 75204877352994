import {
  Button,
  Center,
  Flex,
  Icon,
  Text,
  VStack,
  useColorModeValue
} from "@chakra-ui/react";
import React from "react";
import { FiArrowRight } from "react-icons/fi";

import { useNavigate } from "react-router-dom";

function Error() {
  const textColor = useColorModeValue("oleniumGray.500", "oleniumGray.400");
  const textColorAlt = useColorModeValue("oleniumGray.400", "oleniumGray.300");

  const navigate = useNavigate();

  return (
    <Flex
      minHeight="80vh"
      minWidth="100vw"
      alignItems="center"
      justifyContent="center"
    >
      <Center w="100%" h="60vh" py={4} rounded="xl">
        <VStack>
          <Text
            bgGradient="linear(to-l, blue.300, blue.500)"
            bgClip="text"
            fontSize={{ base: "6xl", sm: "7xl", md: "9xl" }}
            fontWeight="extrabold"
          >
            404
          </Text>
          <Text color={textColor} fontSize="2xl" fontWeight="bold">
            Page not found
          </Text>
          <Flex m={4} w={"100%"} textAlign="center" maxW="500px">
            <Text color={textColorAlt} fontSize="md" fontWeight="medium">
              The page you are looking for might have been removed, had its name
              changed, or is temporarily unavailable.
            </Text>
          </Flex>
          <Button
            variant="oleniumBlue"
            size="md"
            mt={4}
            onClick={() => navigate("/login")}
          >
            Login
            <Icon as={FiArrowRight} ml={1} mr={1} />
          </Button>
        </VStack>
      </Center>
    </Flex>
  );
}

export default Error;
