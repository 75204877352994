import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Skeleton,
  SkeletonText,
  Tag,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { getIdToken } from "../../../../cognito/cognitoAuth";

const ProjectInformation = ({
  description = "",
  status,
  deadline,
  capability,
  link,
  provider_id,
  isLoading = true,
}) => {
  const [descriptionMore, setDescriptionMore] = useState(false);
  const textColor = useColorModeValue("oleniumBlack.600", "white");
  const boxColor = useColorModeValue("white", "oleniumBlack.600");
  const [provider, setProvider] = useState(null);

  const toggleDescription = () => setDescriptionMore(!descriptionMore);

  const trimString = (str) =>
    str.length > 341 ? `${str.substring(0, 341)}...` : str;

  const renderStatus = (status) => {
    const statuses = {
      IN_PROGRESS: "green",
      COMPLETED: "green",
      CLOSED: "red",
      AWAITING_ACCEPTANCE: "yellow",
      AWAITING_ASSIGNMENT: "yellow",
    };

    // Convert the status to lowercase and then capitalize the first letter of each word
    const capitalizedStatus = status
      .toLowerCase()
      .replace(/\b\w/g, (l) => l.toUpperCase());

    return (
      <Tag variant="outline" colorScheme={statuses[status] || "yellow"}>
        {capitalizedStatus.replace("_", " ")}
      </Tag>
    );
  };

  const convertToDaysLeft = (dueDate) => {
    const today = new Date();
    const due = new Date(dueDate);
    const diffDays = Math.ceil((due - today) / (1000 * 60 * 60 * 24));

    if (diffDays <= 0) return "Overdue";
    if (diffDays === 1) return "1 day left";
    return `${diffDays} days left`;
  };

  const getProvider = async (provider_id) => {
    const API_URL = process.env.REACT_APP_API_URL;
    const token = await getIdToken();

    const userResponse = await axios.get(
      `${API_URL}/users/info/${provider_id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
    );

    if (userResponse.data.statusCode === 200) {
      const { profile_picture_url, given_name, family_name } =
        userResponse.data.body;
      return {
        profile_picture_url,
        given_name,
        family_name,
      };
    } else {
      throw new Error("Failed to fetch user info.");
    }
  };

  useEffect(() => {
    if (provider_id) {
      getProvider(provider_id)
        .then((provider) => {
          setProvider(provider);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [provider_id]);

  return (
    <Box w="100%" bg={boxColor} rounded="xl" p={8} mb={6} minW={300}>
      <Text color={textColor} fontSize="md" fontWeight="bold" mb={2}>
        Project Information
      </Text>
      <SkeletonText
        isLoaded={!isLoading}
        rounded="6"
        mt="2"
        noOfLines={4}
        spacing="3"
        skeletonHeight="3"
      >
        <Text
          color="gray.400"
          fontWeight="normal"
          fontSize="sm"
          wordBreak={"break-word"}
        >
          {descriptionMore ? description : trimString(description)}
        </Text>
      </SkeletonText>
      {description.length > 341 && (
        <Button
          onClick={toggleDescription}
          mb={3}
          mt={3}
          size="sm"
          variant="link"
        >
          {descriptionMore ? "Less" : "More"}
        </Button>
      )}
      <Divider mt={4} />

      {provider && (
        <>
          <Flex mt={4} justify="space-between">
            <Text color={textColor} fontSize="xs" fontWeight="bold">
              Team:
            </Text>
            <HStack justify="flex-start">
              <Avatar
                size="xs"
                name={`${provider.given_name} ${provider.family_name}`}
                src={provider.profile_picture_url}
              />
              <Text color={textColor} fontSize="xs" fontWeight="bold">
                {`${provider.given_name} ${provider.family_name}`}
              </Text>
            </HStack>
          </Flex>
        </>
      )}

      <Flex mt={4} justify="space-between">
        <Text color={textColor} fontSize="xs" fontWeight="bold">
          Status:
        </Text>
        {isLoading ? (
          <Skeleton
            isLoaded={!isLoading}
            rounded="6"
            height="20px"
            width="80px"
          />
        ) : (
          renderStatus(status)
        )}
      </Flex>
      <Flex mt={4} justify="space-between">
        <Text color={textColor} fontSize="xs" fontWeight="bold">
          Deadline:
        </Text>
        {isLoading ? (
          <Skeleton
            isLoaded={!isLoading}
            rounded="6"
            height="20px"
            width="80px"
          />
        ) : (
          <Tag variant="outline">{convertToDaysLeft(deadline)}</Tag>
        )}
      </Flex>
      <Flex mt={4} justify="space-between">
        <Text color={textColor} fontSize="xs" fontWeight="bold">
          Capability:
        </Text>
        {isLoading ? (
          <Skeleton
            isLoaded={!isLoading}
            rounded="6"
            height="20px"
            width="80px"
          />
        ) : (
          <Tag variant="outline">{capability}</Tag>
        )}
      </Flex>
    </Box>
  );
};

export default ProjectInformation;
