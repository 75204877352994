import React, { useState, useEffect } from "react";

import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Icon,
  Spacer,
  Spinner,
  Text,
  VStack,
  useColorModeValue
} from "@chakra-ui/react";
import { Step, Steps, useSteps } from "chakra-ui-steps";
import { Link } from "react-router-dom";

import { BsCheckCircle } from "react-icons/bs";
import { getIdToken } from "../../../cognito/cognitoAuth";
import ProjectDetails from "./Forms/ProjectDetails";
import ProjectService from "./Forms/ProjectService";
import TeamAssignment from "./Forms/TeamAssignment";
import WhatNext from "./Forms/WhatNext";

import axios from "axios";

const NewProject = () => {
  const { nextStep, prevStep, setStep, reset, activeStep } = useSteps({
    initialStep: 0,
  });

  const textColor = useColorModeValue("oleniumGray.700", "white");
  const boxColor = useColorModeValue("white", "oleniumBlack.600");
  const [isFormValid, setIsFormValid] = React.useState(false);

  const [selectedService, setSelectedService] = React.useState("");
  const [projectTitle, setProjectTitle] = React.useState("");
  const [projectBrief, setProjectBrief] = React.useState("");
  const [providerReasoning, setProviderReasoning] = React.useState("");
  const [deadline, setDeadline] = React.useState("");
  const [providerId, setProviderId] = useState(null);
  const [alert, setAlert] = useState({ show: false, status: "", message: "" });
  const [isLoading, setIsLoading] = useState(false);

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleProviderSelect = (providerId) => {
    setProviderId(providerId); // Set the selected provider's ID
  };

  useEffect(() => {
    // set window scroll to top
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // Check if selectedService is not empty and we are on the first step
    if (selectedService && activeStep === 0) {
      nextStep(); // Automatically move to the next step

      // reset the selected provider

    }
    // You might want to include a condition to prevent auto-advance in certain scenarios
  }, [selectedService]); // Include dependencies that trigger the effect


  const createProject = async (values) => {
    try {
      setIsLoading(true);
      const IdToken = await getIdToken(); // Get the access token here

      const response = await axios.post(
        apiUrl + "/projects",
        {
          name: projectTitle,
          brief: projectBrief,
          deadline: deadline,
          service_id: selectedService,
        },
        {
          headers: {
            Authorization: `Bearer ${IdToken}`,
            "Content-Type": "application/json",
          },
        },
      );

      if (response.status === 200) {
        // Show success alert
        setAlert({
          show: true,
          status: "success",
          message: "Project created successfully.",
        });
      }
    } catch (error) {
      console.error("Error creating project", error);

      // Check error response status code
      let message = "An unexpected error occurred.";
      if (error.response) {
        switch (error.response.status) {
          case 400:
            message = "Bad request. Please check the provided data.";
            break;
          case 401:
            message = "Unauthorized. Please log in again.";
            break;
          case 403:
            message =
              "Forbidden. You don't have permission to perform this action.";
            break;
          case 404:
            message = "Not found. The requested resource was not found.";
            break;
          case 500:
            message =
              "Internal Server Error. Something went wrong on our side.";
            break;
          default:
            break;
        }
      }

      // Show error alert
      setAlert({
        show: true,
        status: "error",
        message: message,
      });
    } finally {
      setIsLoading(false); // Set loading state back to false
    }
  };

  React.useEffect(() => {
    setIsFormValid(
      projectTitle.trim() !== "" &&
      projectBrief.trim() !== "" &&
      deadline.trim() !== "",
    );
  }, [projectTitle, projectBrief, deadline]);

  const steps = [
    {
      label: "Service",
      title: "Choose a service",
      comp: (
        <ProjectService
          selectedService={selectedService}
          setSelectedService={setSelectedService}
        />
      ),
    },
    {
      label: "Details",
      title: "Tell us more",
      comp: (
        <ProjectDetails
          projectTitle={projectTitle}
          setProjectTitle={setProjectTitle}
          projectBrief={projectBrief}
          setProjectBrief={setProjectBrief}
          deadline={deadline}
          setDeadline={setDeadline}
          onFormValidityChange={setIsFormValid}
        />
      ),
    },
    // {
    //   label: "Team",
    //   title: "Assign a team",
    //   comp: (
    //     <TeamAssignment
    //       selectedService={selectedService}
    //       projectBrief={projectBrief}
    //       projectTitle={projectTitle}
    //       deadline={deadline}
    //       onProviderSelect={handleProviderSelect} // Pass the function to handle provider selection
    //       setProviderReasoning={setProviderReasoning}
    //     />
    //   ),
    // },
    { label: "What's next", title: "What happens next?", comp: <WhatNext /> },
  ];

  return (
    <div>
      <Flex maxW={"800px"} w={"full"} mx={"auto"} py={{ base: 0, md: 4 }} px={0}>
        <Flex flexDir="column" width="100%">
          {activeStep < steps.length && (
            <Steps
              activeStep={activeStep}
              colorScheme="green"
              size="sm"
              p={0}
              bg="transparent"
              variant="simple"
            >
              {steps.map(({ label, title, comp }) => (
                <Step label={label} key={label}>
                  <Box
                    w="100%"
                    justifyContent={"center"}
                    px={{ base: 0, md: 8 }}
                    bg={boxColor}
                    rounded="xl"
                    maxWidth={"800px"}
                    pt={{ lg: 8, md: 10, base: 8 }}
                    pb={{ lg: 12, md: 10, base: 8 }}
                    mt={8}

                  >
                    <Heading
                      size="lg"
                      color={textColor}
                      mb={6}
                      textAlign="center"
                    >
                      {title}
                    </Heading>
                    {comp}
                  </Box>
                  <Flex width="100%" justify="space-between" mt={8}>
                    {activeStep > 0 && (
                      <Button
                        isDisabled={activeStep === 0}
                        mr={4}
                        onClick={prevStep}
                      >
                        Previous
                      </Button>
                    )}
                    <Spacer />

                    <Button
                      onClick={() => {
                        if (activeStep === steps.length - 1) {
                          createProject({
                            service_id: selectedService,
                            name: projectTitle,
                            brief: projectBrief,
                            deadline: deadline,
                          });
                        }
                        nextStep();
                      }}
                      variant="oleniumBlue"
                      isDisabled={
                        activeStep === 0
                          ? !selectedService
                          : activeStep === 1
                          ? !isFormValid
                          : false
                      }
                    >
                      {activeStep === steps.length - 1
                        ? "Create Project"
                        : "Next"}
                    </Button>
                  </Flex>
                </Step>
              ))}
            </Steps>
          )}

          {activeStep === steps.length && (
            <Center
              w="100%"
              py={10}
              bg={boxColor}
              rounded="xl"
              height={"400px"}
              maxWidth={"800px"}
              mt={{ base: 8, md: 0, lg: 16 }}
            >
              <VStack spacing={4}>
                {isLoading ? (
                  <>
                    <Spinner size={"xl"} />
                    <Text color={textColor} fontSize="xl" fontWeight="bold">
                      Creating Project
                    </Text>
                  </>
                ) : (
                  <>
                    {alert.status === "success" ? (
                      <>
                        <Icon as={BsCheckCircle} w={12} h={12} />
                        <Text color={textColor} fontSize="xl" fontWeight="bold">
                          Project Created
                        </Text>
                        <Text
                          color={textColor}
                          fontSize="md"
                          fontWeight="normal"
                          overflowWrap={"break-word"}
                          px={8}
                          textAlign={"center"}
                          maxW={"600px"}
                        >
                          Your project has been created successfully. Your
                          chosen designer will review your project and get back
                          to you soon.
                        </Text>
                        <Link to={"/"}>
                          <Button variant="oleniumBlue">View Projects</Button>
                        </Link>
                      </>
                    ) : (
                      <Alert status="error" rounded="md" w="100%">
                        <AlertIcon />
                        {alert.message}
                      </Alert>
                    )}
                  </>
                )}
              </VStack>
            </Center>
          )}
        </Flex>
      </Flex>
    </div>
  );
};

export default NewProject;
