import {
  Tag,
  Box,
  HStack,
  Hide,
  Image,
  Text,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import React from "react";

function CapabilityCard({
  value,
  title,
  description,
  icon,
  onChange,
  selectedOption,
  isDisabled,
}) {
  const textColor = useColorModeValue("oleniumBlack.700", "white");
  const boxColor = useColorModeValue("white", "oleniumBlack.300");
  const borderColor = useColorModeValue("oleniumBlack.200", "oleniumBlack.600");
  const borderColorActive = useColorModeValue("#0085FF", "#0085FF");

  const disabledTextColour = useColorModeValue("gray.100", "white");
  const disabledBoxColour = useColorModeValue("oleniumBlack.300", "oleniumBlack.500");

  const handleClick = (e) => {
    if (!isDisabled) {
      onChange(value);
    }
  };

  return (
    <motion.div transition={{ delay: 0.2 }}>
      <Box
        borderColor={
          selectedOption && !isDisabled ? borderColorActive : borderColor
        }
        shadow={selectedOption && !isDisabled ? "lg" : ""}
        borderRadius="2xl"
        p={{ base: 3, sm: 4, md: 6 }}
        pb={{ base: 4, sm: 6, md: 6 }}
        m={{ base: 0, md: 4 }}
        my={{ base: 2 }}
        bg={isDisabled ? disabledBoxColour : boxColor}
        borderWidth="2px"
        onClick={handleClick}
        cursor={!isDisabled ? "pointer" : "not-allowed"}
        _hover={{
          borderColor: !isDisabled ? borderColorActive : borderColor,
        }}
      >
        <Hide above="md">
          <HStack spacing={3} align="center">
            <Image
              src={icon}
              alt={title}
              width={{ base: "40px", md: "60px" }}
              height={{ base: "40px", md: "60px" }}
              aspectRatio={1}
              my={3}
              ml={3}
              // opacity 0.5 if disabled
              opacity={0.5}
            />
            <VStack align="left" spacing={0}>
              <Text
                color={isDisabled ? disabledTextColour : textColor}
                fontSize={{ base: "sm", sm: "md", md: "lg", lg: "lg" }}
                fontWeight="bold"
                align="left"
                m={0}
                p={0}
              >
                {title}
              </Text>
              {isDisabled && (
              <Tag size="sm" w="fit-content" bg={"#0085FF"} color="white" fontWeight={"bold"} mt={1}>
                Coming Soon
              </Tag>
              )}
            </VStack>
          </HStack>
        </Hide>
        <HStack spacing={{ base: 3, md: 2 }}>
          <Hide below="md">
            <Image
              src={icon}
              alt={title}
              width={{ base: "40px", md: "50px" }}
              height={{ base: "40px", md: "50px" }}
              aspectRatio={1}
            />
          </Hide>

          <Box>
            <Hide below="md">
              <HStack>
              <Text
                color={isDisabled ? disabledTextColour : textColor}
                fontSize={{ base: "sm", sm: "sm", md: "lg", lg: "lg" }}
                fontWeight="bold"
                ml={3}
                mb={1}
                align="left"
              >
                {title}
              </Text>
              {isDisabled && (
              <Tag size="sm" w="fit-content" bg={"#0085FF"} color="white" fontWeight={"bold"} mb={1} ml={2}>
                Coming Soon
              </Tag>
              )}

              </HStack>
            </Hide>
            <Text
              color={isDisabled ? disabledTextColour : textColor}
              fontSize={{ base: "sm", sm: "sm", md: "sm", lg: "md" }}
              ml={3}
              align="left"
            >
              {description}
            </Text>
          </Box>
        </HStack>
      </Box>
    </motion.div>
  );
}

export default CapabilityCard;
