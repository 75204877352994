import {
    Box,
    Divider,
    Grid,
    GridItem,
    HStack,
    Heading,
    Image,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Tag,
    Text,
    VStack,
    Button
} from "@chakra-ui/react";
import React from "react";

import axios from "axios";
import { useEffect, useState } from "react";
import { getIdToken } from "../../../cognito/cognitoAuth";

import { CloseIcon } from "@chakra-ui/icons";

import PortfolioForm from "./PortfolioForm";
import { set } from "date-fns";

const apiUrl = process.env.REACT_APP_API_URL;



const PortfolioItemModal = ({
    isOpen, onClose, selectedPortfolioItem
}) => {

    const [allServices, setAllServices] = useState([]);
    const [serviceIdToNameMapping, setServiceIdToNameMapping] = useState({});

    const [isEditing, setIsEditing] = useState(false);
    const [editingItem, setEditingItem] = useState(null);

    const [isDeleting, setIsDeleting] = useState(false);


    const handleEditClick = (item) => {
        setEditingItem(item);
        setIsEditing(true);
        onClose();
    };

    const handleUpdatePortfolioItem = (updatedPortfolioItem) => {
        // Implement this function
        console.log(updatedPortfolioItem);
    };

    useEffect(() => {
        const fetchServices = async () => {
            try {
                const IdToken = await getIdToken();
                const response = await axios.get(apiUrl + "/services", {
                    headers: {
                        Authorization: `Bearer ${IdToken}`,
                        "Content-Type": "application/json",
                    },
                });

                if (response.status === 200 && response.data.statusCode === 200) {
                    const servicesData = JSON.parse(response.data.body);
                    if (Array.isArray(servicesData)) {
                        setAllServices(servicesData);

                        // Create a mapping from service IDs to service names
                        const idToName = {};
                        servicesData.forEach(service => {
                            idToName[service.id] = service.name; // Assuming each service object has 'id' and 'name'
                        });
                        setServiceIdToNameMapping(idToName); // Set the mapping
                    } else {
                        throw new Error("Failed to fetch services.");
                    }
                } else {
                    throw new Error("Failed to fetch services.");
                }
            } catch (error) {
                console.error("Error fetching services", error);
            }
        };

        fetchServices();
    }, []);


    const handleDeletePortfolioItem = async () => {
        setIsDeleting(true);


        try {
            const IdToken = await getIdToken();

            console.log('Deleting portfolio item:', selectedPortfolioItem);
            console.log('portfolio_id', selectedPortfolioItem.portfolio_id);

            const response = await axios.post(apiUrl + "/provider/portfolio/delete", {
                portfolio_id: selectedPortfolioItem.portfolio_id
            }, {
                headers: {
                    Authorization: `Bearer ${IdToken}`,
                    "Content-Type": "application/json",
                }
            });

            if (response.status === 200 && response.data.statusCode === 200) {
                console.log('Successfully deleted portfolio item.');
            }


            // close the modal
            onClose();
            setIsDeleting(false);
            // refresh the page
            window.location.reload();



        } catch (error) {
            setIsDeleting(false);
            console.error('Error deleting portfolio item:', error);
        }
    };





    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                size="6xl"
                isCentered
                scrollBehavior="inside"
                margin={5}
            >
                <ModalOverlay />
                <ModalContent
                    background="oleniumBlack.700"
                    color="white"
                    borderRadius="3xl"
                    p={2}
                >
                    <ModalHeader mt={2}>
                        <HStack justifyContent="space-between" w="100%" alignItems="center" justifyItems={"center"}>
                            <Heading as="h3" size="lg" mb={2}>
                                {selectedPortfolioItem?.title}
                            </Heading>
                            <CloseIcon onClick={onClose} size="md" />
                        </HStack>
                    </ModalHeader>
                    <ModalBody overflowY="auto">
                        <Grid templateColumns={{ base: "1fr", md: "70% 30%" }} p={{ base: 2, md: 8 }} gap={{ base: 4, md: 8 }}>
                            <GridItem colSpan={1}>
                                <VStack
                                    spacing={{ base: 2, md: 4 }}
                                    mb={4}
                                    w="100%"
                                    alignItems="flex-start"
                                >

                                    {/* Cover Image */}
                                    {selectedPortfolioItem?.images.length > 0 && (
                                        <Box w="100%" borderRadius="xl" h="100%" overflow="hidden">
                                            <Image
                                                src={selectedPortfolioItem.images[0]}
                                                alt={selectedPortfolioItem?.title}
                                                objectFit="cover"
                                                w="100%"
                                                h="100%"
                                            />
                                        </Box>
                                    )}

                                    {/* Grid for the rest of the images */}
                                    <Box w="100%">
                                        <Grid
                                            templateColumns={{ base: "1fr", md: "1fr 1fr" }}
                                            gap={{ base: 2, md: 4 }}
                                        >
                                            {selectedPortfolioItem?.images.slice(1).map((image, index, array) => {
                                                const isLastItem = index === array.length - 1;
                                                const shouldTakeFullWidth = array.length === 1 || (array.length % 2 !== 0 && isLastItem);

                                                const imageHeight = isLastItem ? "100%" : "250px"

                                                return (
                                                    <Box
                                                        key={index}
                                                        borderRadius="xl"
                                                        h={{ base: "100%", md: { imageHeight } }}
                                                        overflow="hidden"
                                                        gridColumn={shouldTakeFullWidth ? "span 2" : "span 1"}
                                                    >
                                                        <Image
                                                            src={image}
                                                            alt={selectedPortfolioItem?.title}
                                                            objectFit="cover"
                                                            w="100%"
                                                            h="100%"
                                                        />
                                                    </Box>
                                                );
                                            })}
                                        </Grid>
                                    </Box>

                                </VStack>
                            </GridItem>
                            <GridItem colSpan={1}>
                                <Box position="sticky" top={0}>
                                    <Text fontSize="lg" fontWeight="bold" mb={2}>
                                        Details
                                    </Text>
                                    <Divider mb={2} />



                                    <Text fontSize="sm" fontWeight="normal" mb={2}>
                                        {selectedPortfolioItem?.description}
                                    </Text>

                                    <HStack alignItems="flex-start" spacing={2} mb={2} wrap={"wrap"}>
                                        {selectedPortfolioItem?.services &&
                                            selectedPortfolioItem?.services
                                                .filter(serviceId => serviceIdToNameMapping.hasOwnProperty(serviceId))
                                                .map((serviceId, index) => (
                                                    <Tag
                                                        size="md"
                                                        borderRadius="md"
                                                        px={2}
                                                        py={1}
                                                        background={"oleniumBlack.500"}
                                                        key={index}
                                                    >
                                                        {serviceIdToNameMapping[serviceId]}
                                                    </Tag>
                                                ))}
                                    </HStack>

                                    {selectedPortfolioItem?.additionalTags && (
                                        <HStack alignItems="flex-start" spacing={2} mb={2} wrap={"wrap"}>
                                            {selectedPortfolioItem?.additionalTags.map((tag, index) => (
                                                <Tag
                                                    size="md"
                                                    borderRadius="md"
                                                    px={2}
                                                    py={1}
                                                    background={"oleniumBlack.500"}
                                                    key={index}
                                                >
                                                    {tag}
                                                </Tag>
                                            ))}
                                        </HStack>
                                    )}

                                    {selectedPortfolioItem?.tags && (
                                        <HStack alignItems="flex-start" spacing={2} mb={4} wrap={"wrap"}>
                                            {selectedPortfolioItem?.tags.map((tag, index) => (
                                                <Tag
                                                    size="md"
                                                    borderRadius="md"
                                                    px={2}
                                                    py={1}
                                                    background={"oleniumBlack.500"}
                                                    key={index}
                                                >
                                                    {tag}
                                                </Tag>
                                            ))}
                                        </HStack>
                                    )}

                                    <Button
                                        variant="oleniumBlue"
                                        size="md"
                                        background={"oleniumBlack.500"}
                                        width={"100%"}
                                        onClick={() => handleEditClick(selectedPortfolioItem)}
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        variant="oleniumRed"
                                        size="md"
                                        width={"100%"}
                                        mt={2}
                                        onClick={handleDeletePortfolioItem}
                                        isLoading={isDeleting}
                                    >
                                        Delete
                                    </Button>
                                </Box>
                            </GridItem>
                        </Grid>
                    </ModalBody>
                </ModalContent>
            </Modal>
            {isEditing && (
                <PortfolioForm
                    isOpen={isEditing}
                    onClose={() => setIsEditing(false)}
                    initialValues={editingItem}
                    onSubmit={handleUpdatePortfolioItem} // Implement this function
                    services={allServices}
                    isEditMode={true}
                />
            )}
        </>
    );
}

export default PortfolioItemModal; 