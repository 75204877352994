import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Avatar,
  Badge,
  Box,
  Button,
  Center,
  CloseButton,
  Flex,
  HStack,
  Heading,
  Hide,
  IconButton,
  Input,
  InputGroup,
  InputRightAddon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Skeleton,
  SkeletonCircle,
  Spacer,
  Spinner,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  useColorModeValue,
  useDisclosure
} from "@chakra-ui/react";
import React, { useState } from "react";

import loadingAnimationData from "../../../assets/animations/Loading.json";

import { BsArrowRepeat, BsThreeDotsVertical, BsTrash } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import FileUpload from "../../Upload/GlobalFileUpload";

import axios from "axios";
import { useEffect } from "react";
import { getIdToken } from "../../../cognito/cognitoAuth";

import FileIcons from "./FileIcons";
import options from "./Options";

import { motion } from "framer-motion";

const assetTypes = [
  { value: "LOGO", label: "Logo" },
  { value: "IMAGE", label: "Image" },
  { value: "BRANDING_GUIDELINE", label: "Branding Guideline" },
  { value: "COLOUR_PALETTE", label: "Colour Palette" },
  { value: "TYPOGRAPHY", label: "Typography" },
  { value: "ICON_SET", label: "Icon Set" },
  { value: "MARKETING_MATERIAL", label: "Marketing Material" },
  { value: "PRESENTATION_TEMPLATE", label: "Presentation Template" },
  { value: "WEBSITE_DESIGN", label: "Website Design" },
  { value: "LETTERHEAD", label: "Letterhead" },
  { value: "BUSINESS_CARD", label: "Business Card" },
  { value: "ENVELOPE", label: "Envelope" },
  { value: "SIGNAGE", label: "Signage" },
  { value: "STICKER", label: "Sticker" },
  { value: "FLYER", label: "Flyer" },
  { value: "POSTER", label: "Poster" },
  { value: "SOCIAL_MEDIA_ASSET", label: "Social Media Asset" },
  { value: "EMAIL_SIGNATURE", label: "Email Signature" },
  { value: "BROCHURE", label: "Brochure" },
  { value: "CATALOG", label: "Catalog" },
  { value: "INFOGRAPHIC", label: "Infographic" },
  { value: "ANIMATION", label: "Animation" },
  { value: "NEWSLETTER", label: "Newsletter" },
  { value: "PRESS_KIT", label: "Press Kit" },
  { value: "PHOTOGRAPHY", label: "Photography" },
  { value: "VEHICLE_WRAP", label: "Vehicle Wrap" },
  { value: "APPAREL_DESIGN", label: "Apparel Design" },
  { value: "PACKAGING", label: "Packaging" },
  { value: "EXHIBITION_STANDS", label: "Exhibition Stands" },
  { value: "OTHER", label: "Other" },
];

const BrandAssets = () => {
  const colorMode = useColorModeValue("light", "dark");
  const [selectedOption, setSelectedOption] = useState(options[0].value);
  const [assets, setAssets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  var bgColor = useColorModeValue("white", "oleniumBlack.600");
  const [isModalClosed, setIsModalClosed] = useState(false);
  const [showAlert, setShowAlert] = useState(true);

  const API_URL = process.env.REACT_APP_API_URL;

  const fetchAssets = async () => {
    setIsLoading(true);
    const authentication = await getIdToken();
    try {
      const response = await fetch(`${API_URL}/assets`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authentication}`,
        },
      });
      const data = await response.json();

      console.log(data);

      if (data.statusCode === 200) {
        const assetsData = JSON.parse(data.body);
        if (Array.isArray(assetsData)) {
          // Fetch user details for each asset
          for (let i = 0; i < assetsData.length; i++) {
            const asset = assetsData[i];
            const userResponse = await fetch(
              `${API_URL}/users/info/${asset.author}`,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${authentication}`,
                },
              },
            );
            const userData = await userResponse.json();
            if (userData.statusCode === 200) {
              // Add user details to asset
              asset.author =
                userData.body.given_name + " " + userData.body.family_name;
              asset.author_avatar = userData.body.profile_picture_url;
            }
          }
          setAssets(assetsData);
          setIsLoading(false);
        } else {
          throw new Error("Failed to fetch projects.");
        }
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch projects.");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching projects:", error);
      setAssets([]);
    }
  };

  useEffect(() => {
    const alertState = localStorage.getItem("showBrandAssetsAlert");
    if (alertState !== null) {
      setShowAlert(JSON.parse(alertState));
    }
  }, []);

  // 3. Update local storage whenever showAlert changes
  useEffect(() => {
    localStorage.setItem("showBrandAssetsAlert", JSON.stringify(showAlert));
  }, [showAlert]);

  useEffect(() => {
    fetchAssets();
  }, []);

  const refetchAssets = async () => {
    // Add a slight delay (e.g., 500ms) before fetching assets again
    await new Promise((resolve) => setTimeout(resolve, 1000));
    fetchAssets();
  };

  const hasAssets = () => {
    return Array.isArray(assets) && assets.length > 0;
  };

  const onUploadModalCloseWithRefetch = () => {
    onUploadModalClose();
    setIsModalClosed((prevState) => !prevState); // Toggle the isModalClosed state
    refetchAssets();
  };

  const handleDelete = async (id) => {
    setIsDeleting(true);
    const IdToken = await getIdToken();

    try {
      const response = await axios.delete(`${API_URL}/assets/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${IdToken}`,
        },
      });
      if (response.status === 200) {
        setIsDeleting(false);
        fetchAssets();
      } else {
        setIsDeleting(false);
        throw new Error("Failed to delete the asset.");
      }
    } catch (error) {
      setIsDeleting(false);
      console.error("Error deleting the asset:", error);
    }
  };

  const [editedFile, setEditedFile] = useState({});

  const handleEdit = (asset_id) => {
    const assetToEdit = assets.find((asset) => asset.asset_id === asset_id);

    // Ensure assetToEdit exists and has a file_name property
    if (
      assetToEdit &&
      "file_name" in assetToEdit &&
      "file_extension" in assetToEdit
    ) {
      setEditedFile({
        ...assetToEdit,
        file_name: assetToEdit.file_name,
        file_extension: assetToEdit.file_extension,
      });
      onOpen();
    } else {
      console.error(
        `Asset with ID ${asset_id} not found or has no file_name property`,
      );
    }
  };

  const textColor = useColorModeValue("oleniumGray.700", "white");
  const boxColor = useColorModeValue("white", "oleniumBlack.600");
  const [filter, setFilter] = useState("");

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const {
    isOpen: isUploadModalOpen,
    onOpen: onUploadModalOpen,
    onClose: onUploadModalClose,
  } = useDisclosure();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const saveChanges = async () => {
    setIsSaving(true);
    const IdToken = await getIdToken();

    try {
      const response = await axios.put(
        `${API_URL}/assets/${editedFile.asset_id}`,
        editedFile,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${IdToken}`,
          },
        },
      );
      if (response.status === 200) {
        setIsSaving(false);
        fetchAssets();
        onClose();
      } else {
        setIsSaving(false);
        throw new Error("Failed to update the asset.");
      }
    } catch (error) {
      setIsSaving(false);
      console.error("Error updating the asset:", error);
    }
  };

  const fetchPresignedUrl = async (assetId) => {
    try {
      const token = await getIdToken(); // get the token
      const response = await fetch(`${API_URL}/assets/${assetId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) throw new Error("Error fetching pre-signed URL");
      const jsonResponse = await response.json();
      const url = jsonResponse.body;
      return url;
    } catch (error) {
      console.error(error);
      return "";
    }
  };

  const SkeletonText = () => {
    const variants = {
      hidden: { opacity: 0, scale: 0 },
      visible: (i) => ({
        opacity: 1,
        scale: 1,
        transition: {
          delay: i * 0.1,
        },
      }),
    };

    return (
      <Flex direction="column" mt={{ sm: "0px", lg: "0px" }} mb={17}>
        <Flex
          direction="column"
          w={{ sm: "100%", lg: "100%" }}
          h={{ sm: "100%", lg: "100%" }}
          justifyContent="center"
          alignItems="center"
          p={5}
          borderRadius="md"
          boxShadow="md"
          bg={boxColor}
        >
          <SkeletonCircle size="10" />
          <SkeletonText mt="4" noOfLines={4} spacing="4" />
        </Flex>
      </Flex>
    );
  };

  const loadingOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const convertFileSize = (bytes) => {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) {
      return "0 Byte";
    }
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    return `${Math.round(bytes / 1024 ** i, 2)} ${sizes[i]}`;
  };

  // Convert ISO date to a readable format e.g 2023-07-16T22:59:50.007721Z => Jul 16, 2023
  const convertIOSDate = (date) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  // convert PRESENTATION_TEMPLATE to Presentation Template using assetType const above
  function convertValueToLabel(value) {
    const foundAssetType = assetTypes.find((asset) => asset.value === value);
    return foundAssetType ? foundAssetType.label : "";
  }

  const convertDeliveryTypeToLabel = (value) => {
    if (
      value === "DRAFT_ASSET" ||
      value === "ITERATION_ASSET" ||
      value === "FINAL_ASSET"
    ) {
      return "Project deliverable";
    } else {
      return "General Asset";
    }
  };

  const handleViewProject = (projectId) => {
    var win = window.open(`/project/${projectId}`, "_blank");
    win.focus();
  };

  useEffect(() => {
    // set window scroll to top
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Flex justifyContent="space-between" mb={5}>
        <Heading as="h1" size="lg" w={"100%"} my={5}>
          Brand Assets
        </Heading>
        <Flex alignItems="center" justifyContent="flex-end" w={"100%"}>
          <IconButton
            onClick={fetchAssets}
            ml={3}
            icon={<BsArrowRepeat />}
            size={"sm"}
            rounded="full"
            mr={3}
          />
          <Button
            onClick={onUploadModalOpen}
            variant="oleniumBlue"
            size={"sm"}
            px={5}
          >
            Upload
          </Button>

          <Hide below="md">
            <Select
              width={{ sm: "100%", md: "200px", lg: "200px" }}
              onChange={handleFilterChange}
              mx={3}
              value={filter}
              rounded="xl"
              size={{ base: "sm", md: "sm", lg: "md" }}
            >
              <option value="" disabled>
                Filter
              </option>
              {assetTypes.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          </Hide>
        </Flex>
      </Flex>

      {isLoading ? (
        <Center h="50vh" w="100%">
          <VStack spacing={4}>
            <Spinner size="xl" />
          </VStack>
        </Center>
      ) : hasAssets() ? (
        <TableContainer
          as={motion.div}
          initial={{ opacity: 0, y: 10 }}
          animate={{
            opacity: 1,
            y: 0,
            transition: {
              duration: 0.5,
              delay: 0.5,
              ease: "easeInOut",
            },
          }}
          exit={{ opacity: 0, y: -10 }}
          height={{ base: "100vh", md: "100vh", lg: "100vh", xl: "100vh" }}
        >
          <Table variant="simple" style={{ overflow: 'visible' }}>
            <Thead>
              <Tr>
                <Th>Filename</Th>
                <Th>Size</Th>
                <Th>Upload date</Th>
                <Th>Category</Th>
                <Th>Delivery Type</Th>
                <Th>Author</Th>
                <Th isNumeric>Actions</Th>
              </Tr>
            </Thead>

            <Tbody>
              {isLoading ? (
                <>
                  <Tr>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                  </Tr>
                </>
              ) : Array.isArray(assets) && assets.length > 0 ? (
                assets
                  .filter((file) =>
                    filter === "" ? true : file.asset_type === filter,
                  )
                  .map((file, index) => (
                    <Tr
                      as={motion.tr}
                      key={file.id}
                      initial={{ opacity: 0, y: 10 }}
                      animate={{
                        opacity: 1,
                        y: 0,
                        transition: {
                          delay: index * 0.1,
                          duration: 1,
                          ease: "easeInOut",
                        },
                      }}
                      exit={{ opacity: 0, y: -10 }}
                    >
                      <Td>
                        <Flex alignItems="center" gap="2">
                          <FileIcons
                            file={file.file_name + "." + file.file_extension}
                            width={6}
                            height={6}
                            color={textColor}
                          />
                          <Text
                            ml={3}
                            fontSize="sm"
                            fontWeight="bold"
                            align={"left"}
                          >
                            {file.file_name + "." + file.file_extension}
                          </Text>
                        </Flex>
                      </Td>
                      <Td>
                        <Text
                          mt={1}
                          fontSize="sm"
                          fontWeight="bold"
                          align={"left"}
                        >
                          {convertFileSize(file.file_size)}
                        </Text>
                      </Td>
                      <Td>
                        <Text
                          mt={1}
                          fontSize="sm"
                          fontWeight="bold"
                          align={"left"}
                        >
                          {convertIOSDate(file.created_at)}
                        </Text>
                      </Td>
                      <Td>
                        <Tag size="sm" variant="outline" rounded={"full"}>
                          {convertValueToLabel(file.asset_type)}
                        </Tag>
                      </Td>
                      <Td>
                        <Tag size="sm" variant="outline" rounded={"full"}>
                          {convertDeliveryTypeToLabel(file.delivery_type)}
                        </Tag>
                      </Td>
                      <Td>
                        <Flex alignItems="center" gap="2">
                          <Avatar
                            size="xs"
                            name={file.author}
                            src={file.author_avatar}
                          />
                          <Text
                            ml={1}
                            fontSize="sm"
                            fontWeight="bold"
                            align={"left"}
                          >
                            {file.author}
                          </Text>
                        </Flex>
                      </Td>
                      <Td isNumeric zIndex={100}>
                        {/* {file.delivery_type !== null && (
                          <Button
                            mx={2}
                            size={"xs"}
                            // take file.project_id and redirect to project page
                            onClick={() => handleViewProject(file.project_id)}
                          >
                            View project
                          </Button>
                        )} */}

                        <Button
                          mx={2}
                          size={"xs"}
                          onClick={async () => {
                            const url = await fetchPresignedUrl(file.asset_id);
                            window.open(url, "_blank");
                          }}
                        >
                          Download
                        </Button>
                        <Menu zIndex={100}>
                          <MenuButton>
                            <IconButton
                              aria-label="Options"
                              icon={<BsThreeDotsVertical />}
                              size="xs"
                              variant="outline"
                              rounded="full"
                              mx={2}
                            />
                          </MenuButton>
                          <MenuList bg={boxColor} rounded="xl">
                            <MenuItem
                              icon={<FiEdit />}
                              bg={boxColor}
                              onClick={() => handleEdit(file.asset_id)}
                            >
                              Edit file
                            </MenuItem>
                            <MenuItem
                              icon={<BsTrash />}
                              color="red.500"
                              bg={boxColor}
                              onClick={() => handleDelete(file.asset_id)}
                              isLoading={isDeleting}
                            >
                              Delete file
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </Td>
                    </Tr>
                  ))
              ) : (
                <></>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{
            opacity: 1,
            y: 0,
            transition: {
              duration: 0.5,
              ease: "easeInOut",
            },
          }}
          exit={{ opacity: 0, y: -10 }}
        >
          <Box
            w="100%"
            h="80%"
            minH={"500px"}
            backgroundColor={bgColor}
            borderRadius="20px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box w="90%" h="80%" p="4" mx="auto" my="auto">
              <VStack align="stretch" h="100%">
                <VStack align="stretch">
                  <Badge
                    fontSize="xs"
                    mb="20px"
                    px={2}
                    py={1}
                    w={"fit-content"}
                    variant={"outline"}
                    rounded={"full"}
                    textTransform={"Capitalize"}
                  >
                    Effortless Asset Management
                  </Badge>
                  <Text
                    fontSize={{
                      base: "25px",
                      sm: "35px",
                      md: "45px",
                      lg: "60px",
                    }}
                    fontWeight="bold"
                    lineHeight={1.2}
                    maxW={"900px"}
                  >
                    Keep track of your assets and files all in one place.
                  </Text>
                </VStack>
                <Spacer />
                <VStack align="stretch" spacing={10}>
                  <Text fontSize="md" maxW={"700px"}>
                    Discover the power of seamless brand consistency with our
                    Brand Assets feature. Effortlessly manage, store, and access
                    all your essential design elements in one convenient
                    location. Easily share your visual assets, and maintain a
                    cohesive brand identity with every project.
                  </Text>
                  <Button
                    variant={"oleniumBlue"}
                    maxW={"200px"}
                    onClick={() => {
                      onUploadModalOpen();
                    }}
                  >
                    Upload Assets
                  </Button>
                </VStack>
              </VStack>
            </Box>
          </Box>
        </motion.div>
      )}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          bg={boxColor}
          color={textColor}
          rounded="xl"
          boxShadow="xl"
        >
          <ModalHeader>Edit File</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box mb={4}>
              <Text mb={2}>Filename</Text>
              <InputGroup size="md" rounded={"xl"}>
                <Input
                  placeholder="Filename"
                  value={editedFile ? editedFile.file_name : ""}
                  onChange={(e) =>
                    setEditedFile({ ...editedFile, file_name: e.target.value })
                  }
                />
                <InputRightAddon
                  children={
                    editedFile && editedFile.file_extension
                      ? "." + editedFile.file_extension
                      : ""
                  }
                />
              </InputGroup>
            </Box>
            <Box>
              <Text mb={2}>Asset Type</Text>
              <Select
                size="md"
                rounded="md"
                value={editedFile ? editedFile.asset_type : assetTypes[0].value}
                onChange={(e) =>
                  setEditedFile({ ...editedFile, asset_type: e.target.value })
                }
              >
                {assetTypes.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
            </Box>
          </ModalBody>
          <ModalFooter justifyContent="space-between">
            <Button onClick={onClose}>Cancel</Button>
            <Button
              variant="oleniumBlue"
              onClick={() => saveChanges(editedFile.asset_id)}
              isLoading={isSaving}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isUploadModalOpen}
        onClose={onUploadModalCloseWithRefetch}
        size="2xl"
        isCentered
      >
        <ModalOverlay />
        <ModalContent
          bg={boxColor}
          color={textColor}
          rounded="3xl"
          boxShadow="xl"
          p={{ base: 2, md: 4 }}
          m={5}
        >
          <ModalHeader>Upload Brand Assets</ModalHeader>
          <ModalCloseButton m={4} />
          <ModalBody pb={6}>
            {showAlert && (
              <Alert status="info" mb={10} mt={2} rounded="lg">
                <VStack spacing={2}>
                  <HStack w={"100%"}>
                    <AlertIcon mr={0} height={4} width={4} />
                    <Spacer />
                    <AlertTitle w={"100%"}>
                      <Text fontSize="md" fontWeight="bold">
                        Important Notice
                      </Text>
                    </AlertTitle>
                  </HStack>
                  <AlertDescription fontSize={{ base: "xs", md: "sm" }} lineHeight={1.5}>
                    All assets uploaded to our site will be shared with
                    providers that you assign to your project so they can
                    implement them in your projects.
                  </AlertDescription>
                  <CloseButton
                    position="absolute"
                    right="8px"
                    top="8px"
                    onClick={() => setShowAlert(false)}
                  />
                </VStack>
              </Alert>
            )}
            <FileUpload onClose={onUploadModalClose} />
            {!showAlert && (
              <Text
                fontSize="xs"
                color="oleniumGray.500"
                mt={4}
                textAlign={"center"}
              >
                All files uploaded will be shared with providers that you assign
                to your projects.
              </Text>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default BrandAssets;
