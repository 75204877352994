import {
  Box,
  Button,
  Icon,
  Input,
  VStack,
  useToast
} from "@chakra-ui/react";
import axios from "axios";
import React, { useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { BsUpload } from "react-icons/bs";
import { getIdToken } from "../../cognito/cognitoAuth";
import FileItem from "./FileItem";

const MAX_FILES = 10; // Set your maximum number of files here
// max file size 1gb
const MAX_FILE_SIZE = 1000000000;

const FileUpload = ({ onClose, onUploadSuccess }) => {
  const [isDragActive, setIsDragActive] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [numUploaded, setNumUploaded] = useState();
  const [uploadingFiles, setUploadingFiles] = useState([]);
  const [isAssetTypeSelected, setIsAssetTypeSelected] = useState(false);

  const handleAssetTypeChange = () => {
    setIsAssetTypeSelected(
      uploadedFiles.every((file) => file.assetType && file.assetType !== ""),
    );
  };

  const toast = useToast(); // Chakra UI's toast for notifications

  const hasFiles = uploadedFiles.length > 0;
  const totalFiles = filesToUpload.length;

  const API_URL = process.env.REACT_APP_API_URL;

  const handleUpload = () => {
    filesToUpload.forEach((file) => {
      if (file.size > MAX_FILE_SIZE) {
        toast({
          title: "File size error.",
          description: "File size exceeds the maximum limit.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else {
        uploadFile(file);
      }
    });
  };

  const getSignedUrl = async (file) => {
    const IdToken = await getIdToken();
    const response = await axios.post(
      `${API_URL}/assets/global`,
      {
        file_name: file.name,
        content_type: file.type,
        delivery_type: "NON_DELIVERABLE",
        asset_type: file.assetType,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${IdToken}`,
        },
      },
    );

    return response; // return the whole response
  };

  const uploadFile = async (file) => {
    try {
      setUploadingFiles((prevFiles) => [...prevFiles, file]);
      const signedUrlResponse = await getSignedUrl(file);

      if (
        signedUrlResponse.status !== 200 ||
        !signedUrlResponse.data.body.startsWith("http")
      ) {
        throw new Error(`Invalid signed URL: ${signedUrlResponse.data.body}`);
      }

      const signedUrl = signedUrlResponse.data.body;

      const uploadResponse = await axios.put(signedUrl, file, {
        headers: {
          "Content-Type": file.type,
        },
        onUploadProgress: (progressEvent) => {
          const progressPercentage = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          );
          updateFileProgress(file, progressPercentage);
        },
        withCredentials: false,
        responseType: "text",
      });

      if (uploadResponse.status !== 200) {
        throw new Error("File upload to S3 failed");
      }

      console.log("File uploaded successfully:", uploadResponse.data);
      setFilesToUpload((prevFiles) => prevFiles.filter((f) => f !== file));
      setUploadingFiles((prevFiles) => prevFiles.filter((f) => f !== file));
      setNumUploaded((prevNum) => {
        const updatedNum = prevNum + 1;
        return updatedNum;
      });
    } catch (error) {
      console.error("File upload failed:", error);
      toast({
        title: "File upload failed",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const updateFileProgress = (file, progressPercentage) => {
    setUploadedFiles((prevFiles) => {
      return prevFiles.map((f) => {
        if (f === file) {
          return { ...f, progressPercentage };
        }
        return f;
      });
    });
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: true,
    maxSize: MAX_FILE_SIZE, // Restrict file size
    maxFiles: MAX_FILES, // Restrict total number of files
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > MAX_FILES) {
        toast({
          title: "File count error.",
          description: `Maximum of ${MAX_FILES} files can be uploaded at once.`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      // Check for filenames with additional "." in them
      for (let file of acceptedFiles) {
        const splitName = file.name.split(".");
        if (
          splitName.length > 2 ||
          (splitName.length === 2 && splitName[0] === "")
        ) {
          toast({
            title: "File name error.",
            description: "Filenames with more than one period are not allowed.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          return;
        }
      }

      setFilesToUpload([...filesToUpload, ...acceptedFiles]);
      setUploadedFiles([...uploadedFiles, ...acceptedFiles]);
    },
    onDropRejected: () => {
      toast({
        title: "File rejected.",
        description: "The file you attempted to upload was rejected.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
    onDragEnter: () => setIsDragActive(true),
    onDragLeave: () => setIsDragActive(false),
    onDropAccepted: () => setIsDragActive(false),
  });

  const handleDelete = (fileToDelete) => {
    setUploadedFiles(uploadedFiles.filter((file) => file !== fileToDelete));
    handleAssetTypeChange();
  };

  // sets the file type for the upload from the dropdown
  const handleSelectType = (file, assetType) => {
    setUploadedFiles((prevFiles) => {
      return prevFiles.map((f) => {
        if (f === file) {
          return { ...f, assetType };
        }
        return f;
      });
    });
  };

  const handleUploadAndClose = () => {
    handleUpload();
    if (numUploaded === totalFiles) {
      onClose();
    }
  };

  const fileItems = useMemo(
    () =>
      uploadedFiles.map((file, index) => {
        let status;
        if (uploadingFiles.includes(file)) {
          status = "uploading";
        } else if (filesToUpload.includes(file)) {
          status = "selected";
        } else {
          status = "uploaded";
        }

        return (
          <FileItem
            key={index}
            file={file}
            onDelete={handleDelete}
            progressPercentage={file.progressPercentage || 0}
            status={status}
            type={handleSelectType}
            handleAssetTypeChange={handleAssetTypeChange} // passing down the function
          />
        );
      }),
    [uploadedFiles, uploadingFiles, filesToUpload], // Add all dependencies here
  );

  return (
    <>
      <Box
        {...getRootProps({
          onClick: hasFiles ? (e) => e.stopPropagation() : undefined,
        })}
        h="150px"
        borderWidth="2px"
        borderStyle="dashed"
        borderColor={isDragActive ? "#0085FF" : "gray.400"}
        backgroundColor={isDragActive ? "#0085FF0F" : ""}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        borderRadius="20px"
        width="100%"
        _hover={{
          borderColor: isDragActive ? "oleniumBlue.100" : "gray.500",
        }}
        p={4}
      >
        <Icon as={BsUpload} w={8} h={8} color="oleniumGray.600" />
        <Button onClick={open} my={4} size="sm" variant="link" maxW={"200px"}>
          Select files to upload or drag here
        </Button>
        <Input {...getInputProps()} />
      </Box>
      <Box w="100%" display="flex" justifyContent="center">
        {hasFiles && (
          <VStack spacing={2} w="100%" align="stretch" mt={4}>
            {fileItems}
          </VStack>
        )}
      </Box>
      {hasFiles && (
        <Button
          onClick={handleUploadAndClose}
          my={4}
          size="md"
          variant="oleniumBlue"
          isDisabled={filesToUpload.length === 0 || !isAssetTypeSelected}
          w="100%"
          mt={8}
        >
          Upload
        </Button>
      )}
    </>
  );
};

export default FileUpload;
