import {
  Alert,
  AlertIcon,
  Box,
  Button,
  GridItem,
  Grid,
  Heading,
  Image,
  Text,
  VStack,
  useDisclosure,
  Flex,
  ButtonGroup,
  TagLabel,
  Tooltip,
} from "@chakra-ui/react";

import axios from "axios";
import { getIdToken } from "../../../../cognito/cognitoAuth";

import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import PortfolioModal from "../../Portfolio/PortfolioForm";

import InstagramImportModal from "../../Portfolio/InstagramModal";
import PortfolioItemModal from "../../Portfolio/PortfolioItemModal";

// Portfolio component
const Portfolio = ({
  updatePortfolioItemsInParent,
  portfolioItems,
  setPortfolioItems,
  editingItem,
  setEditingItem,
  placeholders,
  setPlaceholders,
  services,
  setServices,
  setFormValidPortfolio,
}) => {
  const [error, setError] = useState(null);
  const [projects, setProjects] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [loading, setLoading] = useState(true);

  const [alert, setAlert] = useState({
    show: false,
    status: "success",
    message: "",
  });

  useEffect(() => {
    const placeholders = new Array(Math.max(0, 3 - portfolioItems.length)).fill(0);
    setPlaceholders(placeholders);
  }, [portfolioItems]);


  useEffect(() => {
    // setFormValidPortfolio(true) if there are at least 3 projects

    if (projects.length >= 3) {
      setFormValidPortfolio(true);
    } else {
      setFormValidPortfolio(false);
    }
  }, [projects]);

  const [isPortfolioModalOpen, setIsPortfolioModalOpen] = useState(false);
  const openPortfolioModal = () => setIsPortfolioModalOpen(true);

  const [isInstagramModalOpen, setIsInstagramModalOpen] = useState(false);
  const openInstagramModal = () => setIsInstagramModalOpen(true);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const API_URL = process.env.REACT_APP_API_URL;

  const handlePortfolioSubmit = async (values) => {
    try {
      const token = await getIdToken();
      const response = await axios.post(
        `${API_URL}/portfolio`,
        {
          title: values.title,
          description: values.description,
          images: values.images,
          service_id: values.service_id,
        },
        {
          headers: {
            Authorization: token,
          },
        },
      );

      const parsedData = JSON.parse(response.data.body);
      setProjects([...projects, parsedData]);
      setAlert({
        show: true,
        status: "success",
        message: "Portfolio item created successfully.",
      });
    } catch (error) {
      console.error("Error creating portfolio item:", error);
      setAlert({
        show: true,
        status: "error",
        message: error.message,
      });
    } finally {
      setIsPortfolioModalOpen(false);
    }
  }

  const fetchProjects = async () => {
    setLoading(true);
    setError(null);

    try {
      const token = await getIdToken();
      const response = await axios.get(`${API_URL}/portfolio/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const portfolioItems = response.data;
      setProjects(portfolioItems);
    } catch (error) {
      console.error("Error fetching projects:", error);
      setError(error.message || 'Error fetching portfolio items');
    } finally {
      setLoading(false);
    }
  };



  useEffect(() => {
    fetchProjects();
  }, []);

  const handleItemClick = (item) => {
    console.log(item); // Add this line for debugging
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  return (
    <Box w="100%" maxW="800px" mx="auto" mb={8}>
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
      >
        <Heading as="h1" size="lg" mb={4} textAlign={"center"} maxW={"100%"}>
          Add your portfolio
        </Heading>
      </motion.div>
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.4 }}
      >
        <Text
          fontSize="md"
          mb={8}
          textAlign={"center"}
          maxW={"600px"}
          mx="auto"
        >
          Upload your portfolio projects to showcase your work to potential clients
          and to help us match you with the right projects. You can import from Instagram.
        </Text>
      </motion.div>

      <Flex justifyContent="center" alignItems="center" mb={8}>

        <ButtonGroup isAttached variant='outline'>
          <Button onClick={openPortfolioModal} size={{ base: "sm", md: "lg" }}>
            Add portfolio
          </Button>
          <Tooltip label="Coming soon" aria-label="Coming soon">
            <Button onClick={openInstagramModal} size={{ base: "sm", md: "lg" }} isDisabled={true}>
              Add from Instagram
            </Button>
          </Tooltip>
        </ButtonGroup>

      </Flex>

      <PortfolioItemModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        selectedPortfolioItem={selectedItem}
      />
      {isPortfolioModalOpen && (
        <PortfolioModal
          isOpen={isPortfolioModalOpen}
          onClose={() => setIsPortfolioModalOpen(false)}
          initialValues={{}} // Pass empty initialValues for new portfolio
          onSubmit={handlePortfolioSubmit} // You need to implement this function
          services={services}
          isEditMode={false}
        />
      )}
      <InstagramImportModal
        isOpen={isInstagramModalOpen}
        onClose={() => setIsInstagramModalOpen(false)}
      />

      <ProjectsGrid projects={projects} onItemClick={handleItemClick} />

      {error && (
        <Alert status="error" mt={4}>
          <AlertIcon />
          {error}
        </Alert>
      )}

      <Flex justifyContent="center" alignItems="center" mt={8} mb={8}>
        <Button size="sm" onClick={fetchProjects}>
          Refresh
        </Button>
      </Flex>
    </Box>

  );
};

const ProjectsGrid = ({ projects, onItemClick }) => {

  if (!Array.isArray(projects)) {
    // Optionally, handle this scenario differently, like showing an error message
    return null;
  }

  return (
    <Flex direction="column" mt={{ sm: "0px", lg: "0px" }} mb={17}>
      <Grid
        templateColumns={{
          sm: "1fr",
          md: "repeat(1, 1fr)",
          lg: "repeat(2, 1fr)",
          xl: "repeat(3, 1fr)",
        }}
        gap={8}
        mt={5}
      >
        {projects.map((project, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 50 }}
            animate={{
              opacity: 1,
              y: 0,
              transition: { delay: index * 0.1, duration: 0.5 },
            }}
            exit={{ opacity: 0, y: -50 }}
            onClick={() => onItemClick(project)}
          >
            <Box position="relative" borderRadius="xl" overflow="hidden" borderWidth="2px" borderColor="transparent" _hover={{ borderColor: "#0085FF" }}>
              <Image
                src={project.images[0]}
                alt="portfolio item"
                objectFit="cover"
                w={"100%"}
                h={"100%"}
                height={"180px"}
              />

              <Box
                position="absolute"
                top={0}
                left={0}
                w={"100%"}
                h={"100%"}
                bgGradient="linear(to-b, transparent, rgba(0, 0, 0, 0.5))"
                opacity="0"
                _hover={{ opacity: "1", cursor: "pointer" }}
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
              >
                <Text
                  fontSize="md"
                  color="white"
                  textAlign={"left"}
                  mb={4}
                  ml={4}
                  fontWeight="bold"
                >
                  {project.title}
                </Text>
              </Box>
            </Box>
          </motion.div>
        ))}
        {projects.length < 3 &&
          [...Array(3 - projects.length)].map((_, index) => (
            <GridItem
              key={`placeholder-${index}`}
              colSpan={1}
              rowSpan={1}
              display="flex"
              alignItems="center"
              justifyContent="center"
              border="2px dashed #ccc"
              borderRadius="xl"
              height="180px"
            >
              <Text color="white"> Project {projects.length + index + 1}</Text>
            </GridItem>
          ))
        }
      </Grid>
    </Flex>
  );
};


export default Portfolio;
