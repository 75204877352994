import {
  Alert,
  AlertIcon,
  AlertTitle,
  AspectRatio,
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Hide,
  Icon,
  IconButton,
  Image,
  Input,
  Spacer,
  Stack,
  Text,
  Textarea,
  useToast,
  Wrap,
  WrapItem,
  CheckboxGroup,
  Flex,
  Tag,
  TagLabel,
  TagRightIcon,
  Select,

} from "@chakra-ui/react";
import axios from "axios";
import { motion } from "framer-motion";
import React, { useContext, useEffect, useState } from "react";
import { BsFillFilePersonFill, BsUpload } from "react-icons/bs";
import { UserContext } from "../../../../UserContext";
import { getDetails, getIdToken } from "../../../../cognito/cognitoAuth";

import { BsCheckCircle, BsCircle } from "react-icons/bs";
import { set } from "date-fns";


const apiUrl = process.env.REACT_APP_API_URL;

const BasicProfile = ({
  onSubmit,
  setFormValidBasicProfile,
  setBio,
  setProfilePicture,
  setProviderType,
  providerType,
  bio,
  profilePicture,
  setPreviewImage,
  previewImage,
  selectedServices,
  setSelectedServices,
  services,
}) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [details, setDetails] = useState({});
  const [alert, setAlert] = useState(null);
  const { userDetails, setUserDetails } = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const [bioTouched, setBioTouched] = useState(false);
  const toast = useToast();

  useEffect(() => {
    // Validate form on each state change
    const isValid = bio && bio.length >= 150 && bio.length <= 500 && userDetails.profilePicture && selectedServices.length > 0;
    setFormValidBasicProfile(isValid);
  }, [bio, userDetails.profilePicture, selectedServices]);

  useEffect(() => {
    const get = async () => {
      const userDetails = await getDetails();
      setDetails(userDetails);
    };
    get();
  }, []);

  useEffect(() => {
    const storedBio = localStorage.getItem("bio");
    if (storedBio) {
      setBio(storedBio);
    }

    const storedProviderType = localStorage.getItem("providerType");
    if (storedProviderType) {
      setProviderType(storedProviderType);
    }

  }, []);

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];

      // Validate if the file is an image
      if (!file.type.startsWith('image/')) {
        toast({
          title: "Invalid file type.",
          description: "Please upload an image file.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        return;
      }

      setLoading(true);
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64data = reader.result;

        try {
          const token = await getIdToken();

          const body = {
            profile_picture: base64data,
          };

          const response = await axios.post(
            `${apiUrl}/users/profile-picture`,
            body,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            },
          );

          if (response.status === 200) {
            setLoading(false);
            setUserDetails({
              ...userDetails,
              profilePicture: response.data.profile_picture_url,
            });
            setPreviewImage(response.data.profile_picture_url);
            window.location.reload();

            toast({
              title: "Profile picture updated.",
              description: "Your profile picture has been successfully updated.",
              status: "success",
              duration: 9000,
              isClosable: true,
            });
          }
        } catch (e) {
          setLoading(false);
          console.error(e);

          toast({
            title: "An error occurred.",
            description: "Unable to update your profile picture.",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      };
      reader.readAsDataURL(file);
    }
  };


  const handleSubmit = async (event) => {
    event.preventDefault();

    // Check for all required fields
    if (!bio || bio.length < 50 || bio.length > 500 || !profilePicture || selectedServices.length === 0) {
      setAlert({
        title: "Validation Error",
        description: "Please ensure all fields are correctly filled, including at least one service selection.",
        status: "error",
      });
      return;
    }

    // Check for bio length
    if (bio.length < 50 || bio.length > 500) {
      setAlert({
        title: "Invalid bio",
        description: "The bio must be between 50 and 500 characters",
        status: "error",
      });
      return;
    }

    const IdToken = await getIdToken(); // Get the ID token here

    if (!profilePicture) {
      setAlert({
        title: "Missing profile picture or logo",
        description: "Please upload a profile picture or logo",
        status: "error",
      });
      return;
    }

    try {
      const formData = new FormData();
      formData.append("image", profilePicture);

      // Replace this URL with the actual API endpoint you want to call
      const response = await axios.post(`${API_URL}/face`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${IdToken}`, // Pass the ID
        },
      });

      if (response.data && response.data.message) {
        setAlert({
          title: "Error",
          description: response.data.message,
          status: "error",
        });
      } else {
        throw new Error("Invalid API response");
      }
    } catch (error) {
      setAlert({
        title: "Error",
        description: "An error occurred while uploading the image",
        status: "error",
      });
    }
    onSubmit({
      profilePicture,
      bio,
      providerType,
    });
  };

  const fileInputRef = React.useRef(null);


  useEffect(() => {
    // Load selected services from local storage when the component mounts
    const savedServices = localStorage.getItem("selectedServices");
    if (savedServices) {
      setSelectedServices(JSON.parse(savedServices));
    }
  }, []);

  useEffect(() => {
    // Save selected services to local storage whenever they change
    localStorage.setItem("selectedServices", JSON.stringify(selectedServices));
  }, [selectedServices]);

  const handleServiceChange = (serviceId, isChecked) => {
    if (isChecked) {
      setSelectedServices((prevServices) => [...prevServices, serviceId]);
    } else {
      setSelectedServices((prevServices) =>
        prevServices.filter((id) => id !== serviceId),
      );
    }
  };

  useEffect(() => {
    // Load selected services from local storage when the component mounts
    const savedServices = localStorage.getItem("selectedServices");
    if (savedServices) {
      // Filter out services that don't exist in the 'services' array
      const validSelectedServices = JSON.parse(savedServices).filter((serviceId) =>
        services.some((service) => service.id === serviceId)
      );
      setSelectedServices(validSelectedServices);
    }
  }, [services]); // Add 'services' as a dependency so the effect re-runs whenever 'services' change


  const fadeInUpVariants = (delay) => {
    return {
      initial: {
        opacity: 0,
        y: 50,
      },
      animate: {
        opacity: 1,
        y: 0,
        transition: {
          duration: 0.5,
          delay: delay,
        },
      },
    };
  };

  return (
    <Box w="100%" maxW={"600px"} mx="auto" alignContent={"center"}>
      <form onSubmit={handleSubmit}>
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
        >
          <Heading as="h1" size="lg" mb={4} textAlign={{ base: "center" }}>
            Hello, {details.given_name || "there"} 👋
          </Heading>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
        >
          <Text
            fontSize="md"
            mb={8}
            textAlign={"center"}
            maxW={"400px"}
            mx="auto"
          >
            Please tell us a bit more about yourself, this will help us find the
            relevant jobs for you on our platform.
          </Text>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.6 }}
        >
          <Stack spacing={4}>
            <Flex justifyContent="center">
              <Box
                border="2px"
                borderColor="gray.200"
                p={4}
                borderRadius="xl"
                borderStyle="dashed"
                w={{ base: "100%", md: "700px" }}
                maxWidth={"600px"}
                mb={4}
              >
                <HStack spacing={4}>
                  {previewImage || userDetails.profilePicture ? (
                    <AspectRatio ratio={1} w={12} h={12} borderRadius="lg">
                      <Image
                        src={previewImage || userDetails.profilePicture}
                        alt="Profile Picture"
                        rounded="lg"
                        objectFit="cover"
                        aspectRatio={1}
                      />
                    </AspectRatio>
                  ) : (
                    <Box
                      bg="oleniumBlack.300"
                      w={12}
                      h={12}
                      borderRadius="lg"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      aspectRatio={1}
                    >
                      <Icon as={BsFillFilePersonFill} w={6} h={6} color="white" />
                    </Box>
                  )}
                  {/* If no profile picture is selected, set text to "Browse" */}
                  <Text fontSize="md">{profilePicture ? "Change" : "Upload"}</Text>
                  <Spacer />
                  <Input
                    ref={fileInputRef}
                    type="file"
                    display="none"
                    onChange={handleImageChange}
                  />

                  <Hide below="md">
                    <Button
                      onClick={() => {
                        document.querySelector('input[type="file"]').click();
                      }}
                    >
                      Browse
                    </Button>
                  </Hide>

                  <Hide above="md">
                    <IconButton
                      icon={<Icon as={BsUpload} />}
                      onClick={() => {
                        if (fileInputRef.current) {
                          fileInputRef.current.click();
                        }
                      }}
                    />
                  </Hide>
                </HStack>
              </Box>
            </Flex>

            {alert && (
              <Alert status={alert.status} borderRadius="md" my={6}>
                <AlertIcon />
                <AlertTitle mr={2}>{alert.title}</AlertTitle>
                {alert.description}
              </Alert>
            )}

            <FormControl id="profileType" isRequired>
              <FormLabel>User Type</FormLabel>
              <Select
                placeholder="Select your profile type"
                onChange={(e) => {
                  setProviderType(e.target.value);
                  localStorage.setItem("profileType", e.target.value);
                }}
              >
                <option value="FREELANCER">Freelancer</option>
                <option value="AGENCY">Agency</option>
              </Select>
            </FormControl>

            <FormControl
              isRequired
              isInvalid={bioTouched && (bio.length < 50 || bio.length > 500)}
            >
              <FormLabel>Tell us about yourself</FormLabel>
              <Textarea
                value={bio}
                maxWidth={"600px"}
                mx={"auto"}
                onChange={(e) => {
                  setBio(e.target.value);
                  setBioTouched(true);
                  // Write the new bio to local storage whenever it is updated
                  localStorage.setItem("bio", e.target.value);
                }}
                placeholder="Enter your bio here (50-500 characters). Your bio will be used to match you with clients on our platform."
                height={200}
                rounded={"xl"}
                mb={4}
              />
              {bioTouched && bio.length < 50 && (
                <Text fontSize="sm" color="red.500">
                  The bio must be at least 50 characters
                </Text>
              )}

              {bioTouched && bio.length > 500 && (
                <Text fontSize="sm" color="red.500">
                  The bio must be no more than 500 characters
                </Text>
              )}
            </FormControl>

            {!loading && (
              <motion.Box
                variants={fadeInUpVariants(0.4)}
                initial="initial"
                animate="animate"
              >
                <motion.div
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.4 }}
                >
                  <Text
                    fontSize="md"
                    textAlign={"left"}
                  >
                    Please select the services you offer
                  </Text>
                </motion.div>
                <FormControl id="services">
                  <CheckboxGroup>
                    <Box
                      display="flex"
                      flexWrap="wrap"
                      justifyContent="center"
                      pt={4}
                    >
                      {services.map((service, index) => {
                        return (
                          <motion.div
                            key={service.id || index}
                            variants={fadeInUpVariants(0.2 * index)}
                          >

                            <ServiceBox
                              value={service.id}
                              label={service.name}
                              isChecked={selectedServices.includes(service.id)}
                              onServiceChange={(isChecked) =>
                                handleServiceChange(service.id, isChecked)
                              }
                            />
                          </motion.div>
                        );
                      })}
                    </Box>
                  </CheckboxGroup>
                </FormControl>
              </motion.Box>
            )}


          </Stack>
        </motion.div>
      </form>
    </Box >
  );
};


const ServiceBox = ({ label, isChecked, onServiceChange, ...rest }) => {
  const handleCheck = () => {
    const newCheckState = !isChecked;
    onServiceChange(newCheckState);
  };

  const fadeInVariants = {
    initial: {
      opacity: 0,
      y: 20,
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <motion.VStack
      variants={fadeInVariants}
      initial="initial"
      animate="animate"
      alignItems="center"
      justifyContent="center"
      mb={6}
    >
      <Wrap spacing={2}>
        <WrapItem>
          <Tag
            size={{ base: "sm", md: "sm", lg: "lg" }}
            variant={isChecked ? "solid" : "outline"}
            rounded="full"
            background={isChecked ? "blue.500" : ""}
            colorScheme={isChecked ? "blue" : "gray"}
            onClick={handleCheck}
            mr={3}
            mt={3}
          >
            <TagLabel>{label}</TagLabel>
            <TagRightIcon
              boxSize={{ base: "12px", md: "12px", lg: "16px" }}
              as={isChecked ? BsCheckCircle : BsCircle}
            />
          </Tag>
        </WrapItem>
      </Wrap>
    </motion.VStack>
  );
};


export default BasicProfile;
