import { Box, Button, Flex, HStack, Text, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

import { ArrowForwardIcon } from "@chakra-ui/icons";

const WorkAtOleniumCTA = () => {
  const boxColor = useColorModeValue("white", "oleniumBlack.600");

  const navigate = useNavigate();

  const handleLearnMoreClick = () => {
    navigate("/work-on-olenium");
    window.scrollTo(0, 0);
  };

  return (
    <Flex direction="column" align="center" justify="center" w="100%">
      <Box
        bg={boxColor}
        p={10}
        rounded="2xl"
        maxW={"100%"}
        w={"100%"}
        height={"300px"}
        shadow="lg"
        display="flex"
        alignItems="center"
      >
        <Box>
          <Text
            fontSize={{ base: "3xl", md: "4xl" }}
            align={"left"}
            fontWeight="900"
            lineHeight={"110%"}
            bgGradient="linear(to-r, purple.500, red.600)"
            backgroundClip="text"
            overflow="visible"
            maxW={"450px"}
          >
            Join Olenium as a freelancer or an agency
          </Text>
          <Text
            fontSize={"sm"}
            align={"left"}
            maxW={"450px"}
            fontWeight="400"
            mt={2}
          >
            Create your provider profile by adding your portfolio and services. We use machine learning to match you with the right clients.
          </Text>
          <HStack spacing={4} align="center" mt={4}>
            <Button
              rightIcon={<ArrowForwardIcon />}
              variant="oleniumBlue"
              mt={4}
              onClick={handleLearnMoreClick}
              size={"sm"}
            >
              Learn more
            </Button>
            <Button
              mt={4}
              onClick={() => window.open("https://calendar.app.google/rMvL3Ls2FXCvyKSq5")}
              size={"sm"}
            >
              Book a call
            </Button>
          </HStack>

        </Box>
      </Box>
    </Flex>
  );
};

export default WorkAtOleniumCTA;
