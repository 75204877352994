import {
  Avatar,
  Badge,
  Box,
  Button,
  ButtonGroup,
  Center,
  Divider,
  Flex,
  HStack,
  Heading,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useNavigate } from "react-router-dom";
import { getIdToken } from "../../../../cognito/cognitoAuth";

const API_URL = process.env.REACT_APP_API_URL;

const ProviderManager = () => {
  const navigate = useNavigate();

  const onBack = () => {
    navigate("/admin-panel");
  };

  const [applications, setApplications] = useState([]);
  const [selectedApplication, setSelectedApplication] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [actionLoading, setActionLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchApplications = async () => {
    try {
      setLoading(true);
      const authentication = await getIdToken();
      const response = await axios.get(`${API_URL}/provider/all`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authentication}`,
        },
      });
      if (response.data.statusCode === 200) {
        const parsedBody = JSON.parse(response.data.body);
        const parsedApplications = parsedBody.applications;

        if (Array.isArray(parsedApplications)) {
          const applicationsWithDetails = await Promise.all(
            parsedApplications.map(async (app) => {
              const userResponse = await fetch(
                `${API_URL}/users/info/${app.provider_id}`,
                {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authentication}`,
                  },
                },
              );

              const userData = await userResponse.json();
              if (userData.statusCode === 200) {
                app.given_name = userData.body.given_name;
                app.family_name = userData.body.family_name;
                app.profile_picture_url = userData.body.profile_picture_url;
              }
              return app;
            }),
          );
          setLoading(false);
          setApplications(applicationsWithDetails);
        }
      } else {
        setLoading(false);
        console.error("Error status code:", response.data.statusCode);
        setApplications([]);
      }
    } catch (error) {
      console.error("Error fetching applications:", error);
    }
    setLoading(false);
  };

  const viewApplication = (app) => {
    setSelectedApplication(app);
    onOpen();
  };

  const handleApprove = async (userId) => {
    try {
      setActionLoading(true);
      const authentication = await getIdToken();
      const response = await axios.post(
        `${API_URL}/provider/approve/${userId}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authentication}`,
          },
        },
      );
      if (response.data.statusCode === 200) {
        setActionLoading(false);
        fetchApplications();
      }
    } catch (error) {
      console.error("Error approving application:", error);
    }
    setActionLoading(false);
  };

  const handleReject = async (userId) => {
    try {
      setActionLoading(true);
      const authentication = await getIdToken();
      const response = await axios.post(
        `${API_URL}/provider/reject/${userId}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authentication}`,
          },
        },
      );
      if (response.data.statusCode === 200) {
        setActionLoading(false);
        fetchApplications();
      }
    } catch (error) {
      console.error("Error rejecting application:", error);
    }
    setActionLoading(false);
  };

  useEffect(() => {
    fetchApplications();
  }, []);

  const convertTimeToDaysOld = (time) => {
    const now = new Date();
    const created = new Date(time);
    const diff = now.getTime() - created.getTime();
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));

    if (days === 0) {
      return "Today";
    }
    if (days === 1) {
      return "Yesterday";
    }

    return days + " days ago";
  };

  const renderStatus = (status) => {
    switch (status) {
      case "APPROVED":
        return <Tag colorScheme="green">Approved</Tag>;
      case "REJECTED":
        return <Tag colorScheme="red">Rejected</Tag>;
      case "PENDING":
        return <Tag colorScheme="yellow">Pending</Tag>;
      default:
        return <Tag colorScheme="yellow">Unknown</Tag>;
    }
  };

  return (
    <Box>
      <Flex justifyContent="flex-start" mb={10}>
        <IconButton aria-label="Back" icon={<FaArrowLeft />} onClick={onBack} />
        <Heading as="h1" size="lg" ml={5} mt={1}>
          Provider Applications
        </Heading>
      </Flex>
      {!loading ? (
        <Table w={"100%"} variant="simple">
          <Thead>
            <Tr>
              <Th>User</Th>
              <Th>Submitted At</Th>
              <Th>Status</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody w={"100%"}>
            {applications.map((app) => (
              <Tr key={app.provider_id}>
                <Td>
                  <HStack spacing={2}>
                    <Avatar
                      size="xs"
                      name={app.given_name + " " + app.family_name}
                      src={app.profile_picture_url}
                    />
                    <Text ml={1} fontSize="sm" fontWeight="bold" align={"left"}>
                      {app.given_name + " " + app.family_name}
                    </Text>
                  </HStack>
                </Td>
                <Td>{convertTimeToDaysOld(app.created_at)}</Td>
                <Td>{renderStatus(app.status)}</Td>
                <Td>
                  <HStack spacing={6}>
                    <ButtonGroup isAttached variant="outline">
                      <Button
                        colorScheme="green"
                        variant="outline"
                        onClick={() => handleApprove(app.provider_id)}
                        isDisabled={app.status === "APPROVED"} // Disable if already approved
                        isLoading={actionLoading}
                      >
                        Approve
                      </Button>
                      <Button
                        colorScheme="red"
                        variant="outline"
                        onClick={() => handleReject(app.provider_id)}
                        isDisabled={app.status === "REJECTED"} // Disable if already rejected
                        isLoading={actionLoading}
                      >
                        Reject
                      </Button>
                    </ButtonGroup>
                    <Button
                      variant="oleniumBlue"
                      onClick={() => viewApplication(app)}
                    >
                      View
                    </Button>
                  </HStack>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      ) : (
        <Center>
          <Spinner size={"xl"} />
        </Center>
      )}
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent background="oleniumBlack.600" color="white">
          <ModalHeader>Application Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedApplication && (
              <VStack spacing={4} mb={4} w="100%" alignItems="flex-start">
                <Text fontWeight="bold">Created At:</Text>
                <Text>{selectedApplication.created_at}</Text>

               

                <Text fontWeight="bold">Bio:</Text>
                <Text>{selectedApplication.bio}</Text>
              </VStack>
            )}

            <Box pl={4}>
              <VStack spacing={4} mb={4} w="100%">
                {selectedApplication?.portfolio.items.map((item, index) => (
                  <PortfolioItem key={index} item={item} />
                ))}
              </VStack>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

const PortfolioItem = ({ item }) => {
  return (
    <VStack alignItems="center" m={2}>
      <Box
        h="100%"
        w="100%"
        maxW="500px"
        alignItems="center"
        p={6}
        border="1px"
        borderColor="gray.200"
        borderRadius="3xl"
        overflow="hidden"
      >
        <VStack w="100%" alignItems="center">
          <Carousel
            showArrows={true}
            showStatus={false}
            infiniteLoop={true}
            swipeable={true}
            emulateTouch={true}
          >
            {item.images.map((image, index) => (
              <Image
                src={image}
                alt="portfolio item"
                objectFit="cover"
                w={"100%"}
                h={"100%"}
                borderRadius="xl"
                transition="opacity 0.5s ease-in-out"
                zIndex={1}
                borderColor="transparent"
                border="2px"
                _hover={{
                  opacity: 1,
                  borderColor: "oleniumBlue.500",
                }}
              />
            ))}

            <Text fontSize="sm" color="oleniumGray.700">
              Bio
            </Text>
            <Text fontSize="sm" color="oleniumGray.700">
              {item.bio}
            </Text>




          </Carousel>
        </VStack>
        <HStack spacing={4} mb={4} justify="space-between" w="100%" align="top">
          <Text
            fontSize={{ base: "md", md: "lg" }}
            textAlign={"left"}
            fontWeight="bold"
          >
            {item.title}
          </Text>
        </HStack>
        <Divider mb={4} />
        <VStack spacing={2} mb={4} w="100%">
          <Text
            fontSize={{ base: "sm", md: "md" }}
            textAlign={"left"}
            color="oleniumGray.700"
          >
            {item.description}
          </Text>
        </VStack>
        {item.tags.map((tag, index) => (
          <Badge
            key={index}
            variant="oleniumBlue"
            mr={2}
            textTransform={"none"}
            py={1}
            px={3}
            borderRadius="full"
          >
            {tag}
          </Badge>
        ))}
      </Box>
    </VStack>
  );
};

export default ProviderManager;
