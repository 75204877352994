import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  GridItem,
  HStack,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  useBreakpointValue,
  useColorModeValue
} from "@chakra-ui/react";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../../../UserContext";
import loadingAnimationData from "../../../assets/animations/NewProjectLoading.json";
import animationData from "../../../assets/animations/unassigned.json";
import backgroundImage from "../../../assets/images/payment-background.jpg";
import { getIdToken } from "../../../cognito/cognitoAuth";
import OleniumLogoAnimated from "../../../components/OleniumLogoAnimated";
import AcceptProject from "./Widgets/AcceptProject";
import AddDelivery from "./Widgets/AddDelivery";
import ClientPayments from "./Widgets/ClientPayments";
import Payments from "./Widgets/Payments";
import ProjectAssets from "./Widgets/ProjectAssets";
import ProjectInformation from "./Widgets/ProjectInformation";
import TeamChat from "./Widgets/TeamChat";


import { loadStripe } from "@stripe/stripe-js";

import { BiSolidCheckCircle } from "react-icons/bi";

const API_URL = process.env.REACT_APP_API_URL;
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const ProjectDetail = () => {
  let { projectId } = useParams();
  const navigate = useNavigate();
  const [isStartFeeModalOpen, setIsStartFeeModalOpen] = useState(false);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const [isPaymentSuccessModalOpen, setIsPaymentSuccessModalOpen] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const expectedPath = `/project/${projectId}/payment_success`;
    if (location.pathname === expectedPath) {
      setIsPaymentSuccessModalOpen(true);
    }
  }, [location.pathname, projectId]);

  const closePaymentSuccessModal = () => {
    setIsPaymentSuccessModalOpen(false);
    navigate(`/project/${projectId}`);
    window.location.reload();
  };

  const [isLoading, setIsLoading] = useState(true);
  const [project, setProject] = useState({
    project: {},
    assets: [],
  });

  useEffect(() => {
    const userId = userDetails.id;
    if (
      project.project.start_fee_paid === false &&
      userId === project.project.user_id
    ) {
      setIsStartFeeModalOpen(true);
    }
  }, [project]);

  const { userDetails } = useContext(UserContext);

  const loadingOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const fetchProject = async () => {
    const authentication = await getIdToken();
    try {
      const response = await axios.get(`${API_URL}/projects/${projectId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authentication}`,
        },
      });

      if (response.data.statusCode === 200) {
        const projectData = JSON.parse(response.data.body);
        setProject({
          project: projectData.project,
          assets: projectData.assets,
        });
        setIsLoading(false);
      } else {
        throw new Error("Failed to fetch project details.");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching project details:", error);
      setProject({
        project: {}, //empty object instead of null
        assets: [],
      });
    }
  };

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

  const handlePayClick = async () => {
    setIsPaymentLoading(true);
    try {
      const authentication = await getIdToken();
      // post to /projects/{id}/start-payment
      const response = await axios.post(
        `${API_URL}/projects/${projectId}/start-payment`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authentication}`,
          },
        },
      );

      const responseBody = JSON.parse(response.data.body); // Parse the body property
      const stripeSessionId = responseBody.id; // Extract the session ID

      const stripe = await stripePromise;

      // Redirect to Stripe Checkout using the session ID
      stripe.redirectToCheckout({ sessionId: stripeSessionId });
    } catch (error) {
      console.error("Error starting payment:", error);
      // Handle error
    }
    setIsPaymentLoading(false);
  };

  useEffect(() => {
    fetchProject();
  }, [API_URL, projectId]);

  const bgColour = useColorModeValue("white", "oleniumBlack.600");

  const direction = useBreakpointValue({ base: "column", md: "row" });
  const [isAnimationStopped, setIsAnimationStopped] = useState(true);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [tabIndex, setTabIndex] = useState(0);

  // const openSidekickTab = () => {
  //   setTabIndex(1); // Set tab index to 1 (Sidekick tab)
  // };

  const checkIfUserIsProvider = () => {
    if (project.project.provider_id === userDetails.id) {
      return true;
    } else {
      return false;
    }
  };

  const checkIfUserIsClient = () => {
    if (project.project.user_id === userDetails.id) {
      return true;
    } else {
      return false;
    }
  };

  const handlePaymentSuccess = () => {
    // refetch project
    setIsLoading(true);
    fetchProject();
  };

  return (
    <Box>
      {isLoading ? (
        <Center h="100vh">
          <VStack spacing={4}>
            <Spinner size={"xl"} />
          </VStack>
        </Center>
      ) : (
        <Flex direction="column" m={0} p={0} w={"100%"}>
          <VStack
            align="start"
            justify={"center"}
            spacing={6}
          >
            <Heading as="h1" size="lg" mb={2} mt={5} ml={3}>
              {project.project.name}
            </Heading>
          </VStack>

          <Grid
            templateColumns={{ sm: "1fr", md: "1fr", lg: "3fr 1fr" }}
            templateRows="auto 1fr"
            mt="24px"
            gap="24px"
          >
            <GridItem >
              <Box
                mb="24px"
                borderRadius="15px"
                bg={bgColour}
                p={{ base: "0px", md: "24px" }}
                pt={{ base: "10px", md: "24px" }}
              >
                <Tabs
                  colorScheme="white"
                  index={tabIndex}
                  onChange={setTabIndex}
                  maxW={"100%"}
                >
                  <TabList px={{ base: "10px", md: "0px" }} pt={{ base: "10px", md: "0px" }}>
                    <Tab>Team Chat</Tab>
                    <Tab>Assets</Tab>
                    {project.project.status === "IN_PROGRESS" && (
                      <Tab>Payments</Tab>
                    )}
                  </TabList>

                  <TabPanels px={{ base: "10px", md: "0px" }} pt={{ base: "10px", md: "0px" }}>
                    <TabPanel maxW={"100%"}>
                      {isLoading ? (
                        <Spinner />
                      ) : project.project.status === "AWAITING_ASSIGNMENT" ? (
                        <Box>
                          <VStack
                            align="start"
                            justify={"center"}
                            spacing={6}
                            my={16}
                          >
                            <Text
                              fontSize={"4xl"}
                              align={"left"}
                              fontWeight="900"
                              lineHeight={1.2}
                              bgGradient="linear(to-r, orange.500, yellow.600)"
                              backgroundClip="text"
                              overflow="visible"
                            >
                              {userDetails.id === project.project.user_id ? (
                                <>
                                  We're reviewing your project <br /> and
                                  assigning a team
                                </>
                              ) : (
                                <>
                                  We're in the process of assigning <br />a new
                                  provider to this project
                                </>
                              )}
                            </Text>
                            <Text
                              fontSize={"sm"}
                              align={"left"}
                              maxW={"500px"}
                              fontWeight="400"
                              mt={4}
                            >
                              {userDetails.id === project.project.user_id ? (
                                <>
                                  Your project is currently being reviewed by
                                  our team. We'll be in touch soon with an update
                                  on the status of the project. If you'd like to
                                  cancel the project, please contact us at{" "}
                                  <a href="mailto:support@olenium.co.uk">
                                    Sales@olenium.co.uk
                                  </a>
                    
                                </>
                              ) : (
                                <>
                                  This project is currently in the process of
                                  being assigned to a new provider. We'll be in
                                  touch soon with an update on the status of the
                                  project. If you rejected the project, it will
                                  be assigned to a new provider and you will be
                                  notified.
                                </>
                              )}
                            </Text>
                          </VStack>
                        </Box>
                      ) : (
                        <TeamChat
                          project_id={project.project.id}
                          userId={userDetails.id}
                        />
                      )}
                    </TabPanel>
                    <TabPanel maxW={{ base: "calc(100vw - 50px)", md: "calc(100vw - 60px)" }} >
                      <ProjectAssets
                        project_id={project.project.id}
                        user_id={userDetails.id}
                        is_provider={checkIfUserIsProvider()}
                      />
                    </TabPanel>
                    {project.project.status !== "AWAITING_ASSIGNMENT" && (
                      <TabPanel maxW={{ base: "calc(100vw - 50px)", md: "calc(100vw - 60px)" }} >
                        <ClientPayments
                          project_id={project.project.id}
                          user_id={project.project.user_id}
                          is_provider={
                            project.project.provider_id === userDetails.id
                          }
                        />
                      </TabPanel>
                    )}
                  </TabPanels>
                </Tabs>
              </Box>
            </GridItem>

            <GridItem >
              <ProjectInformation
                link="http://olenium.co.uk/websites"
                status={project?.project.status}
                capability={project?.project.service_name}
                deadline={project?.project.deadline}
                description={project?.project.brief}
                provider_id={project?.project.provider_id}
                isLoading={isLoading}
              />

              {checkIfUserIsProvider() === true &&
                project.project.status === "IN_PROGRESS" && (
                  <>
                    <AddDelivery
                      project_id={project?.project.id}
                      client_id={project?.project.user_id}
                    />

                    <Payments
                      project_id={project?.project.id}
                      user_id={project?.project.user_id}
                    />
                  </>
                )}

              {checkIfUserIsProvider() === true &&
                project.project.status === "AWAITING_ACCEPTANCE" && (
                  <>
                    <AcceptProject
                      project_id={project?.project.id}
                      user_id={project?.project.user_id}
                    />
                  </>
                )}
            </GridItem>
          </Grid>
        </Flex>
      )}
      <Modal
        isOpen={isStartFeeModalOpen}
        isClosable={false}
        onClose={() => { }}
        size="md"
        isCentered
      >
        <ModalOverlay />
        <ModalContent
          bgImage={`url(${backgroundImage})`}
          bgSize="cover"
          bgPos="center"
          borderRadius="10px"
          p={10}
          margin={5}
        >
          <ModalHeader>
            <ModalCloseButton onClick={() => navigate("/")} />
          </ModalHeader>
          <ModalBody>
            <OleniumLogoAnimated />
            <Text fontSize="md" mt={3} fontWeight="bold">
              Start Project Fee
            </Text>
            <HStack alignItems="baseline" spacing={0}>
              <Text fontSize="7xl" fontWeight="light" mb={4}>
                £9
              </Text>
              <Text
                fontSize="3xl"
                fontWeight="regular"
                mt={2}
                color={"whiteAlpha.600"}
              >
                .99
              </Text>
            </HStack>
            <HStack>
              <Icon as={BiSolidCheckCircle} color="#00b36e" w={6} h={6} />
              <Heading as="h3" size="md" color="white">
                Fully refundable
              </Heading>
            </HStack>
            <Text fontSize="md" my={2}>
              This fee is fully refundable if you decide to cancel the project
              before your provider delivers the first milestone.
            </Text>
            <HStack mt={8}>
              <Icon as={BiSolidCheckCircle} color="#00b36e" w={6} h={6} />
              <Heading as="h3" size="md" color="white">
                Help improve Olenium
              </Heading>
            </HStack>
            <Text fontSize="md" my={2}>
              This fee helps fund our small team of developers and designers to
              continue improving Olenium.
            </Text>
          </ModalBody>
          <ModalFooter>
            <VStack spacing={4} w={"100%"}>
              <Button
                variant="oleniumBlue"
                size="md"
                fontSize="md"
                fontWeight="bold"
                onClick={handlePayClick}
                isLoading={isPaymentLoading}
                loadingText="Processing..."
                width={"100%"}
              >
                Get Started
              </Button>

              <Button
                size="md"
                fontSize="md"
                fontWeight="bold"
                onClick={() => navigate("/")}
                width={"100%"}
                variant={"ghost"}
              >
                Go back to dashboard
              </Button>
            </VStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isPaymentSuccessModalOpen}
        onClose={() => closePaymentSuccessModal()}
        size="md"
        isCentered
      >
        <ModalOverlay />
        <ModalContent bg="oleniumBlack.700" color="white" p={5} textAlign={"center"} pt={10} rounded={"2xl"}>
          <ModalCloseButton />
          <ModalBody p={5} textAlign={"center"}>
            <Icon as={BiSolidCheckCircle} w={12} h={12} />
            <Heading as="h1" size="md" fontWeight="bold" mb={5}>
              Payment successful!
            </Heading>
            <Text mb={4}>
              Thank you for your payment! Your you should have received an email with your receipt.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button variant='oleniumBlue' mr={3} onClick={() => closePaymentSuccessModal()} w={"100%"}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ProjectDetail;
