import { AddIcon, CheckIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Text,
  Textarea,
  VStack,
  useColorModeValue,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import { getIdToken } from "../../../../cognito/cognitoAuth";

const MIN_AMOUNT = 10.0; // Example minimum amount
const MAX_AMOUNT = 10000.0; // Example maximum amount

const Payments = ({ project_id, user_id }) => {
  const textColor = useColorModeValue("oleniumBlack.600", "white");
  const boxColor = useColorModeValue("white", "oleniumBlack.600");
  const apiUrl = process.env.REACT_APP_API_URL;
  const [hasStripeAccessToken, setHasStripeAccessToken] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [initialLoading, setInitialLoading] = useState(true);
  const [invoiceData, setInvoiceData] = useState({
    amount: "",
    currency: "USD", // default currency
    description: "",
    dueDate: "",
  });

  const today = new Date().toISOString().split("T")[0];

  const [invoices, setInvoices] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const toast = useToast();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInvoiceData({ ...invoiceData, [name]: value });
  };

  const handleAmountChange = (event) => {
    const value = event.target.value;
    // Store the raw input from the user
    setInvoiceData({ ...invoiceData, amount: value });
  };

  const handleAmountBlur = () => {
    let value = parseFloat(invoiceData.amount || 0);
    if (!isNaN(value)) {
      value = value.toFixed(2); // Round to 2 decimal places
      setInvoiceData({ ...invoiceData, amount: value });
    }
  };

  const displayAmount = () => {
    let value = parseFloat(invoiceData.amount || 0);
    if (!isNaN(value)) {
      return value.toFixed(2); // Round to 2 decimal places when displaying
    }
    return "0.00";
  };

  const amountAfterFees = (parseFloat(displayAmount()) * 0.9).toFixed(2);

  const currencySymbols = {
    USD: "$",
    GBP: "£",
    EUR: "€",
  };

  const validateForm = () => {
    const errors = {};

    // Validate amount
    if (!invoiceData.amount) {
      errors.amount = "Amount is required.";
    } else {
      const value = parseFloat(invoiceData.amount);
      if (value <= 0) {
        errors.amount = "Amount must be greater than 0.";
      } else if (value < MIN_AMOUNT) {
        errors.amount = `Amount must be at least ${MIN_AMOUNT}.`;
      } else if (value > MAX_AMOUNT) {
        errors.amount = `Amount cannot exceed ${MAX_AMOUNT}.`;
      } else if (!/^\d+(\.\d{0,2})?$/.test(invoiceData.amount)) {
        errors.amount = "Invalid amount. Up to two decimal places are allowed.";
      }
    }

    // Validate description
    if (!invoiceData.description) {
      errors.description = "Description is required.";
    }

    // Validate due date
    if (!invoiceData.dueDate) {
      errors.dueDate = "Due date is required.";
    } else {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const selectedDate = new Date(invoiceData.dueDate);
      selectedDate.setHours(0, 0, 0, 0);
      if (selectedDate < today) {
        errors.dueDate = "Due date cannot be in the past.";
      }
    }

    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const saveInvoice = async () => {
    if (!validateForm()) {
      return;
    }

    setIsLoading(true);

    try {
      const IdToken = await getIdToken();
      const dueDateTimestamp = new Date(invoiceData.dueDate).getTime();

      // Convert amount to cents for the API
      const amountInCents = Math.round(parseFloat(invoiceData.amount) * 100);

      const payload = {
        project_id: project_id,
        amount: amountInCents,
        currency: invoiceData.currency,
        description: invoiceData.description,
        due_date: dueDateTimestamp,
      };

      // Make the API request to the Lambda function
      const lambdaApiUrl = `${apiUrl}/invoices`;
      const response = await axios.post(lambdaApiUrl, payload, {
        headers: {
          Authorization: `Bearer ${IdToken}`,
        },
      });

      if (response.status === 200) {
        toast({
          title: "Invoice created successfully.",
          status: "success",
          isClosable: true,
        });
        onClose();
        window.location.reload();
      }
    } catch (error) {
      toast({
        title: "Failed to create invoice. Try logging out and back in.",
        description: error.message,
        status: "error",
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const createInvoice = () => {
    setFormErrors({});
    onOpen();
  };

  const [stripeInfo, setStripeInfo] = useState({
    can_request_payout: false,
    has_setup_payouts: false,
    balance: 0,
    weekly_earnings: 0,
  });

  const fetchStripeInfo = async () => {
    setInitialLoading(true);
    try {
      // Get Cognito token if you're using AWS Cognito for authentication
      const token = await getIdToken();

      const response = await axios.post(
        `${apiUrl}/stripe/balance-and-payout`,  // Replace with your Lambda function URL
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      setStripeInfo(response.data);


    } catch (error) {
      console.error(error);
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setInitialLoading(false);
  };

  useEffect(() => {
    fetchStripeInfo();
  }
    , []);



  return (
    <Box w="100%" bg={boxColor} rounded="xl" p={8} mb={6}>
      <Text color={textColor} fontSize="md" fontWeight="bold" mb={2}>
        Project Payments
      </Text>

      <Divider my={4} />

      {!initialLoading ? (
        <VStack spacing={2} align="stretch">
          {stripeInfo.has_setup_payouts ? (
            <>
              <Text color={textColor} fontSize="sm" mb={2}>
                Add payments to a project to make sure you get paid for your
                work.
              </Text>

              <Button
                variant="oleniumBlue"
                onClick={createInvoice}
                size={"sm"}
                leftIcon={<AddIcon />}
              >
                Add Payment
              </Button>
            </>
          ) : (
            <>
              <Text color={textColor} fontSize="sm" mb={2}>
                You need to connect your Stripe account to add payments to a
                project.
              </Text>

              <Button
                variant="oleniumBlue"
                onClick={() => navigate("/my-account")}
                size={"sm"}
              >
                Connect Stripe Account
              </Button>
            </>
          )}
        </VStack>
      ) : (
        <Center>
          <Spinner size="lg" />
        </Center>
      )}


      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent bg={boxColor} rounded="xl" p={4}>
          <ModalHeader>Create Invoice</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Alert status="info" mb={4} rounded="md">
              <AlertIcon />
              <Text fontSize="sm">
                Invoices can't be deleted once created. Make sure you have the
                correct details before submitting.
              </Text>
            </Alert>

            <FormControl isInvalid={!!formErrors.amount} mb={4}>
              <FormLabel>Amount</FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  color="gray.300"
                  fontSize="1.2em"
                  children={currencySymbols[invoiceData.currency] || "$"}
                />
                <Input
                  type="number"
                  name="amount"
                  min={MIN_AMOUNT} // Add this line
                  max={MAX_AMOUNT} // Add this line
                  value={invoiceData.amount}
                  onChange={handleAmountChange} // Change here
                  onBlur={handleAmountBlur}
                />
                {!formErrors.amount && invoiceData.amount !== "" && (
                  <InputRightElement>
                    <CheckIcon color="green.500" />
                  </InputRightElement>
                )}
              </InputGroup>
              <Box color="red.500" fontSize="sm">
                {formErrors.amount}
              </Box>
            </FormControl>

            <Grid templateColumns="repeat(2, 1fr)" gap={4} mb={4}>
              <GridItem colSpan={1}>
                <Box>
                  <Text
                    color={textColor}
                    fontSize="sm"
                    fontWeight="bold"
                    mb={2}
                  >
                    Amount
                  </Text>
                  <Text color={textColor} fontSize="sm" mb={2}>
                    {invoiceData.amount === ""
                      ? "0.00"
                      : currencySymbols[invoiceData.currency] || "$"}
                    {invoiceData.amount}
                  </Text>
                </Box>
              </GridItem>
              <GridItem colSpan={1}>
                <Box>
                  <Text
                    color={textColor}
                    fontSize="sm"
                    fontWeight="bold"
                    mb={2}
                  >
                    Amount After Fees
                  </Text>
                  <Text color={textColor} fontSize="sm" mb={2}>
                    {currencySymbols[invoiceData.currency] || "$"}
                    {amountAfterFees}
                  </Text>
                </Box>
              </GridItem>
            </Grid>

            <Divider my={4} />

            <FormControl isInvalid={!!formErrors.description} mb={4}>
              <FormLabel>Description</FormLabel>
              <Textarea
                name="description"
                value={invoiceData.description}
                onChange={handleInputChange}
              />
              <Box color="red.500" fontSize="sm">
                {formErrors.description}
              </Box>
            </FormControl>

            <FormControl isInvalid={!!formErrors.dueDate} mb={4}>
              <FormLabel>Due Date</FormLabel>
              <Input
                type="date"
                name="dueDate"
                value={invoiceData.dueDate}
                onChange={handleInputChange}
                min={today}
              />
              <Box color="red.500" fontSize="sm">
                {formErrors.dueDate}
              </Box>
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Currency</FormLabel>
              <Select
                name="currency"
                value={invoiceData.currency}
                onChange={handleInputChange}
              >
                <option value="USD">🇺🇸 USD</option>
                <option value="GBP">🇬🇧 GBP</option>
                <option value="EUR">🇪🇺 EUR</option>
              </Select>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="oleniumBlue"
              onClick={saveInvoice}
              disabled={isLoading}
              isLoading={isLoading}
            >
              Create Invoice
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Payments;
