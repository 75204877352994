import React from 'react';
import { Box, Tag, Heading } from "@chakra-ui/react";
import { motion, useAnimation } from "framer-motion";

import people1 from '../../../../assets/landing/our-people/people-1.jpg';
const MotionText = motion(Heading);

const Hero = () => {

    return (
        <Box
            bgImage={`url(${people1})`}
            bgSize="cover"
            bgPosition="center"
            h="90vh"
            w="100%"
            position="relative"  // Add this
        >
            {/* Black overlay */}
            <Box
                position="absolute"
                top="0"
                left="0"
                w="100%"
                h="100%"
                bg="rgba(0, 0, 0, 0.8)"  // You can adjust the opacity here
            >
            </Box>

            {/* Centered Text */}
            <Box
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                zIndex="2"  // Make sure the text appears above the overlay
                textAlign="center"
            >
                <Tag
                    variant="outline"
                    border={1}
                    color="white"
                    borderColor="white"
                    background={"whiteAlpha.300"}
                    borderRadius="full"
                    px={8}
                    py={1}
                    mb={4}
                >
                    Our Services
                </Tag>
                <Heading
                    color="white"
                    fontSize={{ base: "4xl", md: "8xl" }}
                    fontWeight="bold"
                    lineHeight={1}
                    mb={2}
                    textAlign="center"
                >
                    Capabilities that capture all areas of creativity.
                </Heading>
            </Box>
        </Box>
    );
};

export default Hero;
