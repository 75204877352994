import React from "react";
import { Icon } from "@chakra-ui/react";
import {
  BsFiletypeAac,
  BsFiletypeAi,
  BsFiletypeBmp,
  BsFiletypeCs,
  BsFiletypeCss,
  BsFiletypeCsv,
  BsFiletypeDoc,
  BsFiletypeDocx,
  BsFiletypeExe,
  BsFiletypeGif,
  BsFiletypeHeic,
  BsFiletypeHtml,
  BsFiletypeJava,
  BsFiletypeJpg,
  BsFiletypeJs,
  BsFiletypeJson,
  BsFiletypeJsx,
  BsFiletypeKey,
  BsFiletypeM4P,
  BsFiletypeMd,
  BsFiletypeMdx,
  BsFiletypeMov,
  BsFiletypeMp3,
  BsFiletypeMp4,
  BsFiletypeOtf,
  BsFiletypePdf,
  BsFiletypePhp,
  BsFiletypePng,
  BsFiletypePpt,
  BsFiletypePptx,
  BsFiletypePsd,
  BsFiletypePy,
  BsFiletypeRaw,
  BsFiletypeRb,
  BsFiletypeSass,
  BsFiletypeScss,
  BsFiletypeSh,
  BsFiletypeSql,
  BsFiletypeSvg,
  BsFiletypeTiff,
  BsFiletypeTsx,
  BsFiletypeTtf,
  BsFiletypeTxt,
  BsFiletypeWav,
  BsFiletypeWoff,
  BsFiletypeXls,
  BsFiletypeXlsx,
  BsFiletypeXml,
  BsFiletypeYml,
  BsFileEarmarkText,
} from "react-icons/bs";

const FileIcon = ({ file, width, height, color }) => {
  if (!file) {
    // Render a default icon when filename is not provided or undefined
    return <BsFileEarmarkText />;
  }

  const fileExtension = file.split(".").pop().toLowerCase();

  switch (fileExtension) {
    case "aac":
      return (
        <Icon as={BsFiletypeAac} width={width} height={height} color={color} />
      );
    case "ai":
      return (
        <Icon as={BsFiletypeAi} width={width} height={height} color={color} />
      );
    case "bmp":
      return (
        <Icon as={BsFiletypeBmp} width={width} height={height} color={color} />
      );
    case "cs":
      return (
        <Icon as={BsFiletypeCs} width={width} height={height} color={color} />
      );
    case "css":
      return (
        <Icon as={BsFiletypeCss} width={width} height={height} color={color} />
      );
    case "csv":
      return (
        <Icon as={BsFiletypeCsv} width={width} height={height} color={color} />
      );
    case "doc":
      return (
        <Icon as={BsFiletypeDoc} width={width} height={height} color={color} />
      );
    case "docx":
      return (
        <Icon as={BsFiletypeDocx} width={width} height={height} color={color} />
      );
    case "exe":
      return (
        <Icon as={BsFiletypeExe} width={width} height={height} color={color} />
      );
    case "gif":
      return (
        <Icon as={BsFiletypeGif} width={width} height={height} color={color} />
      );
    case "heic":
      return (
        <Icon as={BsFiletypeHeic} width={width} height={height} color={color} />
      );
    case "html":
      return (
        <Icon as={BsFiletypeHtml} width={width} height={height} color={color} />
      );
    case "java":
      return (
        <Icon as={BsFiletypeJava} width={width} height={height} color={color} />
      );
    case "jpg":
      return (
        <Icon as={BsFiletypeJpg} width={width} height={height} color={color} />
      );
    case "jpeg":
      return (
        <Icon as={BsFiletypeJpg} width={width} height={height} color={color} />
      );
    case "js":
      return (
        <Icon as={BsFiletypeJs} width={width} height={height} color={color} />
      );
    case "json":
      return (
        <Icon as={BsFiletypeJson} width={width} height={height} color={color} />
      );
    case "jsx":
      return (
        <Icon as={BsFiletypeJsx} width={width} height={height} color={color} />
      );
    case "key":
      return (
        <Icon as={BsFiletypeKey} width={width} height={height} color={color} />
      );
    case "m4p":
      return (
        <Icon as={BsFiletypeM4P} width={width} height={height} color={color} />
      );
    case "md":
      return (
        <Icon as={BsFiletypeMd} width={width} height={height} color={color} />
      );
    case "mdx":
      return (
        <Icon as={BsFiletypeMdx} width={width} height={height} color={color} />
      );
    case "mov":
      return (
        <Icon as={BsFiletypeMov} width={width} height={height} color={color} />
      );
    case "mp3":
      return (
        <Icon as={BsFiletypeMp3} width={width} height={height} color={color} />
      );
    case "mp4":
      return (
        <Icon as={BsFiletypeMp4} width={width} height={height} color={color} />
      );
    case "otf":
      return (
        <Icon as={BsFiletypeOtf} width={width} height={height} color={color} />
      );
    case "pdf":
      return (
        <Icon as={BsFiletypePdf} width={width} height={height} color={color} />
      );
    case "php":
      return (
        <Icon as={BsFiletypePhp} width={width} height={height} color={color} />
      );
    case "png":
      return (
        <Icon as={BsFiletypePng} width={width} height={height} color={color} />
      );
    case "ppt":
      return (
        <Icon as={BsFiletypePpt} width={width} height={height} color={color} />
      );
    case "pptx":
      return (
        <Icon as={BsFiletypePptx} width={width} height={height} color={color} />
      );
    case "psd":
      return (
        <Icon as={BsFiletypePsd} width={width} height={height} color={color} />
      );
    case "py":
      return (
        <Icon as={BsFiletypePy} width={width} height={height} color={color} />
      );
    case "raw":
      return (
        <Icon as={BsFiletypeRaw} width={width} height={height} color={color} />
      );
    case "rb":
      return (
        <Icon as={BsFiletypeRb} width={width} height={height} color={color} />
      );
    case "sass":
      return (
        <Icon as={BsFiletypeSass} width={width} height={height} color={color} />
      );
    case "scss":
      return (
        <Icon as={BsFiletypeScss} width={width} height={height} color={color} />
      );
    case "sh":
      return (
        <Icon as={BsFiletypeSh} width={width} height={height} color={color} />
      );
    case "sql":
      return (
        <Icon as={BsFiletypeSql} width={width} height={height} color={color} />
      );
    case "svg":
      return (
        <Icon as={BsFiletypeSvg} width={width} height={height} color={color} />
      );
    case "tiff":
      return (
        <Icon as={BsFiletypeTiff} width={width} height={height} color={color} />
      );
    case "tsx":
      return (
        <Icon as={BsFiletypeTsx} width={width} height={height} color={color} />
      );
    case "ttf":
      return (
        <Icon as={BsFiletypeTtf} width={width} height={height} color={color} />
      );
    case "txt":
      return (
        <Icon as={BsFiletypeTxt} width={width} height={height} color={color} />
      );
    case "wav":
      return (
        <Icon as={BsFiletypeWav} width={width} height={height} color={color} />
      );
    case "woff":
      return (
        <Icon as={BsFiletypeWoff} width={width} height={height} color={color} />
      );
    case "xls":
      return (
        <Icon as={BsFiletypeXls} width={width} height={height} color={color} />
      );
    case "xlsx":
      return (
        <Icon as={BsFiletypeXlsx} width={width} height={height} color={color} />
      );
    case "xml":
      return (
        <Icon as={BsFiletypeXml} width={width} height={height} color={color} />
      );
    case "yml":
      return (
        <Icon as={BsFiletypeYml} width={width} height={height} color={color} />
      );
    default:
      return (
        <Icon
          as={BsFileEarmarkText}
          width={width}
          height={height}
          color={color}
        />
      );
  }
};

export default FileIcon;
