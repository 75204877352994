import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Textarea,
  useToast
} from "@chakra-ui/react";
import { addDays, differenceInDays } from "date-fns";
import React, { useEffect } from "react";

const quickActions = [
  { label: "1 month", duration: 30 },
  { label: "3 months", duration: 90 },
  { label: "6 months", duration: 180 },
];

const ProjectDetails = ({
  projectTitle,
  setProjectTitle,
  projectBrief,
  setProjectBrief,
  deadline,
  setDeadline,
  onFormValidityChange,
}) => {
  const [selectedAction, setSelectedAction] = React.useState(null);

  const [titleError, setTitleError] = React.useState("");
  const [briefError, setBriefError] = React.useState("");

  const [dateError, setDateError] = React.useState(""); // New state for date error
  const minDate = new Date().toISOString().split("T")[0]; // sets the minimum date to today

  const toast = useToast();

  const handleTitleChange = (e) => {
    const value = e.target.value;
    setProjectTitle(value);

    if (value.length < 10 || value.length > 400) {
      setTitleError("Title must be between 10 and 400 characters");
    } else {
      setTitleError("");
    }

    onFormValidityChange(
      value.length >= 10 && value.length <= 400 && briefError === "",
    );
  };

  const handleBriefChange = (e) => {
    const value = e.target.value;
    setProjectBrief(value);

    if (value.length < 200 || value.length > 2000) {
      setBriefError("Brief must be between 200 and 2000 characters");
    } else {
      setBriefError("");
    }

    onFormValidityChange(
      titleError === "" && value.length >= 200 && value.length <= 2000,
    );
  };

  const handleQuickActionClick = (action) => {
    const newDeadline = addDays(new Date(), action.duration);
    const deadlineDate = newDeadline.toISOString().split("T")[0]; // Get date part only
    const formattedDeadline = `${deadlineDate}T17:00:00`; // Append time part

    setDeadline(formattedDeadline);
    setSelectedAction(action.label);
  };

  const handleDateChange = (e) => {
    const selectedDate = e.target.value;
    const formattedDeadline = `${selectedDate}T23:59:59`;

    if (new Date(selectedDate) < new Date(minDate)) {
      setDateError("Please select a future date.");

      // Displaying the toast for invalid date selection
      toast({
        title: "Invalid Date Selected",
        description: "Please select a date that is in the future.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } else {
      setDateError("");
      setDeadline(formattedDeadline);
    }

    const newDeadline = new Date(formattedDeadline);
    const today = new Date();
    const diff = differenceInDays(newDeadline, today);

    const selected = quickActions.find((action) => action.duration === diff);

    setSelectedAction(selected ? selected.label : null);
  };

  useEffect(() => {
    // check if development environment is development if so autofill the form
    if (process.env.NODE_ENV === 'development') {
      setProjectTitle("EcoVerve: Rebranding for Sustainability");
      setProjectBrief(
        "EcoVerve, an established brand in the eco-friendly products industry, seeks to rebrand itself to emphasize its commitment to sustainability and to appeal to a younger, more environmentally conscious demographic. The rebranding effort includes a new logo, product packaging, and a brand style guide that reflects EcoVerve's values of sustainability, innovation, and community engagement.",
      );
      setDeadline("2022-12-31T17:00:00");
      setSelectedAction("1 year");
    }
  }, []); // Run only once

  return (
    <Box w={"80%"} maxW={"600px"} mx={"auto"} >
      <FormControl isInvalid={titleError}>
        <FormLabel>Project Title</FormLabel>
        <Input
          type="text"
          placeholder="Let's name your project"
          value={projectTitle}
          onChange={handleTitleChange}
        />
        <FormErrorMessage>{titleError}</FormErrorMessage>
      </FormControl>

      <FormControl mt={7} isInvalid={briefError}>
        <FormLabel>Project Brief</FormLabel>
        <Textarea
          placeholder={
            "Tell us about your project in as much detail as possible. Consider answering the following questions:\n\n" +
            "• What is the purpose of this project?\n" +
            "• Who is the target audience?\n" +
            "• What are the key features you want to include?\n" +
            "• Do you have specific design styles or elements you want to incorporate? (e.g., colours, fonts)\n" +
            "• Is there anything else we should know?"
          }
          size={{ base: "sm", md: "md" }}
          resize={"vertical"}
          width="100%"
          height={{ base: "400px", md: "300px" }}
          rounded={"md"}
          value={projectBrief}
          onChange={handleBriefChange}
        />
        <FormErrorMessage>{briefError}</FormErrorMessage>
      </FormControl>

      <FormControl mt={7} maxW={"500px"}>
        <FormLabel>Deadline</FormLabel>
        <Input
          type="date"
          placeholder="Deadline"
          name="deadline"
          value={deadline.split("T")[0]}
          min={minDate}
          onChange={handleDateChange}
          size={{ base: "sm", md: "md" }}
          rounded={"md"}
          maxW={"400px"}
        />
        <FormErrorMessage>{dateError}</FormErrorMessage>
        <HStack wrap={"wrap"} mt={4}>
          {quickActions.map((action) => (
            <Button
              onClick={() => handleQuickActionClick(action)}
              key={action.label}
              size="xs"
              variant={
                selectedAction === action.label ? "oleniumBlue" : "outline"
              }
            >
              {action.label}
            </Button>
          ))}
        </HStack>
      </FormControl>
    </Box>
  );
};

export default ProjectDetails;
