import {
  Button,
  Center,
  Flex,
  Icon,
  Text,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import React from "react";
import { FiArrowRight } from "react-icons/fi";

import { Link } from "react-router-dom";

function Error() {
  const boxColor = useColorModeValue("white", "oleniumBlack.600");
  const textColor = useColorModeValue("oleniumGray.500", "oleniumGray.400");
  const textColorAlt = useColorModeValue("oleniumGray.400", "oleniumGray.300");

  const textAnimationVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div>
      <Flex alignItems="center" gap="2" mt={10}>
        <Center w="100%" h="70vh" bg={boxColor} rounded="xl">
          <VStack spacing={4} w="100%" textAlign="center" maxW={"lg"}>
            <motion.div
              initial="hidden"
              animate="visible"
              variants={textAnimationVariants}
              transition={{ delay: 0.3, duration: 0.6 }}
            >
              <Text
                bgGradient="linear(to-l, blue.300, blue.500)"
                bgClip="text"
                fontSize={{ base: "7xl", sm: "7xl", md: "6xl" }}
                fontWeight="bold"
                mb={4}
              >
                Coming Soon.
              </Text>
              <Text color={textColor} fontSize="2xl" fontWeight="bold" mb={8}>
                This page isn't quite ready yet.
              </Text>
              <Button
                as={Link}
                to="/"
                variant="oleniumBlue"
                rightIcon={<Icon as={FiArrowRight} />}
                size="sm"
              >
                Back to Home
              </Button>
            </motion.div>
          </VStack>
        </Center>
      </Flex>
    </div>
  );
}

export default Error;
