import {
  Alert,
  AlertIcon,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Spacer,
  Spinner,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { signIn } from "../../cognito/cognitoAuth";
import { getIdToken } from "../../cognito/cognitoAuth";

import { FaGoogle, FaMicrosoft } from "react-icons/fa";

const Login = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const toast = useToast();

  const navigate = useNavigate();

  // useEffect(() => {
  //   // Function to load the Google API script
  //   const loadGoogleScript = () => {
  //     const script = document.createElement('script');
  //     script.src = 'https://apis.google.com/js/api.js';
  //     script.onload = () => {
  //       window.gapi.load('client:auth2', initGoogleClient);
  //     };
  //     document.body.appendChild(script);
  //   };

  //   // Initialize the Google auth client
  //   const initGoogleClient = () => {
  //     window.gapi.client.init({
  //       clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID, // replace with your Google client ID
  //       scope: 'email',
  //     });
  //   };

  //   loadGoogleScript();
  // }, []);

  // const handleGoogleSignIn = async () => {
  //   const GoogleAuth = window.gapi.auth2.getAuthInstance();
  //   try {
  //     const GoogleUser = await GoogleAuth.signIn();
  //     const profile = GoogleUser.getBasicProfile();
  //     const id_token = GoogleUser.getAuthResponse().id_token;

  //     // Check if the user exists in Cognito
  //     // If not, create a new user in Cognito with Google profile information
  //     // Then authenticate the user with Cognito

  //     signInWithGoogle(id_token);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  // const signInWithGoogle = async (googleIdToken) => {
  //   // Use Cognito's adminInitiateAuth or a similar method to authenticate the user
  //   // You might need to set up a custom authentication flow in Cognito
  //   // For example, you can use a Lambda trigger to verify the Google ID token
  //   // and sign in the user or create a new user account

  //   try {
  //     const authentication = await getIdToken();

  //     const response = await fetch(`${API_URL}/google-auth`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: `Bearer ${authentication}`,

  //       },
  //       body: JSON.stringify({ token: googleIdToken }),
  //     });

  //     const data = await response.json();
  //     console.log(data);

  //     // If the user is authenticated successfully, you can redirect the user
  //     // to the home page or do other things
  //     if (data.success) {
  //       navigate('/');
  //     } else {
  //       // Handle the case where user does not exist
  //       toast({
  //         title: "Account not found.",
  //         description: "Please sign up to continue.",
  //         status: "warning",
  //         duration: 5000,
  //         isClosable: true,
  //       });
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     toast({
  //       title: "User sign in failed.",
  //       description: "Make sure you've signed up with Google before.",
  //       status: "warning",
  //       duration: 5000,
  //       isClosable: true,
  //     });
  //   }
  // };



  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      await signIn(email, password);
      navigate("/");
    } catch (err) {
      console.log("Sign in error", err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const [isFormValid, setIsFormValid] = useState(false);

  const handleFormChange = () => {
    setIsFormValid(email !== "" && password !== "");
  };

  return (
    <motion.div
      as="form"
      onSubmit={handleSubmit}
      initial={{ x: -100, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Box
        as="form"
        onSubmit={handleSubmit}
        px={10}
        py={8}
        w={{ base: "100%", md: "80%" }}
        borderRadius="2xl"
        bg="blackAlpha.800"
        backdropFilter="blur(10px)"
      >
        <VStack spacing={8} alignItems="start" mb={8}>
          <Box>
            <Text fontSize="4xl" fontWeight="bold" color="white">
              Sign in
            </Text>

            <HStack mt={1}>
              <Text fontSize="md" color="white" fontWeight="bold">
                New to Olenium?{" "}
              </Text>
              <Button
                variant="link"
                size={"md"}
                color="#0085FF"
                onClick={() => navigate("/")}
              >
                Create an account
              </Button>
            </HStack>
          </Box>
          {/* 
          <HStack width="100%" spacing={4}>

            <IconButton
              aria-label="Sign in with Google"
              icon={<Icon as={FaGoogle} />}
              width={"100%"}
              onClick={handleGoogleSignIn} // This will trigger Google Sign-In
            />

            <IconButton aria-label="Sign in with Microsoft" icon={<Icon as={FaMicrosoft} />} width={"100%"} />

          </HStack> */}

          <FormControl id="email" isRequired>
            <FormLabel color="white">Email</FormLabel>
            <Input
              type="email"
              value={email}
              placeholder="Enter your email"
              _placeholder={{ color: "oleniumGray.300" }}
              onChange={(e) => {
                setEmail(e.target.value);
                handleFormChange();
              }}
            />
            <FormErrorMessage>{error && error}</FormErrorMessage>
          </FormControl>

          <FormControl id="password" isRequired>
            <FormLabel color="white">Password</FormLabel>
            <InputGroup size="md">
              <Input
                pr="4.5rem"
                type={show ? "text" : "password"}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  handleFormChange();
                }}
                placeholder="Enter your password"
                _placeholder={{ color: "oleniumGray.300" }}
              />
              <InputRightElement width="4.5rem">
                <Button
                  h="1.75rem"
                  size="sm"
                  onClick={handleClick}
                  background="whiteAlpha.400"
                  color="white"
                  _hover={{ background: "whiteAlpha.500" }}
                >
                  {show ? "Hide" : "Show"}
                </Button>
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage>{error && error}</FormErrorMessage>
          </FormControl>

          {error && (
            <Alert status="error" mt={4} mb={4} borderRadius="xl">
              <AlertIcon />
              {error}
            </Alert>
          )}
          <HStack width="100%">
            <Spacer />
            <Button
              color="#0085FF"
              variant={"link"}
              size={"md"}
              onClick={() => navigate("/forgot-password")}
              alignContent={"right"}
            >
              Forgot Password?
            </Button>
          </HStack>

          <Button
            type="submit"
            variant="oleniumBlue"
            width="100%"
            size={"md"}
            disabled={!isFormValid || isLoading}
          >
            {isLoading ? <Spinner /> : "Login"}
          </Button>
        </VStack>
      </Box>
    </motion.div>
  );
};

export default Login;
