import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Heading,
  Spacer,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import { deleteAccount } from "../../../../cognito/cognitoAuth";

const DeleteAccount = () => {
  const textColor = useColorModeValue("oleniumGray.700", "white");
  const boxColor = useColorModeValue("white", "oleniumBlack.600");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isChecked, setIsChecked] = useState(false);

  const cancelRef = React.useRef();

  const navigate = useNavigate();

  const handleDelete = () => {
    onClose();
    deleteAccount(navigate);
  };

  return (
    <Flex
      w={"100%"}
      my={5}
      alignSelf={{ lg: "flex-end" }}
      justifySelf={{ lg: "flex-end" }}
      paddingBottom={{ sm: 0, md: 50, lg: 100 }}
    >
      <Box bg={boxColor} w="100%" p={8} rounded="2xl" shadow="xl">
        <Heading as="h1" size="md" fontWeight="bold" mb={5} color={textColor}>
          Delete Account
        </Heading>
        <Text color={textColor}>
          This action is irreversible. All your data will be deleted.
        </Text>
        <Flex
          direction="row"
          align="center"
          justify="space-between"
          marginTop={2}
        >
          <Spacer />
          <Button variant="oleniumRed" size="sm" onClick={onOpen}>
            Delete Account
          </Button>
        </Flex>
      </Box>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
        bg={boxColor}
      >
        <AlertDialogOverlay>
          <AlertDialogContent bg={boxColor}>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Account
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? This action cannot be undone. All your data will be
              deleted.
              <Divider mt={3} />
              <Text mt={3} fontSize="sm">
                Disclaimer: By deleting your account, you acknowledge and accept
                that all your personal data, account settings, and any
                associated content will be permanently removed. We will not be
                able to restore any of this information once the account is
                deleted. Please ensure you have a backup of any important data
                before proceeding.
              </Text>
              <Divider mt={3} />
              <Checkbox
                mt={3}
                isChecked={isChecked}
                onChange={(e) => setIsChecked(e.target.checked)}
              >
                I understand and want to delete my account
              </Checkbox>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                variant={"oleniumRed"}
                onClick={handleDelete}
                ml={3}
                isDisabled={!isChecked}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>
  );
};

export default DeleteAccount;
