import {
  Box,
  Button,
  Divider,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useState } from "react";
import { getIdToken } from "../../../../cognito/cognitoAuth";

const AcceptProject = ({ project_id }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const acceptDisclosure = useDisclosure();
  const rejectDisclosure = useDisclosure();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const handleAction = async (action) => {
    setIsLoading(true);
    const token = await getIdToken();

    try {
      const response = await axios.post(
        `${apiUrl}/projects/${project_id}/action`,
        { action },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      if (response.status === 200) {
        toast({
          title: `Project ${action}ed`,
          description: `You have successfully ${action.toLowerCase()}ed the project.`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }

      window.location.reload();
    } catch (error) {
      toast({
        title: `Error ${action}ing Project`,
        description:
          error.response?.data ||
          `An error occurred while ${action.toLowerCase()}ing the project.`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    setIsLoading(false);
    acceptDisclosure.onClose();
    rejectDisclosure.onClose();
  };

  return (
    <Box w="100%" rounded="xl" p={8} mb={6} bg="oleniumBlack.600">
      <Text color="white" fontSize="md" fontWeight="bold" mb={2}>
        Accept Project
      </Text>
      <Text color="white" fontSize="sm" mb={4}>
        You will be able to start working on this project once you accept it.
      </Text>

      <Divider my={4} />

      <HStack spacing={4}>
        <Spacer />
        <Button
          variant="oleniumRed"
          size="sm"
          onClick={rejectDisclosure.onOpen}
        >
          Reject
        </Button>
        <Button
          variant="oleniumBlue"
          size="sm"
          onClick={acceptDisclosure.onOpen}
        >
          Accept
        </Button>
      </HStack>

      <Modal
        isOpen={acceptDisclosure.isOpen}
        onClose={acceptDisclosure.onClose}
      >
        <ModalOverlay />
        <ModalContent
          background={"oleniumBlack.600"}
          color={"white"}
          borderColor={"oleniumBlack.600"}
        >
          <ModalHeader>Confirm Acceptance</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Are you sure you want to accept this project?</Text>
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={acceptDisclosure.onClose}>
              Cancel
            </Button>
            <Button
              variant="oleniumBlue"
              onClick={() => handleAction("Accept")}
              isLoading={isLoading}
            >
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={rejectDisclosure.isOpen}
        onClose={rejectDisclosure.onClose}
      >
        <ModalOverlay />
        <ModalContent
          background={"oleniumBlack.600"}
          color={"white"}
          borderColor={"oleniumBlack.600"}
        >
          <ModalHeader>Confirm Rejection</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Are you sure you want to reject this project?</Text>
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={rejectDisclosure.onClose}>
              Cancel
            </Button>
            <Button
              variant="oleniumBlue"
              onClick={() => handleAction("Reject")}
              isLoading={isLoading}
            >
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default AcceptProject;
