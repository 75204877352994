import {
  Avatar,
  Box,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  IconButton,
  Switch,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { getIdToken } from "../../../../cognito/cognitoAuth";

const UserManager = () => {
  const [users, setUsers] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentUser, setCurrentUser] = useState(null);
  const toast = useToast();
  const API_URL = process.env.REACT_APP_API_URL;

  const showSuccessToast = (message) => {
    toast({
      title: message,
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  const showErrorToast = (message) => {
    toast({
      title: message,
      status: "error",
      duration: 3000,
      isClosable: true,
    });
  };

  const getBadgeColor = (group) => {
    switch (group) {
      case "Clients":
        return "green";
      case "Providers":
        return "blue";
      case "Admins":
        return "purple";
      default:
        return "gray";
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const authentication = await getIdToken();
      const response = await axios.get(`${API_URL}/users/all`, {
        headers: {
          Authorization: `Bearer ${authentication}`,
        },
      });
      setUsers(response.data.body); // Use the 'body' property from the response
    } catch (error) {
      showErrorToast("Failed to fetch users");
    }
  };

  const toggleUserStatus = async (user) => {
    if (user.enabled) {
      // Note: Check the attribute name returned by your backend
      await disableUser(user.username);
    } else {
      await enableUser(user.username);
    }
  };

  const disableUser = async (username) => {
    try {
      const authentication = await getIdToken();
      await axios.post(
        `${API_URL}/users/${username}/disable`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authentication}`,
          },
        },
      );
      showSuccessToast(`Successfully disabled user`);
      fetchUsers();
    } catch (error) {
      showErrorToast(`Failed to disable user ${username}`);
    }
  };

  const enableUser = async (username) => {
    try {
      const authentication = await getIdToken();
      await axios.post(
        `${API_URL}/users/${username}/enable`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authentication}`,
          },
        },
      );
      showSuccessToast(`Successfully enabled user`);
      fetchUsers();
    } catch (error) {
      showErrorToast(`Failed to enable user ${username}`);
    }
  };

  const navigate = useNavigate();

  const onBack = () => {
    navigate("/admin-panel");
  };

  return (
    <Box>
      <Flex justifyContent="flex-start" mb={10}>
        <IconButton aria-label="Back" icon={<FaArrowLeft />} onClick={onBack} />
        <Heading as="h1" size="lg" ml={5} mt={1}>
          User Manager
        </Heading>
      </Flex>
      {/* <Grid templateColumns="repeat(4, 1fr)" gap={6} mb={6}>
        <GridItem colSpan={1}>
          <Box
            p={5}
            shadow="md"
            borderWidth="1px"
            flex="1"
            borderRadius="md"
            bg={"oleniumBlack.600"}
          >
            <Text color={"white"} fontSize="sm" fontWeight="bold">
              Total users
            </Text>
            <Text color={"white"} fontSize="2xl" fontWeight="bold">
              4
            </Text>
          </Box>
        </GridItem>
        <GridItem colSpan={1}>
          <Box
            p={5}
            shadow="md"
            borderWidth="1px"
            flex="1"
            borderRadius="md"
            bg={"oleniumBlack.600"}
          >
            <Text color={"white"} fontSize="sm" fontWeight="bold">
              Banned users
            </Text>
            <Text color={"white"} fontSize="2xl" fontWeight="bold">
              4
            </Text>
          </Box>
        </GridItem>
      </Grid> */}
      <Box>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Username</Th>
              <Th>Email</Th>
              <Th>Group</Th>
              <Th>Status</Th>
              {/* <Th>Actions</Th> */}
            </Tr>
          </Thead>
          <Tbody>
            {users.map((user) => {
              return (
                <Tr key={user.Username}>
                  <Td>
                    <HStack justify="flex-start">
                      <Avatar
                        size="xs"
                        name={`${user.given_name} ${user.family_name}`}
                        src={user.profile_picture_url}
                      />
                      <Text color={"white"} fontSize="xs" fontWeight="bold">
                        {`${user.given_name} ${user.family_name}`}
                      </Text>
                    </HStack>
                  </Td>
                  <Td>{user.email}</Td>
                  <Td>
                    <Tag
                      colorScheme={getBadgeColor(user.user_role)}
                      size="sm"
                      variant="outline"
                      rounded={"full"}
                    >
                      {user.user_role}
                    </Tag>
                  </Td>
                  <Td>
                    <FormControl display="flex" alignItems="center">
                      <FormLabel
                        htmlFor={`status-switch-${user.username}`}
                        mb="0"
                      >
                        {user.enabled ? "Enabled" : "Disabled"}{" "}
                        {/* Note: Check the attribute name returned by your backend */}
                      </FormLabel>
                      <Switch
                        id={`status-switch-${user.username}`}
                        isChecked={user.enabled}
                        onChange={() => toggleUserStatus(user)}
                      />
                    </FormControl>
                  </Td>
                  {/* <Td>
                    <Button onClick={() => openUserModal(user)}>Edit</Button>
                  </Td> */}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

export default UserManager;
