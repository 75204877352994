import {
  Button,
  Flex,
  Grid,
  Heading,
  Hide,
  Icon,
  Select,
  Skeleton,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import axios from "axios";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { BsPlus } from "react-icons/bs";
import { Link } from "react-router-dom";
import { getIdToken } from "../../../cognito/cognitoAuth";
import Card from "../../Shared/Cards/Card";
import CardBody from "../../Shared/Cards/CardBody";
import ProjectCard from "./ProjectCard";

function Projects() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("transparent", "#4A5568");
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState({ show: false, status: "", message: "" });
  const [showSkeleton, setShowSkeleton] = useState(false);

  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    // set window scroll to top
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchProjects = async () => {
      const authentication = await getIdToken();
      try {
        const response = await fetch(`${API_URL}/projects/client`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authentication}`,
          },
        });
        const data = await response.json();

        if (data.statusCode === 200) {
          const projectsData = JSON.parse(data.body);
          if (Array.isArray(projectsData)) {
            const sortedProjects = projectsData.sort((a, b) => {
              const dateA = new Date(a.created_at);
              const dateB = new Date(b.created_at);
              return dateB - dateA;
            });

            const uniqueUserIds = [
              ...new Set(projectsData.map((project) => project.provider_id)),
            ];

            for (const id of uniqueUserIds) {
              try {
                const userResponse = await axios.get(
                  `${API_URL}/users/info/${id}`,
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${authentication}`,
                    },
                  },
                );

                console.log("User response:", userResponse);

                if (userResponse.data.statusCode === 200) {
                  // update projects with user profile info for the provider (profile_picture, first_name, last_name)
                  const user = userResponse.data.body;
                  const { profile_picture_url, given_name, family_name } = user;

                  const project = sortedProjects.find(
                    (project) => project.provider_id === id,
                  );
                  project.profile_picture = profile_picture_url;
                  project.first_name = given_name;
                  project.last_name = family_name;
                } else {
                  throw new Error("Failed to fetch user info.");
                }
              } catch (error) {
                console.error("Error fetching user info:", error);
                setAlert({
                  show: true,
                  status: "error",
                  message: error.message,
                });
              }
            }

            setProjects(sortedProjects);
          } else {
            throw new Error("Failed to fetch projects.");
          }
        } else {
          throw new Error("Failed to fetch projects.");
        }
      } catch (error) {
        console.error("Error fetching projects:", error);
        setAlert({
          show: true,
          status: "error",
          message: error.message,
        });
        setProjects([]);
      } finally {
        setLoading(false);
      }
    };

    // Add setTimeout to setShowSkeleton after 2 seconds
    setTimeout(() => {
      setShowSkeleton(true);
    }, 2000);

    fetchProjects();
  }, []);

  const handleDeleteSuccess = (id) => {
    console.log(`Deleted project with id ${id}`);
  };

  const handleEditClick = () => {
    console.log("Edit project clicked");
  };

  const [selectedTab, setSelectedTab] = useState("all");
  const [selectedDateFilter, setSelectedDateFilter] = useState("all");

  const handleTabChange = (tabIndex) => {
    switch (tabIndex) {
      case 0:
        setSelectedTab("all");
        break;
      case 1:
        setSelectedTab("active");
        break;
      case 2:
        setSelectedTab("completed");
        break;
      default:
        setSelectedTab("all");
    }
  };

  const handleFilterChange = (event) => {
    setSelectedDateFilter(event.target.value);
  };

  const filteredProjects = projects.filter((project) => {
    const tabFilter =
      selectedTab === "all"
        ? true
        : selectedTab === "active"
          ? project.status === "IN_PROGRESS"
          : selectedTab === "completed"
            ? project.status === "COMPLETED"
            : false;

    const dateFilter = isDateInRange(project.created_at, selectedDateFilter);

    return tabFilter && dateFilter;
  });

  return (
    <Flex direction="column">
      <Flex justifyContent="space-between" mb={5} mt={5}>
        <Heading as="h1" size="lg">
          Projects
        </Heading>
      </Flex>
      <Tabs
        onChange={(index) => handleTabChange(index)}
        colorScheme="gray"
        border={borderColor}
        mb={5}
      >
        <TabList>
          <Tab>All</Tab>
          <Tab>Active</Tab>
          <Tab>Completed</Tab>
          <Spacer />
          <Hide below="lg">
            <Select
              onChange={handleFilterChange}
              width="150px"
              mb={3}
              rounded="xl"
              size={{ base: "sm", md: "sm", lg: "md" }}
            >
              <option value="all">All Dates</option>
              <option value="month">This Month</option>
              <option value="week">This Week</option>
              <option value="today">Today</option>
            </Select>
          </Hide>
        </TabList>
        <TabPanels>
          <TabPanel p={{ base: 0, md: 4 }}>
            {loading && showSkeleton ? (
              <SkeletonGrid />
            ) : (
              <ProjectsGrid projects={filteredProjects} loading={loading} />
            )}
          </TabPanel>
          <TabPanel p={{ base: 0, md: 4 }}>
            {loading && showSkeleton ? (
              <SkeletonGrid />
            ) : (
              <ProjectsGrid projects={filteredProjects} loading={loading} />
            )}
          </TabPanel>
          <TabPanel p={{ base: 0, md: 4 }}>
            {loading && showSkeleton ? (
              <SkeletonGrid />
            ) : (
              <ProjectsGrid projects={filteredProjects} loading={loading} />
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}

const SkeletonGrid = () => {
  const variants = {
    hidden: { opacity: 0, scale: 0 },
    visible: (i) => ({
      opacity: 1,
      scale: 1,
      transition: {
        delay: i * 0.1,
      },
    }),
  };

  return (
    <Flex direction="column" mt={{ sm: "0px", lg: "0px" }} mb={17}>
      <Grid
        templateColumns={{
          sm: "1fr",
          md: "repeat(1, 1fr)",
          lg: "repeat(2, 1fr)",
          xl: "repeat(3, 1fr)",
          "2xl": "repeat(3, 1fr)",
        }}
        gap={8}
        mt={5}
      >
        {[...Array(6)].map((_, index) => (
          <motion.div
            key={index}
            custom={index}
            initial="hidden"
            animate="visible"
            variants={variants}
          >
            <Skeleton
              height="200px"
              borderRadius="2xl"
              startColor="oleniumBlack.300"
              endColor="oleniumBlack.500"
            />
          </motion.div>
        ))}
      </Grid>
    </Flex>
  );
};

const ProjectsGrid = ({ projects, loading }) => {
  const borderColor = useColorModeValue("oleniumGray.300", "oleniumGray.700");

  const handleDeleteSuccess = (id) => {
    console.log(`Deleted project with id ${id}`);
  };

  const handleEditClick = () => {
    console.log("Edit project clicked");
  };

  return (
    <Flex direction="column" mt={{ sm: "0px", lg: "0px" }} mb={17} p={0}>
      <Grid
        templateColumns={{
          sm: "1fr",
          md: "repeat(1, 1fr)",
          lg: "repeat(2, 1fr)",
          xl: "repeat(3, 1fr)",
          "2xl": "repeat(3, 1fr)",
        }}
        gap={8}
        mt={5}
        p={0}
      >
        {projects.map((project, index) => (
          <motion.div
            key={project.id}
            initial={{ opacity: 0, y: 50 }}
            animate={{
              opacity: 1,
              y: 0,
              transition: { delay: index * 0.1, duration: 0.5 },
            }}
            exit={{ opacity: 0, y: -50 }}
          >
            <ProjectCard
              id={project.id}
              name={project.name}
              brief={project.brief}
              service_name={project.service_name}
              service_icon={project.service_icon}
              provider_id={project.provider_id}
              status={project.status}
              created_at={project.created_at}
              start_date={project.start_date ? project.start_date : null}
              deadline={project.deadline}
              profile_picture={project.profile_picture}
              provider_first_name={project.first_name}
              provider_last_name={project.last_name}
              onDeleteSuccess={handleDeleteSuccess}
              onEditClick={handleEditClick}
            />
          </motion.div>
        ))}

        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{
            opacity: loading ? 0 : 1,
            y: 0,
            transition: { delay: 0.4, duration: 0.5 },
          }}
          exit={{ opacity: 0, y: -50 }}
        >
          <Card
            _hover={{
              borderColor: "blue.500",
              cursor: "pointer",
              borderStyle: "solid",
              color: "blue.500",
            }}
            rounded="2xl"
            borderStyle="dashed"
            borderWidth="2px"
            borderColor={useColorModeValue(
              "oleniumGray.300",
              "oleniumGray.900",
            )}
            bg={useColorModeValue("white", "oleniumBlack.700")}
            color={useColorModeValue("oleniumGray.500", "white")}
          >
            <CardBody h="100%" w="100%">
              <Flex w="100%" h={"100%"}>
                <Button variant="no-hover" w="100%" h="100%" py={16}>
                  <Link to="/new-project">
                    <Flex
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Icon
                        as={BsPlus}
                        w="30px"
                        h="30px"
                        mb="12px"
                        fontWeight="bold"
                      />
                      <Text fontSize="lg" fontWeight="bold">
                        Create a New Project
                      </Text>
                    </Flex>
                  </Link>
                </Button>
              </Flex>
            </CardBody>
          </Card>
        </motion.div>
      </Grid>
    </Flex>
  );
};

const isDateInRange = (date, range) => {
  const currentDate = new Date();
  const projectDate = new Date(date);
  switch (range) {
    case "all":
      return true;
    case "month":
      return (
        projectDate.getMonth() === currentDate.getMonth() &&
        projectDate.getFullYear() === currentDate.getFullYear()
      );
    case "week":
      const startOfWeek = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() - currentDate.getDay(),
      );
      const endOfWeek = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() + (6 - currentDate.getDay()),
      );
      return projectDate >= startOfWeek && projectDate <= endOfWeek;
    case "today":
      return (
        projectDate.getDate() === currentDate.getDate() &&
        projectDate.getMonth() === currentDate.getMonth() &&
        projectDate.getFullYear() === currentDate.getFullYear()
      );
    default:
      return true;
  }
};

export default Projects;
