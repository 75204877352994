// components/Onboarding/ClientOnboarding.js

import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Icon,
  Text,
  VStack,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { Step, Steps, useSteps } from "chakra-ui-steps";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import BasicProfile from "./DesignerForms/BasicProfile";
import Portfolio from "./DesignerForms/Portfolio";
import WhatsNext from "./DesignerForms/WhatNext";

import { BsCheckCircle } from "react-icons/bs";
import { getIdToken } from "../../../cognito/cognitoAuth";

const API_URL = process.env.REACT_APP_API_URL;

const navigateAndReload = (navigate) => {
  navigate("/my-account");
  window.location.reload();
};

const successfullySubmitted = (navigate) => {
  return (
    <Center h="80vh">
      <Box
        bg="oleniumBlack.600"
        rounded="xl"
        p={14}
        textAlign="center"
        w={"100%"}
        maxWidth="800px"
      >
        <VStack spacing={3}>
          <Icon as={BsCheckCircle} boxSize={12} color="white" />
          <Text color="white" fontSize="xl" fontWeight="bold" mt={4}>
            Your application has been submitted!
          </Text>
          <Text
            color="white"
            fontSize="md"
            fontWeight="normal"
            mt={2}
            maxW={500}
          >
            We appreciate your interest in joining Olenium. We will review your
            application and get back to you shortly.
          </Text>
          <Button
            mt={4}
            variant="oleniumBlue"
            onClick={() => navigateAndReload(navigate)}
          >
            Go to my account
          </Button>
        </VStack>
      </Box>
    </Center>
  );
};

const DesignerOnboarding = () => {
  const { nextStep, prevStep, activeStep } = useSteps({
    initialStep: 0,
  });

  const toast = useToast();

  const [formValidBasicProfile, setFormValidBasicProfile] = useState(false);
  const [formValidPortfolio, setFormValidPortfolio] = useState(false);

  const boxColor = useColorModeValue("white", "oleniumBlack.600");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  // Basic Profile
  const [profilePicture, setProfilePicture] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [bio, setBio] = useState("");
  const [providerType, setProviderType] = useState("FREELANCER");
  const [selectedServices, setSelectedServices] = useState([]);
  const [services, setServices] = useState([]);
  const [alert, setAlert] = useState({ show: false, status: "", message: "" });

  // Portfolio
  const [portfolioItems, setPortfolioItems] = useState([]);
  const [editingItem, setEditingItem] = useState(null);
  const [placeholders, setPlaceholders] = useState([]);

  const [submitted, setSubmitted] = useState(false);

  // Define a function to receive updates from the Portfolio component
  const updatePortfolioItemsInParent = (portfolioItems) => {
    setPortfolioItems(portfolioItems);
  };

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");

    if (code) {
      // If 'code' is found in the URL, make an API request
      const exchangeCodeForToken = async () => {
        setLoading(true);
        try {
          const token = await getIdToken();

          // pass the code to the API in the path to exchange it for an access token
          await axios.post(
            `${apiUrl}/connect/instagram/handle-url-code`,
            {
              code: code,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          );
          toast({
            title: "Success",
            description: "Instagram account successfully connected.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        } catch (error) {
          toast({
            title: "Error",
            description:
              "Failed to connect Instagram account using code from URL.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
        setLoading(false);
      };
      exchangeCodeForToken();
    }
  }, [apiUrl, toast]);

  const createOnboardingData = () => {
    return {
      basicProfile: {
        bio,
        providerType,
      },
      work: {
        selectedServices,
      },
      portfolio: portfolioItems.map((item) => ({
        title: item.title,
        description: item.description,
        services: item.services,
        tags: item.tags,
        images: item.images, // you may need to convert this to a suitable format for your API
      })),
    };
  };

  const getSignedUrl = async (file) => {
    const IdToken = await getIdToken();
    const response = await axios.post(
      `${API_URL}/provider/presigned-url`,
      {
        file_name: file.name,
        content_type: file.type,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${IdToken}`,
        },
      }
    );

    return response.data; // return the data directly
  };

  const MAX_RETRIES = 3;
  const RETRY_DELAY = 500; // milliseconds

  const uploadImageToS3 = async (file) => {
    let retries = 0;

    while (retries < MAX_RETRIES) {
      try {
        const response = await getSignedUrl(file);
        const bodyObject = JSON.parse(response.body);
        const presignedUrl = bodyObject.presigned_url;
        const imageUrl = bodyObject.image_url;

        const uploadResponse = await axios.put(presignedUrl, file, {
          headers: {
            "Content-Type": file.type,
          },
        });

        return imageUrl; // Return the image URL
      } catch (error) {
        retries++;
        if (retries < MAX_RETRIES) {
          console.warn(`Retrying upload (${retries}/${MAX_RETRIES})...`);
          await new Promise((resolve) =>
            setTimeout(resolve, RETRY_DELAY * 2 ** retries)
          );
        } else {
          console.error("Error uploading image to S3", error);
          throw error;
        }
      }
    }
  };

  const convertImagesToS3Keys = async (portfolioItems) => {
    try {
      const portfolioWithS3Keys = await Promise.all(
        portfolioItems.map(async (item) => {
          const imagesWithS3Keys = await Promise.all(
            item.images.map(async (image) => {
              // Assume that image needs to be uploaded to S3 in all cases
              const assetURL = await uploadImageToS3(image);
              return assetURL;
            })
          );

          return {
            ...item,
            images: imagesWithS3Keys,
          };
        })
      );

      return portfolioWithS3Keys;
    } catch (error) {
      console.error("Error converting images to S3 keys", error);
      throw error;
    }
  };

  const submitApplication = async () => {
    try {
      setLoading(true);
      let onboardingData = createOnboardingData();

      // Convert images to S3 keys
      const portfolioWithS3Keys = await convertImagesToS3Keys(
        onboardingData.portfolio
      );
      onboardingData.portfolio = portfolioWithS3Keys; // Update portfolio with S3 keys

      const response = await axios.post(API_URL + "/provider", onboardingData, {
        headers: {
          Authorization: `Bearer ${await getIdToken()}`,
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200 && response.data.statusCode === 200) {
        setLoading(false);
        setSubmitted(true); // Update the submitted state variable
        localStorage.setItem("providerRegistered", false);
      } else {
        throw new Error("Failed to submit application.");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error submitting application", error);
      setAlert({
        show: true,
        status: "error",
        message: error.message,
      });
    }
  };

  const handleNextStep = () => {
    if (activeStep < steps.length - 1) {
      nextStep();
    } else {
      // Submit application
      submitApplication();
    }
  };

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const IdToken = await getIdToken();
        const response = await axios.get(API_URL + "/services", {
          headers: {
            Authorization: `Bearer ${IdToken}`,
            "Content-Type": "application/json",
          },
        });

        if (response.status === 200 && response.data.statusCode === 200) {
          const servicesData = JSON.parse(response.data.body);
          if (Array.isArray(servicesData)) {
            setServices(servicesData);
          } else {
            throw new Error("Failed to fetch services.");
          }
        } else {
          throw new Error("Failed to fetch services.");
        }
      } catch (error) {
        console.error("Error fetching services", error);
        setAlert({
          show: true,
          status: "error",
          message: error.message,
        });
      } finally {
        setLoading(false); // Set loading to false even if an error occurs
      }
    };

    fetchServices();
  }, []);

  if (submitted) {
    return successfullySubmitted(navigate);
  }

  const steps = [
    {
      label: "Portfolio",
      component: (
        <Portfolio
          updatePortfolioItemsInParent={updatePortfolioItemsInParent}
          setFormValidPortfolio={setFormValidPortfolio}
          portfolioItems={portfolioItems}
          setPortfolioItems={setPortfolioItems}
          editingItem={editingItem}
          setEditingItem={setEditingItem}
          placeholders={placeholders}
          setPlaceholders={setPlaceholders}
          services={services}
        />
      ),
    },
    {
      label: "Profile",
      component: (
        <BasicProfile
          onSubmit={handleNextStep}
          setFormValidBasicProfile={setFormValidBasicProfile}
          setProfilePicture={setProfilePicture}
          setPreviewImage={setPreviewImage}
          setBio={setBio}
          setProviderType={setProviderType}
          providerType={providerType}
          profilePicture={profilePicture}
          previewImage={previewImage}
          bio={bio}
          selectedServices={selectedServices}
          setSelectedServices={setSelectedServices}
          services={services}
          alert={alert}
        />
      ),
    },
    {
      label: "What's Next",
      component: <WhatsNext onSubmit={handleNextStep} />,
    },
  ];

  return (
    <Flex
      minH="80vh"
      alignItems="center"
      justifyContent="center"
      maxW={"900px"}
      w={"full"}
      mx={"auto"}
      py={4}
      px={0}
    >
      <Flex flexDir="column" width="100%" mx={4}>
        <Steps
          activeStep={activeStep}
          colorScheme="green"
          size="sm"
          p={0}
          variant="simple"
        >
          {steps.map(({ component, label }) => (
            <Step key={component} label={label}>
              <Box
                justifyContent={"center"}
                px={{ lg: 10, md: 6, base: 5 }}
                bg={boxColor}
                rounded="xl"
                pt={{ lg: 8, md: 10, base: 8 }}
                pb={{ lg: 12, md: 10, base: 8 }}
                mt={8}
              >
                <Center>
                  <VStack spacing={4}>
                    <Box>{component}</Box>
                  </VStack>
                </Center>
              </Box>

              <HStack
                mt={4}
                justifyContent={activeStep === 0 ? "flex-end" : "space-between"}
              >
                {activeStep !== 0 && (
                  <Button onClick={prevStep} isDisabled={activeStep === 0}>
                    Previous
                  </Button>
                )}
                <Button
                  onClick={handleNextStep}
                  variant="oleniumBlue"
                  isLoading={activeStep === steps.length - 1 && loading} // added 'isLoading' here
                  isDisabled={
                    (activeStep === 0 && !formValidPortfolio) ||
                    (activeStep === 1 && !formValidBasicProfile)
                  }
                >
                  {activeStep === steps.length - 1 ? "Finish" : "Next"}
                </Button>
              </HStack>
            </Step>
          ))}
        </Steps>
      </Flex>
    </Flex>
  );
};

export default DesignerOnboarding;
