import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import * as React from "react";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import theme from "./theme";
import { BrowserRouter } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { UserProvider } from "./UserContext";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ChakraProvider theme={theme}>
    <UserProvider>
      <Elements stripe={stripePromise}>
        <BrowserRouter>
          {/* Ensure ColorModeScript uses initialColorMode from theme */}
          <ColorModeScript initialColorMode={theme.config.initialColorMode} />
          <App />
        </BrowserRouter>
      </Elements>
    </UserProvider>
  </ChakraProvider>,
);
