import {
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tag,
  Text,
  VStack,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";
const Hero = () => {
  const navigate = useNavigate();
  const textColor = useColorModeValue("oleniumBlack.600", "white");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [scrollPosition] = useState(0);

  //const overlayOpacity = Math.min(scrollPosition / 1000, 0.9);

  const overlayOpacity = 0.4;

  const youtubeVideoUrl = "https://youtu.be/D_XKatAs1WU";

  return (
    <Box
      width="100%"
      align="center"
      height={{ base: "70vh", md: "90vh" }}
      justifyContent={"center"}
      alignItems={"center"}
      display={"flex"}
      position="relative"
      overflow="hidden"
      backgroundClip={"padding-box"}
    >
      <ReactPlayer
        url={youtubeVideoUrl}
        playing={true}
        loop={true}
        muted={true}
        width={"130%"}
        height={"130%"}
        style={{
          position: "absolute",
          objectFit: "cover",
          zIndex: 0,
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
        }}
        playsinline
      />
      <Box
        width="100%"
        height="100%"
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        background={`linear-gradient(rgba(0, 0, 0, ${overlayOpacity}), rgba(0, 0, 0, ${overlayOpacity}))`}
      />
      <VStack
        spacing={{ base: 6, md: 8 }}
        align="center"
        w={"100%"}
        maxW={"1000px"}
        mx={"auto"}
        lineHeight={"1"}
        zIndex={5}
      >
        <Tag
          size={{ base: "sm", md: "lg" }}
          variant="outline"
          borderRadius="full"
          px={4}
          py={1}
          fontWeight="bold"
          fontSize="md"
          letterSpacing="wide"
          color={textColor}
          background={"whiteAlpha.300"}
        >
          AI Client Matching
        </Tag>




        <Text
          fontSize={{ base: "42px", md: "5xl", lg: "6xl", xl: "9xl" }}
          fontWeight="bold"
          textAlign={"center"}
          lineHeight={"1.2"}
          bgGradient="linear(to-r, white, blue.600)"
          backgroundClip="text"
          overflow="visible"
          textShadow="-2px -2px -2px rgba(0, 0, 0, 0.2)"  // Add this line
        >
          Wide reach. Local impact
        </Text>
        <HStack spacing={4} align="center" mx={"auto"} justify={"center"}>
          <Button
            size={{ base: "sm", md: "md" }}
            variant={"ghost"}
            onClick={onOpen}
          >
            Watch our video
          </Button>
          <Button
            size={{ base: "sm", md: "md" }}
            variant={"oleniumBlue"}

            onClick={() => navigate("/provider-registration")}
          >
            Join now
          </Button>
        </HStack>
      </VStack>

      <Modal isOpen={isOpen} onClose={onClose} size={"6xl"}>
        <ModalOverlay />
        <ModalContent
          bg={"oleniumBlack.600"}
          color={"white"}
          pb={4}
          rounded={"2xl"}
        >
          <ModalHeader>Connecting the dots</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Modal isOpen={isOpen} onClose={onClose} size={"6xl"}>
              <ModalOverlay />
              <ModalContent
                bg={"oleniumBlack.600"}
                color={"white"}
                pb={4}
                rounded={"2xl"}
              >
                <ModalHeader>Connecting the dots</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Box borderRadius="10px" overflow="hidden">
                    <ReactPlayer
                      url={
                        "https://olenium-public-resources.s3.eu-west-1.amazonaws.com/connecting-the-dots.mp4"
                      }
                      controls
                      playing={isOpen}
                      width="100%"
                      height="auto"
                    />
                  </Box>
                </ModalBody>
              </ModalContent>
            </Modal>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box >
  );
};

export default Hero;
