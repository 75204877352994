import {
  Badge,
  Box,
  Button,
  Flex,
  FormLabel,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  Textarea,
  Input,
  InputGroup,
  InputRightElement,
  Center,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useState } from "react";
import { getIdToken } from "../../cognito/cognitoAuth";

const API_URL = process.env.REACT_APP_API_URL;

const Footer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [feedbackType, setFeedbackType] = useState("general");
  const [feedbackText, setFeedbackText] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const [isSupportOpen, setIsSupportOpen] = useState(false);
  const onSupportOpen = () => setIsSupportOpen(true);
  const onSupportClose = () => setIsSupportOpen(false);

  const [isCopied, setIsCopied] = useState(false);

  const toast = useToast();

  const onClose = () => {
    setIsOpen(false);
    setFeedbackText("");
    setErrors({});
  };

  const onOpen = () => {
    setIsOpen(true);
  };



  const validateForm = () => {
    const errors = {};

    if (!feedbackText.trim()) {
      errors.feedbackText = "Feedback is required.";
    }

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const submitFeedback = async () => {
    if (!validateForm()) {
      return;
    }

    setLoading(true); // Start the loading state

    const feedback = {
      feedback: feedbackText,
      feedbackType: feedbackType,
    };

    try {
      const authentication = await getIdToken();

      const response = await axios.post(`${API_URL}/feedback`, feedback, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authentication}`,
        },
      });

      if (response.status === 200 && response.data.statusCode === 200) {
        toast({
          title: "Feedback submitted.",
          description: "Thank you for your feedback!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });

        onClose();
      } else {
        throw new Error("Failed to submit feedback.");
      }
    } catch (error) {
      console.error("Error submitting feedback:", error);
      toast({
        title: "Feedback submission error.",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false); // Reset the loading state
    }
  };
  

  const handleFeedbackTypeChange = (event) => {
    setFeedbackType(event.target.value);
  };

  const handleFeedbackChange = (event) => {
    setFeedbackText(event.target.value);
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText("support@olenium.co.uk"); // Replace with your support email
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000); // Reset after 2 seconds
  };

  return (
    <Box as="footer" py={6} px={{ base: 4, md: 8 }}>
      <Flex
        direction={{ base: "column", md: "row" }}
        width="full"
        mx="auto"
        align="center"
        justify="space-between"
      >
        <HStack mb={2}>
          <Text fontSize="sm">
            &copy; {new Date().getFullYear()} Olenium
          </Text>
          <Text fontSize="sm">
            Version 1.0.0
          </Text>
          <Badge
            size="xs"
            variant="outline"
            px={2}
            rounded={"full"}
            textTransform={"Capitalize"}
          >
            Beta
          </Badge>
          <Badge
            size="xs"
            variant="outline"
            px={2}
            ml={1}
            rounded={"full"}
            textTransform={"Capitalize"}
            colorScheme="orange"
            style={{ display: process.env.NODE_ENV === 'development' ? 'block' : 'none' }} // Conditional rendering based on environment
          >
            Staging Environment
          </Badge>
        </HStack>

        <HStack>
          <Button
            variant="ghost"
            colorScheme="white"
            size="sm"
            onClick={onSupportOpen}
          >
            Support
          </Button>
          <Button
            variant="ghost"
            colorScheme="white"
            size="sm"
            onClick={onOpen}
          >
            Feedback
          </Button>
          <Button
            as="a" // Specify it behaves as an anchor tag
            variant="ghost"
            colorScheme="white"
            size="sm"
            href="https://olenium-public-resources.s3.eu-west-1.amazonaws.com/Privacy+Policy.pdf"
            target="_blank" // Opens in a new tab
            rel="noopener noreferrer" // Security reasons
          >
            Privacy Policy
          </Button>
          {/* <Button
            as="a" // Specify it behaves as an anchor tag
            variant="ghost"
            colorScheme="white"
            size="sm"
            href="https://olenium-public-resources.s3.eu-west-1.amazonaws.com/Terms+of+Use.pdf"
            target="_blank" // Opens in a new tab
            rel="noopener noreferrer" // Security reasons
          >
            Terms of Service
          </Button> */}
        </HStack>
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent bg="oleniumBlack.700" color="white">
          <ModalHeader>Leave Feedback</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb={4}>
              Thank you for your valuable feedback! At Olenium, we highly value
              your thoughts and opinions, as they shape our platform's future
              development and improvement.
            </Text>
            <FormLabel>Feedback type</FormLabel>
            <Select
              placeholder="Select feedback type"
              onChange={handleFeedbackTypeChange}
            >
              <option value="general">General</option>
              <option value="bug">Bug</option>
              <option value="feature">Feature</option>
            </Select>
            <FormLabel mt={4}>Feedback</FormLabel>
            <Textarea
              placeholder="Enter your feedback"
              value={feedbackText}
              onChange={handleFeedbackChange}
              isInvalid={!!errors.feedbackText}
            />
            {errors.feedbackText && (
              <Text color="red.500" mt={1} fontSize="sm">
                {errors.feedbackText}
              </Text>
            )}
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="oleniumBlue"
              onClick={submitFeedback}
              disabled={loading} // Disable the button if loading
              isLoading={loading} // Add the loading indicator to the button
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isSupportOpen} onClose={onSupportClose} isCentered>
        <ModalOverlay />
        <ModalContent bg="oleniumBlack.700" color="white" p={2}>
          <ModalHeader>Contact Support</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb={4}>
              Need help? Send us an email and our support team will get back to you as soon as possible.
            </Text>
            <InputGroup size="md" mb={4}>
              <Input
                pr="4.5rem"
                value="support@olenium.co.uk"
                isReadOnly
              />
              <InputRightElement width="4.5rem">
                <Button h="1.8rem" size="sm" onClick={handleCopyClick} w="4rem" mr={1}>
                  {isCopied ? "Copied" : "Copy"}
                </Button>
              </InputRightElement>
            </InputGroup>
            <Center>
              <Button
                as="a"
                href="mailto:support@olenium.co.uk"
                variant="oleniumBlue"
                size={"sm"}
                isFullWidth
              >
                Open my email
              </Button>
            </Center>
          </ModalBody>
          <ModalFooter>

          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Footer;
