import { Flex, Grid, Heading } from "@chakra-ui/react";

import {
  BsCardChecklist,
  BsChatSquareDots,
  BsFilePost,
  BsFillCreditCard2FrontFill,
  BsFillPersonBadgeFill,
} from "react-icons/bs";
import { FaUsers } from "react-icons/fa";
import AdminCard from "./AdminCard";

function AdminPanel() {
  return (
    <Flex direction="column">
      <Flex justifyContent="space-between" mb={10}>
        <Heading as="h1" size="lg">
          Admin Panel
        </Heading>
      </Flex>

      <Flex direction="column" mt={{ sm: "0px", lg: "0px" }} mb={17}>
        <Grid
          templateColumns={{
            sm: "1fr",
            md: "repeat(1, 1fr)",
            lg: "repeat(2, 1fr)",
            xl: "repeat(3, 1fr)",
            "2xl": "repeat(3, 1fr)",
          }}
          gap="30px"
        >
          <AdminCard
            icon={<BsFilePost />}
            title={"Projects"}
            description={
              "Manage all your ongoing projects, track progress, and view relevant documentation."
            }
            category={"Projects"}
            link={"projects"}
          />

          <AdminCard
            icon={<BsFillCreditCard2FrontFill />}
            title={"Invoice Manager"}
            description={
              "Manage all your invoices, and approve or reject the payout to the providers."
            }
            category={"Invoices"}
            link={"invoice-manager"}
          />

          <AdminCard
            icon={<FaUsers />}
            title={"User Manager"}
            description={
              "Oversee user information, roles, and permissions. Handle user authentication and authorization."
            }
            category={"Users"}
            link={"user-manager"}
          />

          <AdminCard
            icon={<BsFillPersonBadgeFill />}
            title={"Provider Manager"}
            description={
              "Manage providers and their details, including contracts, payments, and associated services."
            }
            category={"Providers"}
            link={"provider-manager"}
          />

          <AdminCard
            icon={<BsCardChecklist />}
            title={"Services Manager"}
            description={
              "Control and monitor all the services offered, including pricing, availability, and related analytics."
            }
            category={"Services"}
            link={"services-manager"}
          />

          <AdminCard
            icon={<BsChatSquareDots />}
            title={"User Feedback"}
            description={
              "Collect and analyze feedback from users to improve the system and provide better user experience."
            }
            category={"Users"}
            link={"user-feedback"}
          />

          <AdminCard
            icon={<BsFilePost />}
            title={"Add User"}
            description={
              "Automate the process of adding a new user to the system, including all necessary information."
            }
            category={"Users"}
            link={"add-user"}
          />

          <AdminCard
            icon={<BsFilePost />}
            title={"Add Provider"}
            description={
              "Automate the process of adding a new user to the system, including all necessary information."
            }
            category={"Users"}
            link={"add-provider"}
          />
        </Grid>
        
      </Flex>
    </Flex>
  );
}

export default AdminPanel;
