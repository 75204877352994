import {
    Box,
    Flex,
    Heading,
    IconButton,
    FormControl,
    FormLabel,
    Input,
    Button,
    Select,
  } from "@chakra-ui/react";
  import axios from "axios";
  import React, { useState } from "react";
  import { useNavigate } from "react-router-dom";
  import { FaArrowLeft } from "react-icons/fa";
  import { getIdToken } from "../../../../cognito/cognitoAuth";
  
  const API_URL = process.env.REACT_APP_API_URL;
  
  const InvoiceManager = () => {
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [userType, setUserType] = useState('Providers');
    
    const onBack = () => {
      navigate("/admin-panel");
    };
    
    const handleSubmit = async (event) => {
      event.preventDefault();
  
      // Generate a random password
      const randomPassword = Math.random().toString(36).slice(-8);
  
      // Get an ID token from Cognito
      const idToken = await getIdToken();
      
      try {
        await axios.post(`${API_URL}/users/admin/create`, {
          firstName,
          lastName,
          email,
          password: randomPassword,
          group: userType,
        }, {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        });
  
        // Navigate back or show success message
        alert("User created successfully");
        navigate("/admin-panel");
      } catch (error) {
        alert("Error creating user: " + error.message);
      }
    };
  
    return (
      <Box>
        <Flex justifyContent="flex-start" mb={10}>
          <IconButton aria-label="Back" icon={<FaArrowLeft />} onClick={onBack} />
          <Heading as="h1" size="lg" ml={5} mt={1}>
            Add Provider Users
          </Heading>
        </Flex>
        <form onSubmit={handleSubmit}>
            <Box mt={5} mb={5} ml={5} maxW={400}>
                <Heading as="h2" size="md">Create a new user</Heading>
          <FormControl isRequired mt={4}>
            <FormLabel>First Name</FormLabel>
            <Input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
          </FormControl>
          <FormControl isRequired mt={4}>
            <FormLabel>Last Name</FormLabel>
            <Input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
          </FormControl>
          <FormControl isRequired mt={4}>
            <FormLabel>Email</FormLabel>
            <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
          </FormControl>
          <FormControl isRequired mt={4}>
            <FormLabel>User Type</FormLabel>
            <Select value={userType} onChange={(e) => setUserType(e.target.value)}>
              <option value="Providers">Providers</option>
              <option value="Clients">Clients</option>
              <option value="Admins">Admins</option>
            </Select>
          </FormControl>
          <Button mt={8} colorScheme="blue" type="submit">
            Create User
          </Button>
            </Box>

        </form>
      </Box>
    );
  };
  
  export default InvoiceManager;
  