import {
  Box,
  Divider,
  Flex,
  HStack,
  Icon,
  Stack,
  Text,
  VStack,
  useColorModeValue
} from "@chakra-ui/react";
import React from "react";

import {
  BsCameraVideoFill,
  BsFillEnvelopeFill,
  BsFillPatchCheckFill,
} from "react-icons/bs";

const ourValues = [
  {
    icon: BsFillEnvelopeFill,
    title: "Look out for an email",
    description:
      "A member of our team will reach out to you shortly to schedule a call.",
  },
  {
    icon: BsCameraVideoFill,
    title: "Chat with our team",
    description:
      "We'll get to know you and your skills better through a video call and share more about our company.",
  },
  {
    icon: BsFillPatchCheckFill,
    title: "Join our team",
    description:
      "We'll get you set up with everything you need to start working with us and our clients.",
  },
];

const AwaitingApproval = () => {
  const boxColor = useColorModeValue("white", "oleniumBlack.600");

  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      w="100%"
      h={"100%"}
    >
      <Box
        bg={boxColor}
        p={10}
        rounded="2xl"
        maxW={"800px"}
        w={"100%"}
        height={"100%"}
        shadow="lg"
        display="flex"
        alignItems="center"
      >
        <Box w={"100%"} h={"100%"} display="flex" flexDirection="column">
          <Text
            fontSize={{ base: "3xl", md: "4xl" }}
            align={"left"}
            fontWeight="900"
            lineHeight={"110%"}
            bgGradient="linear(to-r, orange.500, yellow.600)"
            backgroundClip="text"
            textAlign={"center"}
          >
            We're reviewing your profile
          </Text>
          <Text
            fontSize={"md"}
            align={"left"}
            fontWeight="700"
            mt={4}
            textAlign={"center"}
          >
            We're reviewing your application and will get back to you soon.
          </Text>
          <Stack
            spacing={10}
            align="center"
            mx="auto"
            py={8}
            mt={8}
            maxW={"450px"}
            justify={"center"}
            height={"100%"}
          >
            {ourValues.map((value, index) => (
              <React.Fragment key={index}>
                {renderValue(value.icon, value.title, value.description)}
                {index < ourValues.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </Stack>
        </Box>
      </Box>
    </Flex>
  );
};

const renderValue = (icon, title, description) => (
  <HStack
    spacing={4}
    align="center"
    width="100%"
    flexDirection={{ base: "column", md: "row" }}
  >
    <Box
      w={12}
      h={12}
      minW={12}
      rounded="10px"
      bg="oleniumBlack.100"
      display="flex"
      alignItems="center"
      justifyContent="center"
      marginBottom={{ base: 4, md: 0 }}
      marginRight={{ base: 0, md: 4 }}
      aspectRatio={1}
    >
      <Icon as={icon} boxSize={{ base: 4, md: 6 }} />
    </Box>
    <VStack
      align={{ base: "center", md: "start" }}
      spacing={1}
      h={12}
      justifyContent={"center"}
      textAlign={{ base: "center", md: "left" }}
    >
      <Text
        fontSize={{ base: "md", md: "lg" }}
        fontWeight="bold"
        textAlign={{ base: "center", md: "left" }}
      >
        {title}
      </Text>
      <Text
        fontSize={{ base: "sm", md: "md" }}
        color={"oleniumGray.700"}
        textAlign={{ base: "center", md: "left" }}
      >
        {description}
      </Text>
    </VStack>
  </HStack>
);

export default AwaitingApproval;
