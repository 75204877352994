import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  HStack,
  Icon,
  Text,
  VStack,
  useColorModeValue,
  Heading,
} from "@chakra-ui/react";
import React from "react";
import {
  BsLightningChargeFill,
  BsPeopleFill,
  BsGlobeEuropeAfrica,
  BsCpuFill,
} from "react-icons/bs";



const HowItWorks = () => {
  const textColor = useColorModeValue("oleniumBlack.600", "white");

  const renderGridItem = (icon, title, description) => (
    <Flex
      direction="column"
      align="start"
      p={4}
      borderWidth="2px"
      borderRadius="2xl"
      borderColor="oleniumBlack.500"
      _hover={{
        borderColor: "#0085FF",
        bg: "oleniumBlack.600",
      }}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="50px"
        height="50px"
        borderRadius="10px"
        bg="#0085FF"
        mb={4}
      >
        <Icon as={icon} boxSize={6} />
      </Box>
      <Text fontSize="xl" fontWeight="bold" mb={2} textAlign={"left"}>
        {title}
      </Text>
      <Text fontSize="sm" color={"oleniumGray.500"} textAlign={"left"}>
        {description}
      </Text>
    </Flex>
  );

  return (
    <>
      <Box width="100%" align="center" px={8}>
        <Box mt={{ base: 4, md: 12 }} align="left" maxW="1000px" w="100%">
          <Box mt={{ base: 4, md: 36 }} align="left">
            <Text
              color={textColor}
              fontSize="4xl"
              fontWeight="bold"
              lineHeight={"1.2"}
            >
              Get qualified project leads for free
            </Text>

            <Text
              color={"oleniumGray.500"}
              fontSize="xl"
              lineHeight={"1.2"}
              fontWeight={"700"}
              mt={4}
              maxW={"700px"}
            >
              Discover the new era of connecting with clients worldwide. At Olenium, we harness the power of advanced machine learning to seamlessly match your unique design skills with clients who are seeking exactly what you offer. Say goodbye to the hassle of finding the right projects and hello to a world of opportunities.
            </Text>
          </Box>
        </Box>
      </Box>

      <Box maxWidth={"100%"} my={12} mx="auto" px={8}>
        <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }} gap={8} maxW="1000px" mx="auto">
          <GridItem>
            <Box
              w="100%"
              bg="#0085FF"
              backdropBlur={4}
              rounded="2xl"
              p={{ base: 6, md: 8 }}
            >
              <VStack alignItems="start" spacing={4}>
                <Heading size="lg" color="white" textAlign={"left"} zIndex={1}>
                  Freelancers
                </Heading>
                <Text fontSize="lg" color="white" mb={4}>
                  Tailored for the North East’s vibrant talent pool, our platform is the bridge between your skills and clients craving for them. We make it easy for you to find the right projects and clients, giving you the opportunity to showcase your skills and talents.
                </Text>
              </VStack>
            </Box>
          </GridItem>
          <GridItem>
            <Box
              w="100%"
              bg="rgba(32, 32, 32, 0.4)"
              borderWidth={2}
              borderColor="#0085FF"
              backdropBlur={4}
              rounded="2xl"
              p={{ base: 6, md: 8 }}
            >
              <VStack alignItems="start" spacing={4}>
                <Heading size="lg" color="white" textAlign={"left"} zIndex={1}>
                  Agencies
                </Heading>
                <Text fontSize="lg" color="white" mb={4}>
                  We make it easy for agencies to find the right projects and clients. Our platform allows you to showcase your skills and talents to a global audience, giving you the opportunity to shine.
                </Text>
              </VStack>
            </Box>
          </GridItem>
        </Grid >
      </Box>








      <Box width="100%" align={{ base: "left", md: "center" }} px={8}>
        <Box mt={48}>
          <Grid
            templateColumns={["repeat(1, 1fr)", "repeat(3, 1fr)"]}
            gap={4}
            maxW="1000px"
            mx="auto"
          >
            <GridItem colSpan={{ base: 3, md: 3, lg: 2 }} align="left">
              <VStack spacing={6} maxW={"550px"} align="start">
                <Text
                  color={textColor}
                  fontSize="5xl"
                  fontWeight="bold"
                  lineHeight={"1.2"}
                >

                  Join our community and help define it.
                </Text>
                <HStack spacing={4} mb={16}>
                  <Button
                    size={"md"}
                    variant={"oleniumBlue"}
                    onClick={() => window.open("https://calendar.app.google/rMvL3Ls2FXCvyKSq5")}
                  >
                    Book a call
                  </Button>
                </HStack>
              </VStack>
            </GridItem>
            <GridItem colSpan={{ base: 3, md: 3, lg: 3 }}>
              <Grid
                templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
                gap={{ base: 4, md: 4, lg: 8, xl: 8 }}
              >
                <GridItem maxW={{ base: "100%", md: "500px" }}>
                  {renderGridItem(
                    BsCpuFill,
                    "Tailored Match-Making",
                    "We use advanced machine learning to match you with clients who are seeking exactly what you offer. Say goodbye to the hassle of finding the right projects and hello to a world of opportunities",
                  )}
                </GridItem>
                <GridItem maxW={{ base: "100%", md: "500px" }}>
                  {renderGridItem(
                    BsGlobeEuropeAfrica,
                    "Diverse Global Clientele",
                    "Our platform is your gateway to a global clientele. Work with a myriad of people and businesses from across the world, each offering a unique perspective and diverse project needs.",
                  )}
                </GridItem>
                <GridItem maxW={{ base: "100%", md: "500px" }}>
                  {renderGridItem(
                    BsPeopleFill,
                    "Efficient Collaboration",
                    "Streamline your workflow with our platform's integrated communication and asset sharing features. Easily exchange ideas, gather real-time feedback, and create stronger relationships with your clients.",
                  )}
                </GridItem>
                <GridItem maxW={{ base: "100%", md: "500px" }}>
                  {renderGridItem(
                    BsLightningChargeFill,
                    "Competitive Edge",
                    "Stand out from the crowd by showcasing your unique skills and creative vision on a global stage. Olenium connects you with clients who value your talents, giving you the opportunity to shine.",
                  )}
                </GridItem>
              </Grid>
            </GridItem>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default HowItWorks;
